import React, { useEffect } from "react";
import Pagination from "rc-pagination";
import { useState } from "react";
import { connect } from "react-redux";
import Pageloader from "../../../../components/page-loader/Pageloader";
import { getActivities, getAnnouncements } from "../../../../redux/actions/activities.actions";
import { getGreeting } from "../../../../utils/getGreeting";
import AllAnnouncementItem from "./AnnouncementItem";

const AllAnnouncements = (props) => {
	const { getActivities, allActivities, getAnnouncements, allAnnouncements } = props;
	const [user, setUser] = useState({});
	const [greeting, setGreeting] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		onGetUser();
		setGreeting(getGreeting());
		onGetAnnouncements();
		return;
	}, []);

	const onGetUser = () => {
		let storage = JSON.parse(sessionStorage.getItem("_ark_task"));
		if (storage) {
			setUser(storage.user);
		}
	};

	const onGetAnnouncements = async () => {
		setLoading(true);
		let data = await getAnnouncements(currentPage, 20);
		setLoading(false);
		return data;
	};

	const onPageChange = async (page) => {
		let data = await getActivities(page, 20);
		setCurrentPage(page);
		return data;
	};

	return (
		<section className="px-3 lg:px-12 pt-6 ">
			<h1 className="text-md font-medium lg:text-2xl">
				{greeting}, {user.firstName ? user.firstName : ""}
			</h1>
			<p className="text-xs text-gray-700">Here is a lit of all the announcements:</p>
			<div className=" bg-white rounded-sm px-3 lg:px-6 py-4 mt-8">
				<div>
					<p className="text-lg text-gray-900 ">Announcements</p>
					<hr className="border-gray-200 mt-3" />
				</div>
				{!loading ? (
					<>
						{allAnnouncements && allAnnouncements?.data?.length > 0 ? (
							allAnnouncements?.data?.map((event, i) => {
								return (
									<AllAnnouncementItem
										event={event}
										key={i}
										lastItem={i + 1 === allAnnouncements?.data?.length}
									/>
								);
							})
						) : (
							<p className="text-xs italic">No announcement found</p>
						)}
					</>
				) : (
					<Pageloader />
				)}
			</div>
			<div className="mt-6 flex px-6 justify-end ">
				{!loading && (
					<Pagination
						onChange={(page) => onPageChange(page)}
						defaultCurrent={currentPage}
						total={allAnnouncements.count ? allAnnouncements.count : 0}
						pageSize={20}
					/>
				)}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	allActivities: state.activities.activities,
	allAnnouncements: state.activities.announcements,
});

export default connect(mapStateToProps, { getActivities, getAnnouncements })(AllAnnouncements);
