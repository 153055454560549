export const workflowValues = {
	0: "Recieved",
	1: "Printing",
	2: "Pasting",
	3: "Cutting",
	4: "Laminating",
	5: "Binding",
	6: "Embossing",
	7: "Foil Stamping",
	8: "Perforating",
	9: "Die-cutting",
	10: "Numbering",
	11: "Corner Rounding",
	12: "Folding",
	13: "Collating",
	14: "Creasing",
	15: "Punching",
	16: "Gluing",
	17: "Quality Control",
	18: "Dispatched",
	19: "Weeding",
	20: "Heat Pressing",
	21: "Plotting",
	22: "Sewing",
	23: "Installing",
	24: "Mounting",
};

export const categoryValues = {
	1: "Business Stationary",
	2: "Marketing Materials",
	3: "Clothing & Apparel",
	4: "Labels & Packaging",
	5: "Publications",
	6: "Photo Products & Art",
	7: "Promotional Products",
	8: "Signs & Displays",
	9: "Other",
};

export const statusValues = {
	0: "ACTIVE",
	1: "ARCHIVED",
};

export const industryValues = {
	1: "Healthcare, Beauty & Fitness, Technology",
	2: "Financial Services & Insurance",
	3: "FinTechs",
	4: "Food, Beverage & Restaurant",
	5: "FMCGs",
	6: "Government",
	7: "Oil & Gas",
	8: "Travel & Tourism",
	9: "Education & Training",
	10: "Planning & Events Management",
	11: "Religion",
	12: "Logistics & Transportation",
	13: "Fashion & Retail",
	14: "Professional Services: Legal & Accounting",
	15: "Creative Freelancers & Agencies",
	16: "Photography & Lifestyle",
	17: "NGOs & Charities",
	18: "Real Estate & Construction",
	19: "Other",
};

export const leadSourceValue = {
	1: "Employee Referral",
	2: "Customer Referral",
	3: "Social Media",
	4: "Website",
	5: "Paid Ads",
	6: "LinkedIn",
	7: "Walk-Ins",
	8: "Other",
	9: "Unknown",
};

export const leadStatusValues = {
	0: "Prospects",
	1: "Contact Made",
	2: "Meeting Booked",
	3: "Proposal",
	4: "Awaiting Feedback",
	5: "Negotiating",
	6: " Won Deal",
	7: "Lost Deal",
};

export const definedLeadStyle = {
	0: {
		button: { backgroundColor: "#F5F5F5", border: "1px dashed #000000" },
		text: {
			color: "#000000",
		},
	},
	1: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#EB5757",
		},
	},
	2: {
		button: { backgroundColor: "#FEF2EC", border: "1px dashed #F26925" },
		text: {
			color: "#F26925",
		},
	},
	3: {
		button: { backgroundColor: "#EEFCFA", border: "1px dashed #1B998B" },
		text: {
			color: "#1B998B",
		},
	},
	4: {
		button: { backgroundColor: "#FEF2EC", border: "1px dashed #F26925" },
		text: {
			color: "#F26925",
		},
	},
	5: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#EB5757",
		},
	},
	6: {
		button: { backgroundColor: "#EEFCFA", border: "1px dashed #1B998B" },
		text: {
			color: "#1B998B",
		},
	},
	7: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#1B998B",
		},
	},
};

export const taskCodeDefinedValues = {
	0: "General (default)",
	1: "Request for a quote (RFQ)",
	2: "Design request",
	3: "Proposal",
	4: "Follow up",
	5: "Other",
};

export const definedTaskStatus = {
	0: "To-do",
	1: "In progress",
	2: "In review",
	3: "Done",
	4: "On hold",
	5: "Cancelled",
};

export const definedTaskStatusColors = {
	0: {
		button: { backgroundColor: "#F5F5F5", border: "1px dashed #000000" },
		text: {
			color: "#000000",
		},
	},
	1: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#EB5757",
		},
	},
	2: {
		button: { backgroundColor: "#FEF2EC", border: "1px dashed #F26925" },
		text: {
			color: "#F26925",
		},
	},
	3: {
		button: { backgroundColor: "#EEFCFA", border: "1px dashed #1B998B" },
		text: {
			color: "#1B998B",
		},
	},
	4: {
		button: { backgroundColor: "#FEF2EC", border: "1px dashed #F26925" },
		text: {
			color: "#ebcaba",
		},
	},
	5: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#EB5757",
		},
	},
};

export const definedOrderStatus = {
	0: "Order Received ",
	1: "Pre-flight",
	2: "In Production",
	3: "Quality Control",
	4: "Ready",
	5: "Dispatched",
	6: "On Hold",
	7: "Cancelled",
	8: "Awaiting Approval",
	9: "Awaiting Design",
	10: "Awaiting Payment",
	11: "Delivered",
};

export const ordersStatusColor = {
	0: {
		button: { backgroundColor: "#F5F5F5", border: "1px dashed #000000" },
		text: {
			color: "#000000",
		},
	},
	1: {
		button: { backgroundColor: "#F5EEFC", border: "1px dashed #BB6BD9" },
		text: {
			color: "#BB6BD9",
		},
	},
	2: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#EB5757",
		},
	},
	3: {
		button: { backgroundColor: "#EEF7FB", border: "1px dashed #247BA0" },
		text: {
			color: "#247BA0",
		},
	},
	4: {
		button: { backgroundColor: "#EEFCFA", border: "1px dashed #1B998B" },
		text: {
			color: "#1B998B",
		},
	},
	5: {
		button: { backgroundColor: "#EEFCFA", border: "1px dashed #1B998B" },
		text: {
			color: "#1B998B",
		},
	},
	6: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#EB5757",
		},
	},
	7: {
		button: { backgroundColor: "#FDEDED", border: "1px dashed #EB5757" },
		text: {
			color: "#EB5757",
		},
	},
	8: {
		button: { backgroundColor: "#F5F5F5", border: "1px dashed #000000" },
		text: {
			color: "#000000",
		},
	},
	9: {
		button: { backgroundColor: "#F5F5F5", border: "1px dashed #000000" },
		text: {
			color: "#000000",
		},
	},
	10: {
		button: { backgroundColor: "#EEFCFA", border: "1px dashed #1B998B" },
		text: {
			color: "#1B998B",
		},
	},
	11: {
		button: { backgroundColor: "#EEFCFA", border: "1px dashed #1B998B" },
		text: {
			color: "#2c7068",
		},
	},
};

export const definedPaymentStatus = {
	0: "Not Paid",
	1: "Part Payment",
	2: "Full Payment",
	3: "Full Payment",
};

export const definedPaymentColor = {
	0: "#EB5757",
	1: "#F2994A",
	2: "#1B998B",
	3: "#1B998B",
};

export const roleValues = {
	1: "customer",
	2: "team member",
	3: "manager",
	4: "admin",
};

export const departmentStatus = {
	1: "inactive",
	0: "active",
};
