import { Tooltip } from "@material-ui/core";
import React from "react";
import { getNameInitials } from "../../utils/helper-functions";

const StackedImages = ({ assignees }) => {
	let customStyle = {};
	return (
		<div className="flex justify-center">
			{assignees && assignees.length > 0 ? (
				assignees.map((user, i) => {
					if (i === 0) {
						customStyle = {};
					} else {
						customStyle = {
							marginLeft: "-8px",
						};
					}
					return (
						<Tooltip title={`${user.firstName} ${user.lastName}`}>
							{user.profilePicture.length > 0 ? (
								<img
									style={customStyle}
									className="rounded-full w-8 h-8 ml--2"
									src={
										user.profilePicture.length > 0
											? user.profilePicture
											: "/uploads/profile-placeholder.jpg"
									}
									alt="Sunset"
								/>
							) : (
								<div
									className="w-8 h-8 mx-2 rounded-full mr-4"
									style={{
										backgroundColor: "#f0efec",
										marginLeft: "-25px",
									}}>
									<div className="flex justify-center">
										<p className="uppercase" style={{ marginTop: "7px", fontSize: "12px" }}>
											{getNameInitials(
												`${user ? user.firstName : "Unknown"} ${user ? user.lastName : "User"}`
											)}
										</p>
									</div>
								</div>
							)}
						</Tooltip>
					);
				})
			) : (
				<span
					style={{
						marginLeft: "-8px",
					}}>
					<div
						className="w-8 h-8 mx-2 rounded-full mr-4"
						style={{
							backgroundColor: "#f0efec",
						}}>
						<div className="flex justify-center">
							<p style={{ marginTop: "7px", fontSize: "12px" }}>{getNameInitials("Unknown User")}</p>
						</div>
					</div>
				</span>
			)}
		</div>
	);
};

export default StackedImages;
