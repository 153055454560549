import moment from "moment";
export const numberWithCommas = (num) => {
	if (num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
};

export const streamNotification = () => {
	let url = "wss://ark-beta.herokuapp.com/v1/notifications";
	// let socket = new WebSocket(url);
	// socket.onmessage = (e) => {
	// 	console.log("data stream", JSON.parse(e.data));
	// };
};

export const getBuildDate = (epoch) => {
	const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
	return buildDate;
};

export const getNameInitials = (name) => {
	if (name) {
		let matches = name.match(/\b(\w)/g);
		return matches;
	} else {
		return null;
	}
};
