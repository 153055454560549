import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { forgotPassword } from "../../redux/actions/auth.actions";

const ForgotPassword = (props) => {
	const { forgotPassword } = props;
	const { addToast } = useToasts();
	const [email, setEmail] = useState("");
	const [saving, setSaving] = useState(false);

	const onForgotPassword = async (e) => {
		e.preventDefault();
		setSaving(true);
		let details = {
			email,
		};
		let data = await forgotPassword(details);
		if (data.success) {
			addToast("Password resent link sent", { appearance: "success" });
		} else {
			addToast(data.message, { appearance: "error" });
		}
		setSaving(false);
	};

	return (
		<div>
			<div class="grid grid-cols-2 gap-0">
				<div className="bg-cover hidden lg:block bg-login h-screen bg-center bg-no-repeat"></div>
				<div className="h-screen w-screen p-4 lg:w-full lg:p-8">
					<div>
						<img
							src="https://res.cloudinary.com/w3bh4ck/image/upload/v1600259673/ark/ark-logo-dark.png"
							alt="ark-logo"
							loading="lazy"
							className="rounded-full"
						/>
					</div>
					<div className="mt-6 lg:mt-12">
						<h3 className="text-sm lg:text-2xl">Forgot Password</h3>
						<p className="text-xs text-gray-600 mt-2">Enter your registered email</p>
					</div>
					<form onSubmit={(e) => onForgotPassword(e)} className="mt-5 lg:mt-12">
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
									htmlFor="email">
									Email
								</label>
								<input
									required
									className="appearance-none block w-full bg-orange-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="email"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="example@email.com"
								/>
							</div>
						</div>

						<label className=" block text-gray-500 ">
							<Link to="/">
								<span className="text-sm hover:text-orange-500">Back to log in</span>
							</Link>
						</label>

						<div className="mt-6">
							<button
								type="submit"
								className="bg-orange-500 hover:bg-orange-700 w-full text-white font-bold text-xs py-3 px-4 rounded">
								{saving ? "Sending link..." : "Send link"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { forgotPassword })(ForgotPassword);
