import React, { useEffect, useState } from "react";
import MyTaskItem from "./MyTaskItem";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import {
	changeTaskStatus,
	getOverdueTasks,
	getTasks,
	getTodayTasks,
	getDoneTasks,
} from "../../../../redux/actions/task.actions";
import { getGreeting } from "../../../../utils/getGreeting";
import Pageloader from "../../../../components/page-loader/Pageloader";
import ViewTask from "./ViewTask";

const MyTask = (props) => {
	const {
		getTasks,
		tasks,
		kanban,
		toggleKanbanView,
		overdueTasks,
		changeTaskStatus,
		getOverdueTasks,
		getTodayTasks,
		todaysTasks,
		getDoneTasks,
		doneTasks,
	} = props;
	const { addToast } = useToasts();
	const [greeting, setGreeting] = useState("");
	const [loading, setLoading] = useState(false);
	const [viewSingle, setViewSingle] = useState(false);
	const [activeTask, setActiveTask] = useState("");
	const [user, setUser] = useState({});

	useEffect(() => {
		onGetTasks();
		setGreeting(getGreeting());
		getOverdueTasks();
		getTodayTasks();
		getDoneTasks();
		onGetUser();
		return;
	}, []);

	const onGetTasks = async () => {
		setLoading(true);
		let data = await getTasks();
		if (data?.success) {
			setLoading(false);
		}
		setLoading(false);
	};

	const openSingleTask = (id) => {
		setActiveTask(id);
		setTimeout(() => {
			setViewSingle(true);
		}, 10);
	};

	const markAsDone = async (id, status) => {
		let details = { status };
		let update = await changeTaskStatus(id, details);
		if (update?.success) {
			getTasks();
			getOverdueTasks();
			getTodayTasks();
			getDoneTasks();
			addToast("Task status updated", { appearance: "success" });
		} else {
			addToast(update?.data?.message, { appearance: "error" });
		}
	};

	const onFilterTasks = (status) => {
		getTasks(status);
		getOverdueTasks(status);
		getTodayTasks(status);
		getDoneTasks(status);
	};

	const onGetUser = () => {
		let storage = JSON.parse(sessionStorage.getItem("_ark_task"));
		if (storage) {
			setUser(storage.user);
		}
	};

	return (
		<section className="px-3 lg:px-12 pt-6">
			{!viewSingle && (
				<div>
					<h1 className="text-md font-medium lg:text-3xl">
						{greeting}, {user.firstName ? user.firstName : ""}
					</h1>
					<p className="text-xs mb-1 lg:mb-2 text-gray-700">Here is a breakdown of your tasks:</p>

					{!kanban && (
						<div className="flex justify-end">
							<span className="py-4">
								<button
									onClick={() => toggleKanbanView()}
									style={{ backgroundColor: "#FEF2EC", color: "#F26925", border: "1px dashed #F26925" }}
									className="py-1 text-xs px-4 mx-3 capitalize rounded inline-flex items-center">
									<span className="px-2">Switch to Board View</span>
								</button>
							</span>

							<div className="w-36 h-12 px-3 mb-6 md:mb-0">
								<div className="relative pt-3">
									<select
										style={{ height: "38px" }}
										onChange={(e) => onFilterTasks(e.target.value)}
										className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none text-xs focus:bg-white focus:border-gray-500"
										id="grid-state">
										<option value="">Filter</option>
										<option value={0}>To-do</option>
										<option value={1}>In progress</option>
										<option value={2}>In review</option>
										{/* <option value={3}>Done</option> */}
										<option value={4}>On-hold</option>
										<option value={5}>Cancelled</option>
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg
											className="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20">
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>
							</div>
						</div>
					)}

					{/* Today*/}

					<div className=" full-width pt-2 bg-white px-2 pb-16 shadow-sm mt-2 lg:mt-4 rounded-sm">
						<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-2 py-4 mx-6 lg:mx-3">
							<table className="table-auto min-w-full px-2 ">
								<thead className=" border-b border-gray-200">
									<tr className="min-h-24 text-xs text-gray-600 uppercase">
										<th className="px-4 py-2">
											<span className="lg:flex ">
												<p className="px-2">Today</p>
											</span>
										</th>
										<th className="px-4 py-2 text-left">
											<p className="px-2 ">Due date</p>
										</th>
										<th className="px-4 py-2 text-right">
											<p className="px-2 ">Status</p>
										</th>
									</tr>
								</thead>
								{todaysTasks.tasks && todaysTasks.tasks.length > 0 ? (
									<tbody>
										{!loading && todaysTasks.tasks ? (
											todaysTasks.tasks.map((task, i) => {
												return (
													<MyTaskItem
														key={i}
														openSingleTask={openSingleTask}
														task={task}
														dueDate={"upcoming"}
														markAsDone={markAsDone}
													/>
												);
											})
										) : (
											<tr></tr>
										)}
									</tbody>
								) : (
									<p className="text-xs text-gray-600">No task due today</p>
								)}
							</table>
						</div>
					</div>

					{/* upcoming */}
					<div className=" full-width pt-2 bg-white px-2 pb-16 shadow-sm mt-2 lg:mt-4 rounded-sm">
						<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-2 py-4 mx-6 lg:mx-3">
							<table className="table-auto min-w-full px-2 ">
								<thead className=" border-b border-gray-200">
									<tr className="min-h-24 text-xs text-gray-600 uppercase">
										<th className="px-4 py-2">
											<span className="lg:flex ">
												<p className="px-2">Upcoming</p>
											</span>
										</th>
										<th className="px-4 py-2 text-left">
											<p className="px-2 ">Due date</p>
										</th>
										<th className="px-4 py-2 text-right">
											<p className="px-2 ">Status</p>
										</th>
									</tr>
								</thead>
								<tbody>
									{!loading && tasks.tasks ? (
										tasks.tasks.map((task, i) => {
											return (
												<MyTaskItem
													key={i}
													openSingleTask={openSingleTask}
													task={task}
													dueDate={"upcoming"}
													markAsDone={markAsDone}
												/>
											);
										})
									) : (
										<tr></tr>
									)}
								</tbody>
							</table>
							{loading && (
								<div className="">
									<Pageloader />
								</div>
							)}
						</div>
					</div>

					{/* overdue */}
					<div className=" full-width pt-2 bg-white px-2 pb-16 shadow-sm mt-2 lg:mt-4 rounded-sm">
						<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-2 py-4 mx-6 lg:mx-3">
							<table className="table-auto min-w-full px-2 ">
								<thead className=" border-b border-gray-200">
									<tr className="min-h-24 text-xs text-gray-600 uppercase">
										<th className="px-4 py-2">
											<span className="lg:flex ">
												<p className="px-2">Overdue</p>
											</span>
										</th>
										<th className="px-4 py-2 text-left">
											<p className="px-2 ">Due date</p>
										</th>
										<th className="px-4 py-2 text-right">
											<p className="px-2 ">Status</p>
										</th>
									</tr>
								</thead>
								<tbody>
									{!loading && overdueTasks.tasks ? (
										overdueTasks.tasks.map((item, i) => {
											return (
												<MyTaskItem
													key={i}
													status={"overdue"}
													openSingleTask={openSingleTask}
													task={item}
													dueDate={"upcoming"}
													markAsDone={markAsDone}
												/>
											);
										})
									) : (
										<span></span>
									)}
								</tbody>
							</table>
							{loading && (
								<div className="">
									<p className="text-xs italic">Loading...</p>
								</div>
							)}
						</div>
					</div>

					<div className=" full-width pt-2 bg-white px-2 pb-16 shadow-sm mt-2 lg:mt-4 rounded-sm">
						<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-2 py-4 mx-6 lg:mx-3">
							<table className="table-auto min-w-full px-2 ">
								<thead className=" border-b border-gray-200">
									<tr className="min-h-24 text-xs text-gray-600 uppercase">
										<th className="px-4 py-2">
											<span className="lg:flex ">
												<p className="px-2">Done</p>
											</span>
										</th>
										<th className="px-4 py-2 text-left">
											<p className="px-2 ">Due date</p>
										</th>
										<th className="px-4 py-2 text-right">
											<p className="px-2 ">Status</p>
										</th>
									</tr>
								</thead>
								<tbody>
									{!loading && doneTasks.tasks ? (
										doneTasks.tasks.map((item, i) => {
											return (
												<MyTaskItem
													key={i}
													status={"overdue"}
													openSingleTask={openSingleTask}
													task={item}
													dueDate={"upcoming"}
													markAsDone={markAsDone}
												/>
											);
										})
									) : (
										<tr></tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
			{viewSingle && <ViewTask activeTask={activeTask} setViewSingle={setViewSingle} />}
		</section>
	);
};

const mapStateToProps = (state) => ({
	tasks: state.tasks.allTasks,
	overdueTasks: state.tasks.overdueTasks,
	todaysTasks: state.tasks.todayTasks,
	doneTasks: state.tasks.doneTasks,
});

export default connect(mapStateToProps, {
	getTasks,
	getOverdueTasks,
	changeTaskStatus,
	getTodayTasks,
	getDoneTasks,
})(MyTask);
