import React, { useState } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { createCustomer } from "../../redux/actions/customers.actions";

const AddCustomer = (props) => {
	const { createCustomer, modalOpen } = props;
	const { addToast } = useToasts();
	const [name, setName] = useState("");
	const [saving, setSaving] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [companyAnniversary, setcompanyAnniversary] = useState("");
	const [contactAnniversary, setContactAnniversary] = useState("");

	const onCreateCustomer = async (e) => {
		e.preventDefault();
		setSaving(true);

		let contactPersonDetails = {
			name: `${firstName} ${lastName}`,
			email,
			phone,
		};
		if (JSON.stringify(contactAnniversary).length > 1) {
			if (contactAnniversary) {
				contactPersonDetails.anniversary = contactAnniversary;
			}
		}
		let customerDetails = {
			name,
			industry: 1,
			contacts: [contactPersonDetails],
		};

		if (JSON.stringify(companyAnniversary).length > 1) {
			if (companyAnniversary) {
				customerDetails.anniversary = companyAnniversary;
			}
		}

		let create = await createCustomer(customerDetails);
		if (create.success) {
			addToast("New Customer Created", { appearance: "success" });
			setSaving(false);
			setTimeout(() => {
				modalOpen(false);
			}, 1000);
		} else {
			addToast(create.data.message, { appearance: "error" });
			setSaving(false);
		}
	};

	return (
		<>
			<h3 className="text-gray-900 ">Add new customer</h3>
			<p className="text-xs text-gray-600 mt-2">Enter customer details below:</p>
			<div className="flex justify-center mt-4">
				<form onSubmit={(e) => onCreateCustomer(e)} className="w-full mt-3">
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								CUSTOMER NAME
							</label>
							<input
								required
								value={name}
								onChange={(e) => setName(e.target.value)}
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-password"
								type="text"
								placeholder="Business or Person"
							/>
						</div>
					</div>
					{/* <div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								COMPANY ANNIVERSARY
							</label>
							<div className="relative">
								<DatePicker
									wrapperClassName="customize-date"
									className="customize-date"
									dateFormat="dd/MM/yyyy"
									placeholderText="dd/mm/yyyy"
									selected={companyAnniversary}
									onChange={(date) => setcompanyAnniversary(date)}
								/>
							</div>
						</div>
					</div> */}

					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								FIRST NAME
							</label>
							<input
								required
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-first-name"
								type="text"
								placeholder="Who in the company reached out?"
							/>
						</div>
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								LAST NAME
							</label>
							<input
								required
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-last-name"
								type="text"
								placeholder="Who in the company reached out?"
							/>
						</div>
					</div>
					{/* <div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								CONTACT PERSON's BIRTHDAY
							</label>
							<div className="relative">
								<DatePicker
									wrapperClassName="customize-date"
									className="customize-date"
									dateFormat="dd/MM/yyyy"
									placeholderText="dd/mm/yyyy"
									selected={contactAnniversary}
									onChange={(date) => setContactAnniversary(date)}
								/>
							</div>
						</div>
					</div> */}

					<div className="flex flex-wrap -mx-3 mb-2 justify-between">
						<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								Phone Number
							</label>
							<PhoneInput
								country={"ng"}
								enableSearch={true}
								value={phone}
								onChange={(val) => {
									if (val && val.length > 0) {
										let formattedPhone = val.split("");
										formattedPhone.unshift("+");
										setPhone(formattedPhone.join(""));
									}
								}}
							/>
							{/* <input
								required
								value={phone}
								onChange={(e) => {
									setPhone(e.target.value);
								}}
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-phone"
								type="tel"
								maxLength="15"
								minLength="9"
								placeholder="+2349012345672"
							/> */}
						</div>
						<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-email">
								Email
							</label>
							<div className="w-full">
								<input
									style={{
										width: "270px",
									}}
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className="appearance-none block bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-email"
									type="email"
									placeholder="example@email.com"
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<button
							type="submit"
							className="bg-orange-500 hover:bg-orange-700 text-white  text-xs py-2 px-4 rounded">
							{!saving ? "Create new customer" : "Saving..."}
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	customers: state.customers.customers,
	customer: state.customers.customer,
});

export default connect(mapStateToProps, { createCustomer })(AddCustomer);
