import React from "react";
import { Link } from "react-router-dom";
import { workflowValues } from "../../utils/definedValues";

const ProductTableItem = (props) => {
	const { name, workflow, category, orderCount, quantityOptions, id } = props;

	return (
		<tr className="border-t  border-gray-200 h-16">
			<td className="  border-l-0 px-4 py-2 text-gray-900 text-sm">
				<div className="">
					<Link to={`/products/product/${id}`}>
						<span className="py-2 hover:text-orange-400">{name}</span>
					</Link>
				</div>
			</td>
			<td className=" border-l  border-gray-200 border-r px-4 py-2 text-sm  capitalize">
				{workflow.map((flow, i) => {
					return (
						<span key={i}>
							{workflowValues[parseInt(flow)]}
							{i + 1 !== workflow.length ? ",  " : "."}
						</span>
					);
				})}
			</td>
			<td className=" border-l  border-gray-200 border-r px-4 py-2 text-sm  capitalize ">{category}</td>

			<td className=" border-l  border-gray-200 border-r px-4 py-2 text-sm  capitalize text-center">
				{orderCount}
			</td>
			<td className=" border-l  border-gray-200 px-4 py-2 text-sm  ">{quantityOptions}</td>
		</tr>
	);
};

export default ProductTableItem;
