import React from "react";
import { Link } from "react-router-dom";
import OrdercardItem from "./Order-card-item";

const OrdersCard = ({ orders }) => {
	return (
		<div className="order-card full-width bg-white p-4 overflow-auto shadow-sm rounded-sm">
			<div className="flex justify-between">
				<span className="text-xs uppercase text-gray-600">Recent orders</span>
				<Link to="/orders">
					<span style={{ color: "#F26925" }} className="text-xs cursor-pointer">
						<Link to="/orders">View all</Link>
					</span>
				</Link>
			</div>
			<div>
				{orders.orders && orders.orders.length > 0 ? (
					orders.orders.map((order, i) => {
						if (i < 3) {
							return <OrdercardItem order={order} key={i} />;
						}
					})
				) : (
					<p>No task added</p>
				)}

				{/* <OrdercardItem /> */}
			</div>
		</div>
	);
};

export default OrdersCard;
