import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { format, formatDistance } from "date-fns";
import { useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import { EditIcon } from "../../assets/Svg";
import SelectPointer from "../../components/commons/SelectPointer";
import { KeyboardBackspaceOutlined, AvTimerOutlined, DeleteOutlined } from "@material-ui/icons";
import Pageloader from "../../components/page-loader/Pageloader";
import { deleteLeads, getSingleLead, updateLead } from "../../redux/actions/leads.actions";
import { filteredActivity } from "../../redux/actions/activities.actions";

const customStyles = {
	content: {
		top: "40%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
	},
};
const ViewLead = (props) => {
	const { id } = useParams();
	const { getSingleLead, updateLead, filteredActivity, activities, deleteLeads } = props;
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(true);
	const [savingLead, setSavingLead] = useState(false);
	const [description, setDescription] = useState("");
	const [contact, setContact] = useState({});
	const [leadSource, setLeadSource] = useState({});
	const [leadStatus, setLeadStatus] = useState(0);
	const [priority, setPriority] = useState(0);
	const [dealValue, setDealValue] = useState(0);
	const [createdAt, setCreatedAt] = useState("");
	const [organization, setOrganization] = useState("");
	const [phone, setPhone] = useState("");
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		onGetSingleLead();
		filteredActivity(id);
	}, []);

	const onGetSingleLead = async () => {
		if (id) {
			setLoading(true);
			let singleLead = await getSingleLead(id);
			if (singleLead.success) {
				const { data } = singleLead;
				setDescription(data.description);
				setOrganization(data.organisation);
				setLeadSource(data.source);
				setContact(data.contact);
				setLeadStatus(data.leadStatus);
				setDealValue(data.potentialValue);
				setPhone(data.phone);
				setCreatedAt(data.createdAt);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	const onUpdateLead = async (e) => {
		e.preventDefault();
		setSavingLead(true);
		let leadDetails = {
			contact: contact,
			description,
			organisation: organization,
			phone,
			source: leadSource,
			leadStatus: parseInt(leadStatus),
			potentialValue: parseInt(dealValue),
		};
		let update = await updateLead(id, leadDetails);
		onGetSingleLead();
		filteredActivity(id);
		if (update.succes) {
			addToast("Lead updated", { appearance: "success" });
			filteredActivity(id);
		} else {
			addToast(update.message, { appearance: "success" });
		}
		setSavingLead(false);
	};

	const onDeleteLead = async () => {
		setDeleting(true);
		let res = await deleteLeads(id);
		if (res?.success) {
			addToast("Lead deleted", { appearance: "success" });
			window.location.replace("/leads");
		} else {
			addToast(res?.message, { appearance: "error" });
		}
		setDeleting(false);
	};

	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			<DashboardNav history={props.history} />

			<div
				onClick={() => props.history.goBack()}
				className="flex mx-8 mt-4 lg:mt-8 cursor-pointer text-gray-700">
				<KeyboardBackspaceOutlined /> <span>Back</span>
			</div>
			<div className="mt-10 px-3 lg:px-8 mb-4">
				<h1 className="text-md font-medium lg:text-2xl pb-2">Lead#35435</h1>
				<p className="text-xs text-gray-700">This is the lead details</p>
			</div>
			<div className="lg:flex mx-6 lg:mx-8 mt-4">
				<div
					style={{ height: "500px" }}
					className="lg:w-2/3 w-full mx-2 bg-white rounded-sm px-4 lg:px-8 pb-4">
					<div className="flex justify-between px-2 pt-4">
						<p className="text-xl ">Lead information</p>
						<div className="flex">
							<div
								onClick={() => setEdit(!edit)}
								className="flex text-xs uppercase cursor-pointer text-gray-700">
								<EditIcon /> <span className="pt-1 mx-2">EDIT</span>
							</div>
							<div
								onClick={() => onDeleteLead()}
								className="flex text-xs uppercase cursor-pointer text-red-500">
								<DeleteOutlined />{" "}
								<span className="pt-1 mx-2">{!deleting ? "Delete" : "Deleting..."}</span>
							</div>
						</div>
					</div>
					<hr className="border-gray-200 w-full" />
					{!loading ? (
						<>
							<form onSubmit={(e) => onUpdateLead(e)} className="w-full mt-3 lg:mt-6">
								<div className="flex flex-wrap -mx-3 mb-6">
									<div className="w-full px-3">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
											htmlFor="grid-city">
											Request details:
										</label>
										<textarea
											required
											rows="4"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											disabled={edit}
											className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-password"
											type="text"
											placeholder="include a description that explains a bit about this opportunity here"
										/>
									</div>
								</div>
								<div className="flex flex-wrap -mx-3 mb-2 mt-6">
									<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
											htmlFor="grid-email">
											Lead owner:
										</label>
										<input
											required
											disabled={edit}
											value={leadSource.name}
											onChange={(e) => setLeadSource({ ...leadSource, name: e.target.value })}
											className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-owner"
											type="text"
											placeholder="value"
										/>
									</div>
									<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
											htmlFor="grid-source">
											Lead source:
										</label>
										<div className="relative">
											<select
												value={leadSource.context}
												disabled={edit}
												onChange={(e) => setLeadSource({ ...leadSource, context: e.target.value })}
												className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
												id="grid-source">
												<option value={1}>Employee referral</option>
												<option value={2}>Customer referral</option>
												<option value={3}>Social media</option>
												<option value={4}>Website</option>
												<option value={5}>Paid ads</option>
												<option value={6}>LinkedIn</option>
												<option value={7}>Work-ins</option>
												<option value={8}>Other</option>
												<option value={9}>Unknown</option>
											</select>
											<SelectPointer />
										</div>
									</div>
								</div>
								<div className="flex flex-wrap -mx-3 mb-2 mt-6">
									<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
											htmlFor="grid-status">
											Status:
										</label>
										<div className="relative">
											<select
												value={leadStatus}
												disabled={edit}
												onChange={(e) => setLeadStatus(e.target.value)}
												className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
												id="grid-status">
												<option value={0}>Prospect</option>
												<option value={1}>Contact Made</option>
												<option value={2}>Meeting booked</option>
												<option value={3}>Proposal</option>
												<option value={4}>Awating feedback</option>
												<option value={5}>Negotiating</option>
												<option value={6}>Won deal</option>
												<option value={7}>Lost deal</option>
											</select>
											<SelectPointer />
										</div>
									</div>
									<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
											htmlFor="grid-email">
											Deal value(N):
										</label>
										<Cleave
											value={dealValue}
											disabled={edit}
											onChange={(e) => setDealValue(e.target.value.replace(/[^0-9_.]/g, ""))}
											required
											className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											options={{
												numeral: true,
												numeralThousandsGroupStyle: "thousand",
												prefix: "NGN ",
											}}
										/>
										{/* <input
											value={dealValue}
											disabled={edit}
											onChange={(e) => setDealValue(e.target.value)}
											required
											className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-email"
											type="number"
											placeholder="value"
										/> */}
									</div>
									<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
											htmlFor="grid-email">
											Priority:
										</label>
										<div className="relative">
											<select
												disabled={edit}
												value={priority}
												onChange={(e) => setPriority(e.target.value)}
												className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
												id="grid-state">
												<option value={0}>High</option>
												<option value={2}>Mid</option>
												<option value={3}>Low</option>
											</select>
											<SelectPointer />
										</div>
									</div>
								</div>
								{!edit && (
									<div className="mt-6">
										<button
											type="submit"
											className="bg-orange-500 hover:bg-orange-700 text-white  text-xs py-2 px-4 rounded">
											{!savingLead ? "Update lead" : "Saving..."}
										</button>
									</div>
								)}
							</form>
						</>
					) : (
						<div className="flex justify-center">
							<Pageloader />
						</div>
					)}
				</div>
				<div className="lg:w-1/3 w-full mx-2">
					<div className="bg-white rounded-sm px-2 lg:px-4 my-3 pb-2">
						<div className="flex justify-between px-2 pt-4 pb-3">
							<p className="text-sm font-bold">Prospect information</p>
							{/* <div className="flex text-xs text-gray-600 cursor-pointer">
								<EditIcon /> <span className="pt-0 mx-2">EDIT</span>
							</div> */}
						</div>
						<hr className="border-gray-300 pb-2" />
						<div className="px-2 lg:px-2 mt-4">
							<p className="capitalize text-sm">{contact.name}</p>
							<p className=" capitalize text-xs">{contact.context}</p>
						</div>
						<div className="px-2 lg:px-2 mt-4 text-gray-600 text-xs">
							<p className="capitalize text-sm">{organization}</p>
							{/* <p className="text-gray-600 capitalize text-xs">email@expample.com</p> */}
							<p className="capitalize text-sm">{phone}</p>
						</div>
						<hr className="mt-6 border-dashed border-gray-300" />
						<p className="font-bold text-xs">Date created:</p>
						<p className="text-xs text-gray-600">
							{createdAt.length > 0 ? format(new Date(createdAt), "do MMMM yyyy") : ""}
						</p>
					</div>

					<div className="bg-white rounded-md px-2 lg:px-4 my-3">
						<div className="flex justify-between px-2 pt-4 pb-3">
							<p className="text-sm font-bold">Recent activities</p>
						</div>
						<hr className="border-gray-300 pb-2" />
						{activities.events?.map((event, i) => {
							if (i < 4) {
								return (
									<div key={i} className="text-xs pb-4 flex justify-between mt-2">
										<div style={{ width: "180px" }}>
											<p className="text-xs font-bold">
												<span dangerouslySetInnerHTML={{ __html: event.description }} />
											</p>
											<p className="text-xs text-gray-600">
												{event.user.firstName} {event.user.lastName}
											</p>
										</div>
										<div className="flex text-gray-600">
											<AvTimerOutlined />{" "}
											<span>
												{formatDistance(new Date(event.createdAt), new Date(), {
													addSuffix: true,
												})}
											</span>
										</div>
									</div>
								);
							}
						})}
					</div>
				</div>
			</div>
			{/* Open new address */}
			{/* <Modal
				isOpen={openNewAddress}
				onRequestClose={() => setOpenNewAddress(!openNewAddress)}
				contentLabel="Modal"
				style={customStyles}>
				<div className="px-4 mb-4">
					<div className="flex justify-end">
						<button onClick={() => setOpenNewAddress(!openNewAddress)}>
							<CloseIcon />
						</button>
					</div>
					Add address
					<div>
						<textarea
							className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							rows="4"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							type="text"
							placeholder="Enter address"
						/>
						<button
							onClick={() => onSaveAddress()}
							className="bg-orange-500 hover:bg-orange-700 mt-3 text-white font-bold text-xs py-3 px-4 rounded">
							Save Address
						</button>
					</div>
				</div>
			</Modal> */}
		</div>
	);
};

const mapStateToProps = (state) => ({
	lead: state.leads.lead,
	activities: state.activities.activities,
});

export default connect(mapStateToProps, {
	getSingleLead,
	updateLead,
	filteredActivity,
	deleteLeads,
})(ViewLead);
