import React from "react";

export const ArkLogo = () => {
	return (
		<svg width={62} height={25} viewBox="0 0 62 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.883789 19.3932V19.326C0.883789 15.635 3.56105 13.724 7.58577 13.724C8.98061 13.7257 10.3656 13.9589 11.6846 14.4141V14.1204C11.6846 12.2731 10.5805 11.2185 8.27713 11.2185C6.65926 11.2166 5.05887 11.5542 3.57869 12.2094L2.35117 7.79295C4.52639 6.83959 6.88084 6.36765 9.25421 6.40927C12.3124 6.40927 14.3936 7.16658 15.7516 8.58211C17.0426 9.90209 17.5788 11.7458 17.5788 14.2442V24.5068H11.6528V22.6631C11.0371 23.3904 10.2664 23.9696 9.39757 24.3579C8.52878 24.7462 7.58412 24.9338 6.63338 24.9067C3.37411 24.9067 0.883789 22.9497 0.883789 19.3932ZM11.7269 18.0414V17.2168C10.9875 16.911 10.1951 16.7546 9.39531 16.7568C7.65985 16.7568 6.65102 17.645 6.65102 18.965V19.0287C6.65102 20.2496 7.53639 20.9751 8.76744 20.9751C10.5135 20.9751 11.7269 19.8214 11.7269 18.0414Z"
				fill="white"
			/>
			<path
				d="M20.6191 6.67125H26.6156V10.2632C27.5927 7.82491 29.1695 6.24305 32.009 6.37045V12.9633H31.5046C28.3829 12.9633 26.6156 14.8424 26.6156 18.9793V24.514H20.6191V6.67125Z"
				fill="white"
			/>
			<path
				d="M34.1112 0.442871H40.1077V12.6377L44.7285 6.67121H51.5081L45.5821 13.8232L51.6668 24.4998H45.0813L41.6421 18.4378L40.0971 20.3134V24.4998H34.1006L34.1112 0.442871Z"
				fill="white"
			/>
			<path
				d="M61.5121 20.6603C61.4872 21.3117 61.2719 21.9413 60.893 22.4708C60.514 23.0003 59.9883 23.4063 59.3811 23.6383C58.7739 23.8703 58.1121 23.918 57.4781 23.7755C56.8441 23.6329 56.2659 23.3065 55.8154 22.8367C55.365 22.367 55.0621 21.7747 54.9447 21.1336C54.8273 20.4925 54.9003 19.8309 55.1549 19.2311C55.4094 18.6314 55.8341 18.12 56.3762 17.7606C56.9182 17.4013 57.5537 17.2097 58.2034 17.21C58.6466 17.2182 59.0838 17.3141 59.49 17.492C59.8962 17.67 60.2634 17.9265 60.5707 18.247C60.878 18.5674 61.1194 18.9455 61.2809 19.3596C61.4424 19.7737 61.521 20.2157 61.5121 20.6603Z"
				fill="#F26925"
			/>
		</svg>
	);
};

export const BranchIcon = () => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18 13.9048C16.575 13.9048 15.45 14.8952 15.075 16.1905H8.25C6.15 16.1905 4.5 14.5143 4.5 12.381V10.0952H15.075C15.375 11.3905 16.575 12.381 18 12.381C19.65 12.381 21 11.0095 21 9.33333C21 7.65714 19.65 6.28571 18 6.28571C16.575 6.28571 15.45 7.27619 15.075 8.57143H4.5V4.7619C4.5 4.30476 4.2 4 3.75 4C3.3 4 3 4.30476 3 4.7619V12.381C3 15.3524 5.325 17.7143 8.25 17.7143H15.075C15.375 19.0095 16.575 20 18 20C19.65 20 21 18.6286 21 16.9524C21 15.2762 19.65 13.9048 18 13.9048ZM18 7.80952C18.825 7.80952 19.5 8.49524 19.5 9.33333C19.5 10.1714 18.825 10.8571 18 10.8571C17.175 10.8571 16.5 10.1714 16.5 9.33333C16.5 8.49524 17.175 7.80952 18 7.80952ZM18 18.4762C17.175 18.4762 16.5 17.7905 16.5 16.9524C16.5 16.1143 17.175 15.4286 18 15.4286C18.825 15.4286 19.5 16.1143 19.5 16.9524C19.5 17.7905 18.825 18.4762 18 18.4762Z"
				fill="#666666"
			/>
		</svg>
	);
};

export const MegaphoneIcon = () => {
	return (
		<svg width={102} height={100} viewBox="0 0 102 100" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="42.9217" cy="48.4979" r="39.6" transform="rotate(10 42.9217 48.4979)" fill="#F5F5F5" />
			<path
				d="M19.1604 60.9589C19.9542 63.9459 23.1022 65.7633 26.0859 64.9573C26.749 64.7782 27.1139 64.146 26.9375 63.4823L22.2627 45.8923C22.0863 45.2285 21.4567 44.865 20.7937 45.0441C17.8099 45.8501 15.985 49.0109 16.7789 51.9979L19.1604 60.9589Z"
				fill="#F8AC87"
			/>
			<path
				d="M79.3122 60.0051C79.4886 60.6689 80.1182 61.0324 80.7812 60.8533L82.378 60.5109C84.4113 60.1395 85.6279 58.0323 85.0986 56.0409L76.4942 21.9936C76.1413 20.666 74.8822 19.939 73.6169 20.1919L70.2575 20.9215C69.5945 21.1006 69.2295 21.7328 69.4059 22.3966L69.9351 24.3879L78.7556 57.5766L79.3122 60.0051Z"
				fill="#F8AC87"
			/>
			<path
				d="M100.129 30.3727L93.1196 32.2416C92.4521 32.4196 92.0862 33.0534 92.2658 33.7205C92.3556 34.054 92.6123 34.3431 92.8241 34.4653C93.0358 34.5876 93.4145 34.6654 93.7483 34.5764L100.757 32.7075C101.425 32.5295 101.791 31.8957 101.611 31.2286C101.431 30.5615 100.69 30.1336 100.129 30.3727Z"
				fill="black"
			/>
			<path
				d="M89.6415 29.9519C90.0651 30.1965 90.5047 30.1686 90.9605 29.8683L95.5178 26.8657C96.0345 26.4598 96.1886 25.7038 95.887 25.2479C95.4796 24.731 94.7223 24.5755 94.2665 24.8757L89.7092 27.8783C89.1925 28.2842 89.0383 29.0403 89.3399 29.4961C89.3238 29.7685 89.4297 29.8296 89.6415 29.9519Z"
				fill="black"
			/>
			<path
				d="M98.4204 37.9782L92.965 37.6454C92.3136 37.551 91.7359 38.0626 91.7486 38.7741C91.7164 39.3189 91.9121 39.7136 92.3357 39.9582C92.5475 40.0804 92.6534 40.1416 92.8203 40.0971L98.2756 40.4298C98.9271 40.5242 99.5048 40.0127 99.492 39.3011C99.4793 38.5896 99.0718 38.0726 98.4204 37.9782Z"
				fill="black"
			/>
			<path
				d="M75.427 15.6889L72.0446 16.4121C71.4829 16.6512 70.8603 16.9959 70.5554 17.5241C70.2505 18.0523 70.0963 18.8084 70.3369 19.3698L70.6063 20.3704L25.4997 42.4969L25.304 42.1023C25.1244 41.4352 24.7169 40.9182 24.1874 40.6126C23.658 40.3069 23.0066 40.2125 22.3391 40.3904C20.6703 40.8354 19.1201 42.0531 18.2053 43.6376C17.2905 45.2221 17.072 47.0678 17.566 48.9023L19.9907 57.9078C20.4846 59.7423 21.6012 61.232 23.1895 62.149C24.7779 63.066 26.6263 63.2881 28.462 62.7987C29.797 62.4427 30.5288 61.1751 30.1696 59.8409L29.9451 59.0071L34.8871 58.7618L34.999 61.5024C35.0948 64.5155 36.8595 67.0836 39.2949 68.4897C40.6715 69.2845 42.2921 69.6567 43.9896 69.6509C48.6589 69.389 52.3403 65.4581 52.1291 60.6883L52.0333 57.6753L80.2766 55.9534L80.6358 57.2876C81.056 58.5161 82.2657 59.3554 83.4948 58.9382L85.1026 58.5989C86.3767 58.3486 87.516 57.5979 88.1868 56.4359C88.8577 55.274 89.044 53.9731 88.7907 52.7001L79.8744 18.2566C79.3805 16.4221 77.4296 15.1549 75.427 15.6889ZM22.2211 57.2237L19.7964 48.2182C19.482 47.0508 19.7132 45.9167 20.3231 44.8603C20.933 43.804 21.9054 43.0978 23.0736 42.7864L27.7274 60.4028C25.3911 61.0257 22.9556 59.6196 22.2211 57.2237ZM49.5522 60.7499C49.7379 64.0965 47.106 66.9432 43.7557 67.1215C40.4055 67.2999 37.5498 64.6653 37.3641 61.3186L37.2522 58.578L49.3954 57.8424L49.5522 60.7499ZM85.9182 54.9853C85.6133 55.5135 84.9297 55.9639 84.3232 56.0362L82.7154 56.3756L82.0258 54.1464C81.8911 53.6461 81.3167 53.1737 80.7102 53.246L50.4805 55.2294L35.8662 56.0877L29.2715 56.5056L26.1283 44.8317L71.174 22.8108L78.1789 48.8268C78.3585 49.4939 78.9938 49.8607 79.6613 49.6827C80.3288 49.5047 80.6948 48.8709 80.5151 48.2039L73.2569 20.9148L72.7181 18.9136L76.1006 18.1904C76.7681 18.0124 77.4034 18.3792 77.583 19.0463L86.3485 53.2619C86.4832 53.7622 86.3291 54.5183 85.9182 54.9853Z"
				fill="black"
			/>
			<path
				d="M62.9145 40.296L49.5643 43.8558C48.8968 44.0338 48.5309 44.6676 48.7105 45.3346C48.8003 45.6682 49.057 45.9572 49.2687 46.0795C49.4805 46.2018 49.8592 46.2795 50.1929 46.1905L63.5432 42.6307C64.2107 42.4528 64.5766 41.819 64.397 41.1519C64.2174 40.4848 63.5821 40.118 62.9145 40.296Z"
				fill="black"
			/>
			<path
				d="M51.7083 41.8541C52.0259 42.0375 52.2987 42.0542 52.6325 41.9652L59.3076 40.1853C59.9751 40.0073 60.341 39.3735 60.1614 38.7064C59.9818 38.0394 59.3464 37.6725 58.6789 37.8505L52.0038 39.6304C51.3363 39.8084 50.9704 40.4422 51.15 41.1093C51.2398 41.4428 51.4965 41.7319 51.7083 41.8541Z"
				fill="black"
			/>
			<path
				d="M60.4753 44.5209L53.8002 46.3008C53.1327 46.4788 52.7667 47.1126 52.9464 47.7797C53.0362 48.1133 53.2929 48.4023 53.5046 48.5246C53.7164 48.6468 54.0951 48.7246 54.4288 48.6356L61.104 46.8557C61.7715 46.6777 62.1374 46.0439 61.9578 45.3769C61.7782 44.7098 61.1428 44.343 60.4753 44.5209Z"
				fill="black"
			/>
		</svg>
	);
};

export const OrderIcon = () => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6 15L12 18L18 15M6 12L12 15L18 12M12 6L6 9L12 12L18 9L12 6Z"
				stroke="#525252"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const StatusIcon = () => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7 18V13.3333M7 10.6667V6M12.3333 18V12M12.3333 9.33333V6M17.6667 18V14.6667M17.6667 12V6M5 13.3333H9M10.3333 9.33333H14.3333M15.6667 14.6667H19.6667"
				stroke="#525252"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const NumberIcon = () => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7 10H17.6667M7 14H17.6667M11 6L9.66667 18M15 6L13.6667 18"
				stroke="#525252"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CloseIcon = () => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				stroke="black"
				strokeWidth="1.8"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.1712 9.17157L14.8281 14.8284M9.1712 14.8284L14.8281 9.17157"
				stroke="black"
				strokeWidth="1.8"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const EditIcon = () => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.1566 5.68682H5.59035C5.16857 5.68682 4.76405 5.85438 4.4658 6.15263C4.16755 6.45088 4 6.85539 4 7.27718V18.4096C4 18.8314 4.16755 19.2359 4.4658 19.5342C4.76405 19.8324 5.16857 20 5.59035 20H16.7228C17.1446 20 17.5491 19.8324 17.8474 19.5342C18.1456 19.2359 18.3132 18.8314 18.3132 18.4096V12.8434M17.1204 4.49406C17.4368 4.17772 17.8658 4 18.3132 4C18.7605 4 19.1896 4.17772 19.5059 4.49406C19.8223 4.8104 20 5.23945 20 5.68682C20 6.1342 19.8223 6.56325 19.5059 6.87959L11.9518 14.4338L8.77106 15.2289L9.56624 12.0482L17.1204 4.49406Z"
				stroke="#666666"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
