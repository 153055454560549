import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import Modal from "react-modal";
import {
	AttachFileOutlined,
	AvTimerOutlined,
	CalendarTodayOutlined,
	Delete,
	PersonOutline,
	QueryBuilderOutlined,
	Send,
} from "@material-ui/icons";
import { CloseIcon, OrderIcon } from "../../../../assets/Svg";
import CustomCheckbox from "../../../../components/commons/custom-checkbox/custom-checkbox";
import {
	addJobAttachment,
	assignSupervisor,
	deleteOrderFiles,
	getJobComments,
	getSingleJob,
	setOpenModal,
	updateJobItemFiles,
	updateWorkflow,
} from "../../../../redux/actions/joblogs.actions";
import { connect } from "react-redux";
import Pageloader from "../../../../components/page-loader/Pageloader";
import { format } from "date-fns";
import { endOfToday, formatDistance } from "date-fns/esm";
import { getActivities } from "../../../../redux/actions/activities.actions";
import { definedOrderStatus, workflowValues } from "../../../../utils/definedValues";
import { JobStatusIcon, JobPrintIcon } from "./icons";
import { Tooltip } from "@material-ui/core";
import { addJobComment } from "../../../../redux/actions/order.actions";
import { getAllUsers, uploadImage } from "../../../../redux/actions/users.actions";
import AddRecipient from "./AddRecipient";
import StackedImages from "../../../../components/commons/Stacked-images";
import SwitchJobStatus from "./SwitchJobStatus";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "500px",
		height: "400px",
		paddingLeft: "0px",
		paddingRight: "0px",
		paddingBottom: "20px",
		overflow: "scroll",
	},
};

const ViewJobKanban = (props) => {
	const {
		setOpen,
		targetItem,
		job,
		getSingleJob,
		fetchedJob,
		getActivities,
		activities,
		addJobComment,
		getJobComments,
		uploadImage,
		addJobAttachment,
		comments,
		updateJobItemFiles,
		getAllUsers,
		assignSupervisor,
		updateWorkflow,
		deleteOrderFiles,
		closeModal,
		setOpenModal,
	} = props;
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const [comment, setComment] = useState("");
	const [uploading, setUploading] = useState("");
	const [clientImage, setClientImage] = useState("");
	const [userOptions, setUserOptions] = useState([]);
	const [openAddRecipient, setOpenAddRecipient] = useState("");
	const [allUsers, setAllusers] = useState([]);

	const style = {
		button: { backgroundColor: "#1B998B" },
		text: {
			color: "#1B998B",
		},
	};

	useEffect(() => {
		if (job) {
			onGetJob();
			getActivities(1, 20);
			getJobComments(job._id, targetItem);
		}
		onGetAllUser();
		return;
	}, []);

	const onGetJob = async () => {
		setLoading(true);
		let data = await getSingleJob(job._id, job.items._id);
		if (data) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const onGetAllUser = async () => {
		let tempAllUsers = [];
		let data = await getAllUsers(100000);
		if (data.success) {
			data.data.users.map((user) => {
				tempAllUsers.push({ value: user._id, label: `${user.firstName} ${user.lastName}` });
			});
			setUserOptions(tempAllUsers);
			setAllusers(data.data.users);
		}
	};

	const toCheck = (index, currentStage) => {
		if (currentStage === index || index < currentStage) {
			return true;
		} else {
			return false;
		}
	};

	const onAddComment = async () => {
		if (comment.length > 0) {
			let commentDetails = {
				message: comment,
				jobId: job.items._id,
			};
			let res = await addJobComment(job._id, commentDetails);
			if (res.success) {
				setComment("");
				getJobComments(job._id, job.items._id);
			}
		}
	};

	const onUploadImage = async (file, label) => {
		setUploading(label);
		const data = new FormData();
		data.append("file", file);
		const config = { headers: { "Content-Type": "multipart/form-data" } };
		let upload = await uploadImage(data, config);
		if (upload.success) {
			let fileDetail = {
				[label]: upload.data,
			};
			let persistFile = await updateJobItemFiles(job.items._id, job._id, fileDetail);
			if (persistFile.success) {
				getSingleJob(job._id, job.items._id);
				addToast(persistFile.message, { appearance: "success" });
			} else {
				addToast(persistFile?.message, { appearance: "error" });
			}
		} else {
			addToast(upload?.message, { appearance: "error" });
		}
		setUploading("");
	};

	const onAssignSupervisor = async (userId) => {
		let assigneeDetails = {
			assignedTo: userId,
		};
		let data = await assignSupervisor(job._id, assigneeDetails);
		if (data.success) {
			getSingleJob(job._id, job.items._id);
			addToast(data?.message, { appearance: "success" });
			setOpenAddRecipient(false);
		} else {
			addToast(data?.message, { appearance: "error" });
		}
	};

	const onUpdateWorkflow = async (value) => {
		let updateDetails = { newFlow: parseInt(value) };
		let workflowData = await updateWorkflow(job._id, job.items._id, updateDetails);
		if (workflowData.success) {
			getSingleJob(job._id, job.items._id);
			addToast(workflowData?.message, { appearance: "success" });
		} else {
			addToast(workflowData?.message, { appearance: "error" });
		}
	};

	const onDeleteMockFile = async (fileId) => {
		let token = JSON.parse(sessionStorage.getItem("_ark_task")).token;
		let file = {
			attachment: fileId,
		};
		let response = await deleteOrderFiles(job._id, job.items._id, file, token);
		if (response?.success) {
			addToast("file deleted", { appearance: "success" });
			getSingleJob(job._id, job.items._id);
		} else {
			addToast(response?.message, { appearance: "error" });
		}
	};

	const onDeleteJobReady = async (fileId) => {
		let token = JSON.parse(sessionStorage.getItem("_ark_task")).token;
		let file = {
			attachment: fileId,
		};
		let response = await deleteOrderFiles(job._id, job.items._id, file, token);
		if (response?.success) {
			addToast("file deleted", { appearance: "success" });
			getSingleJob(job._id, job.items._id);
		} else {
			addToast(response?.message, { appearance: "error" });
		}
	};

	return (
		<div className={!loading ? "grid  grid-cols-1 lg:grid-cols-2 mb-6" : ""}>
			{!loading ? (
				<>
					{" "}
					<div className="h-full w-full px-4 py-2" style={{ backgroundColor: "#E5E5E5" }}>
						<div className="pr-20 pt-8">
							<p className="text-gray-800 text-xl">{fetchedJob?.title}</p>
						</div>
						<div className="pt-3">
							<p className="flex text-xs text-gray-600">
								<span className="pr-2">
									<OrderIcon />
								</span>
								Sample order card that this job belongs to and stuff
							</p>
						</div>
						<div className="mt-2">
							<p className="flex text-xs text-gray-600">
								<span className="pr-2">
									<PersonOutline />
								</span>
								{fetchedJob?.assignees?.length < 1 && <span>No job supervisor yet</span>}
								{fetchedJob?.assignees?.length > 0 && (
									<span className=" cursor-pointer px-1">
										{" "}
										{fetchedJob?.assignees?.map((assignee, i) => {
											return (
												<span key={i}>
													<p key={i}>{`${assignee.firstName} ${assignee.lastName}`}</p>
												</span>
											);
										})}{" "}
										<span onClick={() => setOpenAddRecipient(true)} className=" text-orange-500">
											Assign someone
										</span>
									</span>
								)}
							</p>
						</div>
						<div className="flex justify-between">
							<span className="text-xs" style={style.text}>
								<button
									style={style.button}
									className="py-1 px-4 rounded button-outline m-3 text-white text-xs cursor-not-allowed">
									{format(new Date(job ? job.createdAt : endOfToday()), "Lo, MMM")}
								</button>{" "}
								<span>
									{formatDistance(new Date(job.createdAt), new Date(), {
										addSuffix: true,
									})}
								</span>
							</span>
							<span className="px-2 pt-4">
								<StackedImages assignees={fetchedJob?.assignees} />
							</span>
						</div>
						{/* customer information */}
						<div className="bg-white rounded-sm mt-4 px-2 lg:px-4 my-3 pb-2">
							<div className=" px-2 pt-4 pb-3">
								<p className="text-sm font-bold">Customer information</p>
								<hr className="border-gray-300 pb-2" />
							</div>
							<div className="mt-4">
								<p className="uppercase text-gray-600 text-xs">Customer name:</p>
								<p className="capitalize text-gray-900 text-xs py-1">{fetchedJob?.customer?.name}</p>
							</div>
							<div className="mt-4">
								<p className="uppercase text-gray-600 text-xs">Contact person:</p>
								<p className="capitalize text-gray-900 text-xs py-1">
									{fetchedJob?.customer?.contacts[0]?.name}
								</p>
							</div>
							<div className="mt-4">
								<p className="uppercase text-gray-600 text-xs">Email address:</p>
								<p className="capitalize text-gray-900 text-xs py-1">
									{fetchedJob?.customer?.contacts[0]?.email}
								</p>
							</div>
							<div className="mt-4">
								<p className="uppercase text-gray-600 text-xs">Phone number:</p>
								<p className="capitalize text-gray-900 text-xs py-1">
									{fetchedJob?.customer?.contacts[0]?.phone}
								</p>
							</div>
							<div className="mt-4">
								<p className="uppercase text-gray-600 text-xs">Delivery address:</p>
								<p className="capitalize text-gray-900 text-xs py-1">
									{fetchedJob?.customer?.addresses ? fetchedJob?.customer?.addresses[0]?.address : ""}
								</p>
							</div>
						</div>
						{/* recent activities */}
						<div className="bg-white rounded-sm px-2 lg:px-4 my-3">
							<div className="flex justify-between px-2 pt-4 pb-3">
								<p className="text-sm font-bold">Recent activities</p>
							</div>
							<hr className="border-gray-300 pb-2" />
							{activities.events?.map((event, i) => {
								if (i < 4) {
									return (
										<div key={i} className="text-xs pb-4 flex justify-between mt-2">
											<div>
												<p className="text-xs font-bold">
													<span dangerouslySetInnerHTML={{ __html: event.description }} />
												</p>
												{/* <p className="text-xs text-gray-600">{`${event.user.firstName} ${event.user.lastName}`}</p> */}
											</div>
											<div className="flex text-gray-600">
												<AvTimerOutlined />{" "}
												<span>
													{formatDistance(new Date(event.createdAt), new Date(), {
														addSuffix: true,
													})}
												</span>
											</div>
										</div>
									);
								}
							})}
						</div>
					</div>
					{/* right hand grid */}
					<div className="px-4 py-2">
						<div className="flex justify-end pt-6">
							<button onClick={() => setOpenModal("")}>
								<CloseIcon modalOpen={closeModal} />
							</button>
						</div>
						<div className="mt-4">
							<p className="uppercase text-gray-600 text-xs">Current stage:</p>
							<p className="capitalize text-gray-900 text-lg py-1">Finishing</p>
						</div>
						{/* actions */}
						<div className="md:flex pt-6">
							<Tooltip title={format(new Date(job ? job.dueAt : endOfToday()), "Lo, MMM, yyyy")}>
								<button
									style={{ backgroundColor: "#FEF2EC", color: "#F26925", border: "1px dashed #F26925" }}
									className="py-1 text-xs px-4 mx-3 uppercase rounded inline-flex items-center">
									<CalendarTodayOutlined style={{ fontSize: "12px" }} fontSize="small" />
									<span className="px-2">Due date</span>
								</button>
							</Tooltip>
							<button
								onClick={() => setOpenAddRecipient(true)}
								style={{ backgroundColor: "#FEF2EC", color: "#F26925", border: "1px dashed #F26925" }}
								className="py-1 text-xs px-4 mx-3 capitalize rounded inline-flex items-center">
								<PersonOutline style={{ fontSize: "12px" }} fontSize="small" />
								<span className="px-2">Assigness</span>
							</button>

							{/* <Tooltip title={definedOrderStatus[job.status]}>
								<button
									style={{ backgroundColor: "#FEF2EC", color: "#F26925", border: "1px dashed #F26925" }}
									className="py-1 text-xs px-4 mx-3 capitalize rounded inline-flex items-center">
									<JobStatusIcon />
									<span className="px-2">Status</span>
								</button>
							</Tooltip> */}
							<SwitchJobStatus job={job} />

							<button
								style={{ backgroundColor: "#FEF2EC", color: "#F26925", border: "1px dashed #F26925" }}
								className="py-1 text-xs px-4 mx-3 capitalize rounded inline-flex items-center">
								<JobPrintIcon />
								<span className="px-2">Print</span>
							</button>
							<hr className="mt-2 border-dashed border-gray-400" />
						</div>

						{/* Product info */}
						<section className="mt-6">
							<div className="mt-4">
								<p className="uppercase text-gray-600 text-xs">Product</p>
								<p className="capitalize text-gray-900 py-1 text-sm">
									{fetchedJob?.items?.product?.name}
								</p>
							</div>
							<div className="mt-6">
								<p className="uppercase text-gray-600 text-xs">Quantity</p>
								<p className="capitalize text-gray-900 py-1 text-sm">{fetchedJob?.items?.quantity}</p>
							</div>
							<div className="mt-6">
								<p className="uppercase text-gray-600 text-xs">Specs</p>
								<p className="capitalize text-gray-900 py-1 text-sm">
									{fetchedJob?.items?.specifications}
								</p>
							</div>
							<hr className="mt-4 border-dashed border-gray-400" />
						</section>
						{/* attachement */}
						<section className="mt-6">
							<p className="uppercase text-gray-500 text-xs">Attachments</p>
							<div className="md:flex capitalize">
								<span className="pt-3 text-sm">Client file: </span>
								{fetchedJob?.items?.attachments?.length > 0 && (
									<span className="ml-16 pt-2 flex">
										{fetchedJob?.items?.attachments?.map((file, i) => {
											return (
												<span className="m-1 flex" key={i}>
													<a href={file.url} target="_blank">
														<img
															src={file.url.length > 0 ? file.url : ""}
															alt="upload"
															height="36"
															width="36"
														/>
													</a>
													{/* <span onClick={() => onDeleteMockFile(file._id)}>
														{" "}
														<Delete />{" "}
													</span> */}
												</span>
											);
										})}
									</span>
								)}
							</div>

							<div className="md:flex capitalize mt-4">
								<span className="pt-3 text-sm">Mockup file: </span>
								<span className="m-1 ">
									{fetchedJob?.items?.mockup?.url.length > 0 && (
										<span className="flex">
											<a
												className="hover:text-orange-400"
												href={fetchedJob?.items?.mockup?.url}
												target="_blank">
												{fetchedJob?.items?.mockup?.url?.length > 0
													? fetchedJob?.items?.mockup?.name
													: "unnamed file"}
												{/* <img
													src={
														fetchedJob?.items?.mockup?.url?.length > 0
															? fetchedJob?.items?.mockup?.url
															: ""
													}
													alt="upload"
													height="36"
													width="36"
												/> */}
											</a>
											<span
												className="cursor-pointer"
												onClick={() => onDeleteMockFile(fetchedJob?.items?.mockup?._id)}>
												{" "}
												<Delete />{" "}
											</span>
										</span>
									)}
								</span>
								<div className="pt-3 px-3 text-orange-600 text-xs ml-4">
									<label className="flex cursor-pointer">
										<AttachFileOutlined />
										<input
											onChange={(e) => onUploadImage(e.target.files[0], "mockup")}
											type="file"
											className="hidden"
										/>
										{uploading === "mockup" ? "processing..." : "Upload mockup"}
									</label>
								</div>
							</div>

							<div className="md:flex capitalize mt-4">
								<span className="pt-3 text-sm">Print Ready file: </span>
								<span className="m-1 ml-1">
									{fetchedJob?.items?.printReady?.url?.length > 0 && (
										<span className="flex">
											<a
												className="hover:text-orange-400"
												href={fetchedJob?.items?.printReady?.url}
												target="_blank">
												{fetchedJob?.items?.printReady?.url?.length > 0
													? fetchedJob?.items?.printReady?.name
													: "unnamed file"}
												{/* <img
													src={
														fetchedJob?.items?.printReady?.url?.length > 0
															? fetchedJob?.items?.printReady?.url
															: ""
													}
													alt="upload"
													height="36"
													width="36"
												/> */}
											</a>
											<span
												className="cursor-pointer"
												onClick={() => onDeleteJobReady(fetchedJob?.items?.mockup?._id)}>
												{" "}
												<Delete />{" "}
											</span>
										</span>
									)}
								</span>
								<div className="pt-3 px-3 text-orange-600 text-xs ml-2">
									<label className="flex cursor-pointer">
										<AttachFileOutlined />
										<input
											onChange={(e) => onUploadImage(e.target.files[0], "printReady")}
											type="file"
											className="hidden"
										/>
										{uploading === "printReady" ? "processing..." : "Upload print-ready file"}
									</label>
								</div>
							</div>
							<hr className="mt-4 border-dashed border-gray-400" />
						</section>

						{/* workflow */}
						<section className="mt-6">
							<p className="uppercase text-gray-500 text-xs">Workflow</p>
							{fetchedJob?.items?.product?.workflow.map((flow, i) => {
								return (
									<div key={i}>
										<CustomCheckbox
											label={workflowValues[flow]}
											checked={toCheck(i, fetchedJob?.items?.currentStage)}
											oncheck={() => onUpdateWorkflow(flow)}
										/>
									</div>
								);
							})}

							<hr className="mt-4 border-solid border-gray-400" />
						</section>
						{/* comment section */}
						<section className="mt-6 mb-6">
							<p className="capitalize text-gray-900 text-lg">Comments</p>
							<div style={{ maxHeight: "300px", overflowX: "scroll" }} className=" overflow-y-scroll">
								{comments.comments && comments.comments.length > 0 ? (
									comments.comments.map((commentData, i) => {
										return (
											<div key={i}>
												<div className="md:flex justify-between mt-6">
													<div className="md:flex">
														<img
															src={
																commentData.createdBy.profilePicture.length > 0
																	? commentData.createdBy.profilePicture
																	: "/uploads/profile-placeholder.jpg"
															}
															className="h-8 w-8 rounded-full"
															alt="profile-image"
														/>
														<span className="mx-2 text-xs pt-1">
															{commentData.createdBy.firstName} {commentData.createdBy.lastName}
														</span>
													</div>
													<div className="flex text-gray-600 text-xs">
														<QueryBuilderOutlined />
														<span className="mx-1"></span>
														{formatDistance(new Date(commentData.createdAt), new Date(), {
															addSuffix: true,
														})}
													</div>
												</div>
												<div className="px-8">
													<p className="text-xs text-gray-700">{commentData.message}</p>
												</div>
											</div>
										);
									})
								) : (
									<p className="text-xs text-gray-600 italic">No comment</p>
								)}
							</div>

							<div className="flex mt-6">
								<span className="pt-2">
									<img
										src={
											JSON.parse(sessionStorage.getItem("_ark_task")) &&
											JSON.parse(sessionStorage.getItem("_ark_task")).user.profilePicture.length > 0
												? JSON.parse(sessionStorage.getItem("_ark_task")).user.profilePicture
												: "/uploads/profile-placeholder.jpg"
										}
										className="h-8 w-8 rounded-full"
										alt="profile-image"
									/>
								</span>
								<input
									className="appearance-none block w-full mx-2 bg-orange-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="email"
									type="text"
									value={comment}
									onChange={(e) => setComment(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											onAddComment();
										}
									}}
									placeholder="comment..."
								/>
								<span
									onClick={() => onAddComment()}
									className="absolute right-0 mr-8 pt-3 cursor-pointer">
									<Send />
								</span>
							</div>
							<Modal
								isOpen={openAddRecipient}
								onRequestClose={() => setOpenAddRecipient(!openAddRecipient)}
								contentLabel="Modal"
								style={customStyles}>
								<div className="pb-3 p-8">
									<p className="uppercase">Assign Supervisor:</p>
									<Select onChange={(val) => onAssignSupervisor(val.value)} options={userOptions} />
									{/* <AddRecipient
										onAssignSupervisor={onAssignSupervisor}
										allUsers={allUsers}
										closeModal={setOpenAddRecipient}
									/> */}
								</div>
							</Modal>
						</section>
					</div>
				</>
			) : (
				<Pageloader />
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	fetchedJob: state.jobLogs.job,
	activities: state.activities.activities,
	comments: state.jobLogs.comments,
});

export default connect(mapStateToProps, {
	getSingleJob,
	getActivities,
	addJobComment,
	addJobAttachment,
	getJobComments,
	uploadImage,
	updateJobItemFiles,
	getAllUsers,
	assignSupervisor,
	updateWorkflow,
	deleteOrderFiles,
	setOpenModal,
})(ViewJobKanban);
