import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../../redux/actions/auth.actions";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100vh",
		width: "100%",
		overflow: "hidden",
	},
	paper: {
		marginTop: theme.spacing(10),
		display: "flex",
		boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
		padding: "20px",
		borderRadius: "14px",
		flexDirection: "column",
		border: "1px dashed #ccc",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Login = (props) => {
	const { login } = props;
	const [submitting, setSubmitting] = useState(false);
	const { addToast } = useToasts();
	const classes = useStyles();
	const [passwordVisibity, setPasswordVisibility] = useState(false);
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");

	const onLogin = async (e) => {
		e.preventDefault();
		let loginDetails = {
			email,
			password,
		};
		setSubmitting(true);
		let load = await login(loginDetails);
		if (load && load.success) {
			addToast("Login successful", { appearance: "success" });
			setTimeout(() => {
				if (load?.data?.user?.role === 1) {
					window.location.replace("/orders");
				} else {
					window.location.replace("/");
				}
			}, 100);
			setSubmitting(false);
		} else {
			addToast(load && load.message, { appearance: "error" });
			setSubmitting(false);
		}
	};

	return (
		<>
			<div class="grid grid-cols-2 gap-0">
				<div className="bg-cover hidden lg:block bg-login h-screen bg-center bg-no-repeat"></div>
				<div className="h-screen w-screen p-4 lg:w-full lg:p-8">
					<div>
						<img
							src="https://res.cloudinary.com/w3bh4ck/image/upload/v1600259673/ark/ark-logo-dark.png"
							alt="ark-logo"
							loading="lazy"
							className="rounded-full"
						/>
					</div>
					<div className="mt-6 lg:mt-12">
						<h3 className="text-sm lg:text-2xl">Log In</h3>
						<p className="text-xs text-gray-600 mt-2">
							Enter login details to access the Ark Task Manager and start tracking your tasks
						</p>
					</div>
					<form onSubmit={(e) => onLogin(e)} className="mt-5 lg:mt-12">
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
									htmlFor="email">
									Email
								</label>
								<input
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className="appearance-none block w-full bg-orange-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="email"
									type="email"
									placeholder="example@email.com"
								/>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-3">
							<div className="w-full px-3">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
									htmlFor="password">
									Password
								</label>
								<div className="relative">
									<input
										required
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										className="appearance-none block w-full bg-orange-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="password"
										type={!passwordVisibity ? "password" : "text"}
										placeholder="*********"
									/>
									<div
										onClick={() => setPasswordVisibility(!passwordVisibity)}
										className=" absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 cursor-pointer">
										{!passwordVisibity ? <Visibility /> : <VisibilityOff />}
									</div>
								</div>
							</div>
						</div>
						<div className="flex justify-between">
							<label className=" block text-gray-500 ">
								<input className="mr-2 leading-tight" type="checkbox" />
								<span className="text-sm">Keep me logged in</span>
							</label>
							<label className="block text-gray-500 cursor-pointer ">
								<Link to="/forgot-password">
									<span className="text-sm hover:text-orange-500">Forgot password</span>
								</Link>
							</label>
						</div>
						<div className="mt-6">
							<button
								type="submit"
								className="bg-orange-500 hover:bg-orange-700 w-full text-white font-bold text-xs py-3 px-4 rounded">
								{submitting ? "Loading..." : "Log In"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default connect(null, { login })(Login);
