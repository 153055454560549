import React from "react";
import "./screenLoader.css";

const ScreenLoader = () => {
	return (
		<div className="flex justify-center">
			<div className="lds-ripple">
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default ScreenLoader;
