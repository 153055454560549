import React, { useEffect, useState } from "react";
import { DeleteOutline } from "@material-ui/icons";
import { EditIcon } from "../../assets/Svg";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import { updateProfile, getSingleUser, updatePassword } from "../../redux/actions/auth.actions";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import Pageloader from "../../components/page-loader/Pageloader";
import { uploadImage } from "../../redux/actions/users.actions";
import { getNameInitials } from "../../utils/helper-functions";

const ProfilePage = (props) => {
	const { updateProfile, getSingleUser, uploadImage, updatePassword } = props;
	const { addToast } = useToasts();
	const [user, setUser] = useState({});
	const [firstName, setFirstName] = useState("");
	const [lastName, setlastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);
	const [editProfile, setEditProfile] = useState(false);
	const [profilePicture, setProfilePicture] = useState("");
	const [uploading, setUploading] = useState(false);
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [loadingPassword, setLoadingPassword] = useState(false);

	const onUpdatePassword = async (e) => {
		e.preventDefault();
		if (password === repeatPassword) {
			//send password
			let details = { password };
			let res = updatePassword(details);
			if (res) {
				addToast("Password updated", { appearance: "success" });
			} else {
				addToast(res?.message, { appearance: "error" });
			}
		} else {
			addToast("Password does not match", { appearance: "warning" });
		}
	};

	useEffect(() => {
		let userInfo = JSON.parse(window.sessionStorage.getItem("_ark_task"));
		if (userInfo) {
			const { user } = userInfo;
			onGetUserProfile(user._id);
		}
		return;
	}, []);

	const onUpdateProfile = async (e) => {
		e.preventDefault();
		setSaving(true);
		let userDetails = {
			firstName: firstName,
			lastName: lastName,
			profilePicture: profilePicture,
		};
		if (phone.length > 0) {
			userDetails.phone = phone;
		}

		let update = await updateProfile(userDetails);
		if (update.success) {
			addToast("Profile updated", { appearance: "success" });
			let userInfo = JSON.parse(window.sessionStorage.getItem("_ark_task"));
			userInfo.user = user;
			sessionStorage.setItem("_ark_task", JSON.stringify(userInfo));
			setSaving(false);
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		} else {
			setSaving(false);
			addToast(update?.message, { appearance: "error" });
		}
	};

	const onGetUserProfile = async (id) => {
		setLoading(true);
		let userData = await getSingleUser(id);
		const { data, success } = userData;
		if (success) {
			setLoading(false);
			setUser(data);
			setFirstName(data?.firstName);
			setlastName(data?.lastName);
			setEmail(data?.email);
			setPhone(data?.phone);
			setProfilePicture(data?.profilePicture);
		} else {
			setLoading(false);
		}
	};

	const onUploadImage = async (file) => {
		setUploading(true);
		const data = new FormData();
		data.append("file", file);
		const config = { headers: { "Content-Type": "multipart/form-data" } };
		let upload = await uploadImage(data, config);
		if (upload.success) {
			addToast(upload.message, { appearance: "success" });
			setProfilePicture(upload.data);
			let tempUser = user;
			tempUser.profilePicture = upload.data;
			setUser(tempUser);
		} else {
			addToast(upload?.message, { appearance: "error" });
		}
		setUploading(false);
	};

	/**
	 * @function: delete photo
	 */
	const onDeletePhoto = () => {
		setProfilePicture("");
		let tempUser = user;
		tempUser.profilePicture = "";
		setUser(tempUser);
	};

	return (
		<div
			style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}
			className="px-4">
			<DashboardNav history={props.history} />
			<div className="mt-10 px-3 lg:px-8 mb-4">
				<h1 className="text-md font-medium lg:text-2xl">My Profile</h1>
				<p className="text-xs text-gray-700">Update your profile</p>
			</div>
			{!loading ? (
				<div className="min-w-full bg-white rounded-md p-4 px-3 md:px-8 mt-8">
					<div className=" md:flex">
						<div className="w-full md:w-1/4 align-middle">
							{profilePicture.length > 0 ? (
								<img
									className=" md:ml-5  rounded-full"
									height="200px"
									width="200px"
									alt="profile"
									src={profilePicture.length > 0 ? profilePicture : "/uploads/profile-placeholder.jpg"}
								/>
							) : (
								<div
									style={{
										height: "200px",
										width: "200px",
										borderRadius: "50%",
										backgroundColor: "#f0efec",
									}}>
									<div className="flex justify-center">
										<p style={{ marginTop: "50px", fontSize: "60px" }}>
											{getNameInitials(`${user.firstName} ${user.lastName}`)}
										</p>
									</div>
								</div>
							)}
							<div className="flex mt-4 text-xs">
								<div className="flex mx-10  cursor-pointer overflow-hidden">
									<input
										onChange={(e) => onUploadImage(e.target.files[0])}
										accept="image/*"
										style={{ top: "0", bottom: "0", left: "0", width: "2px" }}
										className="opacity-0"
										type="file"
									/>
									{!uploading ? <EditIcon /> : <p className="xs">processing...</p>}{" "}
									{!uploading && <span className="text-gray-600">Edit</span>}
								</div>
								<div onClick={() => onDeletePhoto()} className="flex cursor-pointer text-red-600">
									<DeleteOutline /> <span className="text-red-600">Delete</span>
								</div>
							</div>
						</div>
						<div className="w-full md:w-3/4">
							<div className="flex justify-between">
								<div>
									<p className="text-sm text-gray-900 bold">Personal information</p>
								</div>
								<div onClick={() => setEditProfile(!editProfile)} className="flex mr-6 cursor-pointer">
									<EditIcon /> <span className="text-gray-600">Edit</span>
								</div>
							</div>
							<hr className="border-gray-200 w-full my-4" />
							{/* form */}
							<form onSubmit={(e) => onUpdateProfile(e)}>
								<div className="flex flex-wrap -mx-3 mb-6">
									<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
											htmlFor="grid-first-name">
											First Name
										</label>
										<input
											className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
											id="grid-first-name"
											type="text"
											required
											disabled={!editProfile}
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
											placeholder="first name"
										/>
									</div>
									<div className="w-full md:w-1/2 px-3">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
											htmlFor="grid-last-name">
											Last Name
										</label>
										<input
											className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-last-name"
											type="text"
											required
											disabled={!editProfile}
											value={lastName}
											onChange={(e) => setlastName(e.target.value)}
											placeholder="last name"
										/>
									</div>
								</div>
								<div className="flex flex-wrap -mx-3 mb-6">
									<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
											htmlFor="grid-first-email">
											Email Address
										</label>
										<input
											className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
											id="grid-first-email"
											type="text"
											required
											disabled={!editProfile}
											readOnly
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder="email"
										/>
									</div>
									<div className="w-full md:w-1/2 px-3">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
											htmlFor="grid-last-phone">
											Phone number
										</label>
										<input
											className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-last-phone"
											required
											disabled={!editProfile}
											type="text"
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
											placeholder="+2349039844844"
										/>
									</div>
								</div>
								<div className="mt-6">
									<button
										type="submit"
										className="bg-orange-500 hover:bg-orange-700 text-white  text-xs py-1 px-2 rounded">
										{!saving ? "Save Profile" : "Saving..."}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			) : (
				<Pageloader />
			)}

			<div className="mt-10 px-3 lg:px-8 mb-4">
				<h1 className="text-md font-medium lg:text-2xl">Account Settings</h1>
				<p className="text-xs text-gray-700">Update your account settings</p>
			</div>
			<div className="min-w-full bg-white rounded-md p-4 px-3 md:px-8 mt-8">
				<div className=" md:flex">
					<div className="w-full ">
						<form onSubmit={(e) => onUpdatePassword(e)}>
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="grid-first-email">
										New password
									</label>
									<input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
										type="password"
										required
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										placeholder="password"
									/>
								</div>
								<div className="w-full md:w-1/2 px-3">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="grid-last-phone">
										Repeat password
									</label>
									<input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										required
										type="password"
										value={repeatPassword}
										onChange={(e) => setRepeatPassword(e.target.value)}
										placeholder="repeat password"
									/>
								</div>
							</div>
							<div className="mt-6">
								<button
									type="submit"
									className="bg-orange-500 hover:bg-orange-700 text-white  text-xs py-1 px-2 rounded">
									{!loadingPassword ? "Change Password" : "Processing..."}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			{/* {!loading && (
				<div>
					<CustomersTable customers={customers} />
				</div>
			)}
			{loading === true && <ScreenLoader />} */}
		</div>
	);
};

export default connect(null, { updateProfile, getSingleUser, uploadImage, updatePassword })(ProfilePage);
