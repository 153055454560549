import axios from "axios";
import { addDays } from "date-fns";
import { taskActionTypes } from "../action-types/task-actions.types";

export const createTask = (taskDetails) => async (dispatch) => {
	let url = `/tasks`;
	let formatted = [];
	taskDetails.tasks.map((item) => {
		formatted.push({
			title: item.title,
			attachments: item.attachments,
			description: item.description,
			assignedTo: item.assignedTo,
			dueAt: item.dueAt.toISOString(),
			type: item.type,
		});
	});
	let details = { tasks: formatted };
	try {
		let task = await axios.post(url, details);
		const { success, data } = task.data;
		if ((success, data)) {
			return task.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getTasks = (filter) => async (dispatch) => {
	let inTwoDays = addDays(new Date(), 2);
	let inTwoDaysIso = inTwoDays.toISOString();

	let url;
	if (filter?.length > 0) {
		url = `/tasks?status=${filter}`;
	} else {
		url = `/tasks?status=0&status=1&status=2&status=4&dueAfter=${inTwoDaysIso}`;
	}
	try {
		let tasks = await axios.get(url);
		const { success, data } = tasks.data;
		if ((success, data)) {
			dispatch({
				type: taskActionTypes.GET_ALL_TASKS,
				payload: data,
			});
			return tasks.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getAllTasks = () => async (dispatch) => {
	let url = "/tasks";
	try {
		let tasks = await axios.get(url);
		const { success, data } = tasks.data;
		if ((success, data)) {
			dispatch({
				type: taskActionTypes.GET_ALL_TASKS,
				payload: data,
			});
			return tasks.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getRecentTasks = () => async (dispatch) => {
	let url = `/tasks`;
	try {
		let tasks = await axios.get(url);
		const { success, data } = tasks.data;
		if ((success, data)) {
			dispatch({
				type: taskActionTypes.GET_RECENT_TASK,
				payload: data,
			});
			return tasks.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getOverdueTasks = (filter) => async (dispatch) => {
	let d = new Date();
	let formattedDate = d.toISOString();
	let url;
	if (filter?.length > 0) {
		url = `/tasks?dueBefore=${formattedDate}&status=${filter}`;
	} else {
		url = `/tasks?dueBefore=${formattedDate}&status=1&status=2`;
	}

	try {
		let overduedTasks = await axios.get(url);
		const { success, data } = overduedTasks.data;
		if ((success, data)) {
			dispatch({
				type: taskActionTypes.GET_OVERDUE_TASK,
				payload: data,
			});
			return overduedTasks.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getTodayTasks = (filter) => async (dispatch) => {
	let inTwoDays = addDays(new Date(), 2);
	let inTwoDaysIso = inTwoDays.toISOString();
	let d = new Date();
	let formattedDate = d.toISOString();

	let url;
	if (filter?.length > 0) {
		url = `/tasks?dueAfter=${formattedDate}&dueBefore=${inTwoDaysIso}&status=${filter}`;
	} else {
		url = `/tasks?dueAfter=${formattedDate}&dueBefore=${inTwoDaysIso}&status=2&status=0&status=1&status=4`;
	}

	try {
		let todaysTasks = await axios.get(url);
		const { success, data } = todaysTasks.data;
		if ((success, data)) {
			dispatch({
				type: taskActionTypes.GET_TODAY_TASKS,
				payload: data,
			});
			return todaysTasks.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getDoneTasks = (filter) => async (dispatch) => {
	let url;
	if (filter?.length > 0) {
		url = `/tasks?status=3`;
	} else {
		url = `/tasks?status=3`;
	}

	try {
		let todaysTasks = await axios.get(url);
		const { success, data } = todaysTasks.data;
		if ((success, data)) {
			dispatch({
				type: taskActionTypes.GET_DONE_TASKS,
				payload: data,
			});
			return todaysTasks.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const searchTask = (query) => async (dispatch) => {
	let url;
	if (query) {
		url = `/tasks?query=${query}`;
	} else {
		url = `/tasks`;
	}
	try {
		let tasks = await axios.get(url);
		const { success, data } = tasks.data;
		if ((success, data)) {
			dispatch({
				type: taskActionTypes.GET_ALL_TASKS,
				payload: data,
			});
			return tasks.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getTask = (id) => async (dispatch) => {
	let url = `/tasks/${id}`;
	try {
		let task = await axios.get(url);
		const { success, data } = task.data;
		if (success) {
			dispatch({
				type: taskActionTypes.GET_SINGLE_TASK,
				payload: data,
			});
			return task.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const updateTask = (id, taskDetails) => async (dispatch) => {
	let url = `/tasks/${id}`;
	try {
		let task = await axios.patch(url, taskDetails);
		const { success, data } = task.data;
		if (success) {
			return task.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const changeTaskStatus = (id, status) => async (dispatch) => {
	let url = `/tasks/${id}`;
	try {
		let task = await axios.post(url, status);
		const { success } = task.data;
		if (success) {
			return task.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const attachTaskFile = (id, file) => async (dispatch) => {
	let url = `/tasks/${id}/attachment`;
	try {
		let data = await axios.put(url, file);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const deleteTaskfile = (id, fileId, base, token) => async (dispatch) => {
	let url = `${base}/tasks/${id}/attachment`;

	// Default options are marked with *
	const response = await fetch(url, {
		method: "DELETE",
		cache: "no-cache",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(fileId),
	});
	return response.json();
};

export const addTaskComment = (id, comment) => async (dispatch) => {
	let url = `/comments/task/${id}`;
	try {
		let data = await axios.put(url, comment);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getTaskComments = (id) => async (dispatch) => {
	let url = `/comments/task/${id}`;
	try {
		let response = await axios.get(url);
		const { success, data } = response.data;
		if (success) {
			dispatch({
				type: taskActionTypes.GET_TASKS_COMMENTS,
				payload: data,
			});
			return response.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const deleteTask = (id) => async () => {
	let url = `/tasks/${id}`;
	try {
		let data = await axios.delete(url);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};
