import axios from "axios";
import { usersActionTypes } from "../action-types/users-action.types";

export const getAllUsers = (limit, page) => async (dispatch) => {
	let url = "";
	if (page) {
		url = `/users?limit=${limit}&page=${page}`;
	} else {
		url = `/users?limit=${limit}`;
	}
	try {
		let users = await axios.get(url);
		const { success, data } = users.data;
		if ((success, data)) {
			dispatch({
				type: usersActionTypes.GET_ALL_USERS,
				payload: data,
			});
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const filterUsers = (limit, filter) => async (dispatch) => {
	let url = "";
	if (limit && filter) {
		url = `/users?limit=${limit}&status=${filter}`;
	} else {
		url = `/users?limit=${limit}`;
	}
	try {
		let users = await axios.get(url);
		const { success, data } = users.data;
		if ((success, data)) {
			dispatch({
				type: usersActionTypes.GET_ALL_USERS,
				payload: data,
			});
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

/**
 * @description: upload user profile image
 */
export const uploadImage = (imageData, config) => async () => {
	let url = "/upload";
	try {
		let users = await axios.put(url, imageData, config);
		const { success } = users.data;
		if (success) {
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const createDepartment = (details) => async () => {
	let url = "/departments";
	try {
		let users = await axios.put(url, details);
		const { success } = users.data;
		if (success) {
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getDepartments = (page, limit) => async (dispatch) => {
	let url = "";
	if (page && limit) {
		url = `/departments?page=${page}&limit=${limit}`;
	} else {
		url = "/departments";
	}
	try {
		let users = await axios.get(url);
		const { success, data } = users.data;
		if (success) {
			dispatch({
				type: usersActionTypes.GET_DEPARTMENTS,
				payload: data,
			});
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const filterDepartments = (limit, filter) => async (dispatch) => {
	let url = "";
	if (filter && limit) {
		url = `/departments?limit=${limit}&status=${filter}`;
	} else {
		url = "/departments";
	}
	try {
		let users = await axios.get(url);
		const { success, data } = users.data;
		if (success) {
			dispatch({
				type: usersActionTypes.GET_DEPARTMENTS,
				payload: data,
			});
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const deleteUser = (id) => async () => {
	let url = `/users/${id}`;
	try {
		let users = await axios.delete(url);
		const { success } = users.data;
		if (success) {
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const editUser = (id, details) => async () => {
	let url = `/users/${id}`;
	try {
		let users = await axios.post(url, details);
		const { success } = users.data;
		if (success) {
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const editDepartment = (id, details) => async () => {
	let url = `/departments/${id}`;
	try {
		let response = await axios.patch(url, details);
		const { success } = response.data;
		if (success) {
			return response.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const deactivateDepartment = (id) => async () => {
	let url = `/departments/${id}`;
	try {
		let response = await axios.delete(url);
		const { success } = response.data;
		if (success) {
			return response.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const restoreUser = (id) => async () => {
	let url = `/users/${id}/restore`;
	try {
		let response = await axios.post(url);
		const { success } = response.data;
		if (success) {
			return response.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

