import axios from "axios";
const { activitiesActionTypes } = require("../action-types/activities-action.types");

/**
 * @description: get all activities.
 */
export const getActivities = (page, limit) => async (dispatch) => {
	let url = `/events?page=${page}&limit=${limit}`;
	try {
		const activities = await axios.get(url);
		const { success, data } = activities.data;
		if (success) {
			dispatch({
				type: activitiesActionTypes.GET_ACTIVITIES,
				payload: data,
			});
			return activities.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

/**
 * @description: get all activities.
 */
export const filteredActivity = (id) => async (dispatch) => {
	let url = "";
	if (id) {
		url = `/events?parent=${id}`;
	} else {
		url = `/events`;
	}
	try {
		const activities = await axios.get(url);
		const { success, data } = activities.data;
		if (success) {
			dispatch({
				type: activitiesActionTypes.GET_ACTIVITIES,
				payload: data,
			});
			return activities.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

/**
@description get all announcement.
 */
export const getAnnouncements = (page, limit) => async (dispatch) => {
	let url = `/announcements?page=${page}&limit=${limit}`;
	try {
		const activities = await axios.get(url);
		const { success } = activities.data;
		if (success) {
			dispatch({
				type: activitiesActionTypes.GET_ANNOUNCEMENTS,
				payload: activities.data,
			});
			return activities.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const sendAnnouncement = (details) => async (dispatch) => {
	let url = `/announcements`;
	try {
		const response = await axios.put(url, details);
		const { success } = response.data;
		if (success) {
			return response.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};
