import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Pagination from "rc-pagination";
import { PersonOutline, AvTimerOutlined, GroupOutlined, CreditCardOutlined } from "@material-ui/icons";
import { StatusIcon, OrderIcon } from "../../assets/Svg";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import OrderItem from "./OrderItem";
import {
	filterOrders,
	getAllOrders,
	getOrderStats,
	orderCardFilter,
} from "../../redux/actions/order.actions";
import Pageloader from "../../components/page-loader/Pageloader";
import OverviewStat from "../dashboard/dashboard-tabs/overview/OverView-stat";
import { numberWithCommas } from "../../utils/helper-functions";

const Orders = (props) => {
	const {
		allOrders,
		getAllOrders,
		getOrderStats,
		orderStats,
		filterOrders,
		orderCardFilter,
		role,
	} = props;
	const [currentPage, setCurrentPage] = useState(1);
	const [loadingOrders, setLoadingOrders] = useState(false);

	useEffect(() => {
		onGetAllOrders();
		getOrderStats();
	}, []);

	/**
	 * @description: get all created orders
	 */
	const onGetAllOrders = async () => {
		setLoadingOrders(true);
		let data = await getAllOrders(currentPage, 20);
		if (data?.success) {
			setLoadingOrders(false);
		} else {
			setLoadingOrders(false);
		}
	};

	const onPageChange = async (page) => {
		let data = await getAllOrders(page, 20);
		setCurrentPage(page);
		return data;
	};

	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			{role !== 1 && <DashboardNav history={props.history} />}
			<div className="pt-10 px-3 lg:px-8 mb-4">
				<h1 className="text-md font-medium lg:text-2xl">Orders</h1>
				<p className="text-xs text-gray-700">
					Here is a list of everyone that has ever purchased from Ark:
				</p>
			</div>

			<section className="px-3 lg:px-6 py-4 mx-6 lg:mx-8">
				<div className="grid grid-cols-2 lg:grid-cols-7  gap-2">
					<div
						onClick={() => orderCardFilter("processed")}
						className="pb-3 mt-2 lg:mt-2 mx-1 cursor-pointer">
						<OverviewStat value={orderStats?.processed} title={"Processed orders"} />
					</div>
					<div
						onClick={() => orderCardFilter("active")}
						className="pb-3 mt-2 lg:mt-2 mx-1 cursor-pointer">
						<OverviewStat value={orderStats?.active} title={"Active orders"} />
					</div>
					<div
						onClick={() => orderCardFilter("urgent")}
						className="pb-3 mt-2 lg:mt-2 mx-1 cursor-pointer">
						<OverviewStat value={orderStats?.urgent} title={"urgent orders"} />
					</div>
					<div
						onClick={() => orderCardFilter("ready")}
						className="pb-3 mt-2 lg:mt-2 mx-1 cursor-pointer">
						<OverviewStat value={orderStats?.ready} title={"ready for delivery"} />
					</div>
					<div
						onClick={() => orderCardFilter("overdue")}
						className="pb-3 mt-2 lg:mt-2 mx-1 cursor-pointer">
						<OverviewStat value={orderStats?.overdue} title={"Overdue"} />
					</div>
					<div onClick={() => filterOrders("")} className="pb-3 mt-2 lg:mt-2 mx-1 cursor-pointer">
						<OverviewStat value={allOrders?.count} title={"All orders"} />
					</div>
					{/* <div className="pb-3 mt-2 lg:mt-2 mx-1">
						<OverviewStat
							value={`₦ ${numberWithCommas(leadsStat.totalValue ? leadsStat.totalValue : 0)}`}
							title={"Pipeline value"}
						/>
					</div> */}
					{/* <div className="pb-3 mt-2 lg:mt-2 mx-1">
						<OverviewStat value={leadsStat?.total} title={"leads"} />
					</div> */}
				</div>
			</section>

			<div className="flex justify-end  mb-2">
				<div style={{ width: "120px" }} className="relative pt-1 mx-8 ">
					<select
						style={{ height: "38px" }}
						onChange={(e) => filterOrders(e.target.value)}
						className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none text-xs focus:bg-white focus:border-gray-500"
						id="grid-state">
						<option value="">Filter by</option>
						<option value="">All</option>
						<option value={0}>Order recieved</option>
						<option value={1}>Pre-flight</option>
						<option value={2}>In production</option>
						<option value={3}>Quality control</option>
						<option value={4}>Ready</option>
						<option value={5}>Dispatched</option>
						<option value={6}>On hold</option>
						<option value={7}>Cancelled</option>
						<option value={8}>Awaiting approval</option>
						<option value={9}>Awaiting design</option>
						<option value={10}>Awaiting payment</option>
						<option value={11}>Delivered</option>
					</select>
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
						<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
							<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
						</svg>
					</div>
				</div>
			</div>

			<div className=" rounded-md overflow-scroll bg-white rounded-sm px-3 lg:px-6 py-4 mx-6 lg:mx-8">
				{!loadingOrders ? (
					<table className="table-auto min-w-full px-2 ">
						<thead className="">
							<tr className="min-h-24 text-xs border-b  border-gray-200 text-gray-600 uppercase">
								<th className="px-4 py-2">
									<span className="lg:flex ">
										<OrderIcon />
										<p className="px-2">Order name</p>
									</span>
								</th>
								<th className="px-4 border-r  border-gray-200 border-l py-2">
									<span className="lg:flex ">
										<GroupOutlined />
										<p className="px-2">Customer</p>
									</span>
								</th>
								<th className="px-4 py-2">
									<span className="lg:flex ">
										<CreditCardOutlined />
										<p className="px-2">Payment</p>
									</span>
								</th>
								<th className="px-4 py-2 border-l  border-gray-200">
									<span className="lg:flex ">
										<StatusIcon />
										<p className="px-2">Status</p>
									</span>
								</th>
								<th className="px-4 py-2 border-r  border-gray-200 border-l">
									<span className="lg:flex ">
										<AvTimerOutlined />
										<p className="px-2">Due date</p>
									</span>
								</th>
								<th className="px-4 py-2">
									<span className="lg:flex ">
										<PersonOutline />
										<p className="px-2">Assigned to</p>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{allOrders.orders && allOrders.orders.length > 0 ? (
								allOrders.orders.map((order, i) => {
									return <OrderItem length={allOrders.orders.length} order={order} index={i} key={i} />;
								})
							) : (
								<tr>
									<td>No Order found</td>
								</tr>
							)}
						</tbody>
					</table>
				) : (
					<Pageloader />
				)}
				<div className="mt-6 flex px-6 justify-end ">
					{!loadingOrders && (
						<Pagination
							onChange={(page) => onPageChange(page)}
							defaultCurrent={currentPage}
							total={allOrders.count ? allOrders.count : 0}
							pageSize={20}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	allOrders: state.orders.allOrders,
	orderStats: state.orders.orderStats,
});

export default connect(mapStateToProps, { getAllOrders, getOrderStats, filterOrders, orderCardFilter })(
	Orders
);
