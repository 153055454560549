import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Navbar from "../containers/navbar/Navbar";
import { verifyToken } from "../redux/actions/auth.actions";
import { baseURL } from "../utils/base-url";
import axios from "axios";

axios.defaults.baseURL = baseURL;
let credentials = JSON.parse(sessionStorage.getItem("_ark_task"));
if (credentials !== null && credentials.token) {
	axios.defaults.headers.common["Authorization"] = `Bearer ${credentials.token}`;
}

const PrivateRoute = (props) => {
	const { component: Component, auth, verifyToken, ...rest } = props;
	const [role, setRole] = useState("");

	useEffect(() => {
		verifyToken();
		let role = JSON.parse(sessionStorage.getItem("_ark_task"))?.user?.role;
		if (role) {
			setRole(role);
		}
	}, []);

	return (
		<>
			<Route
				{...rest}
				render={(props) => {
					if (window.sessionStorage.getItem("_ark_task") !== null) {
						return (
							<>
								<Navbar {...props} role={role} />
								<Component {...props} role={role} />
							</>
						);
					} else {
						window.location.replace("/");
						{
							/* return <Redirect to="/" />; */
						}
					}
				}}
			/>
		</>
	);
};

// const mapStateToProps = (state) => ({
// 	tokenVerified: state.auth.tokenVerified,
// });

export default connect(null, { verifyToken })(PrivateRoute);
