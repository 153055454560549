import React from "react";
import "./custom-checkbox.css";

const CustomCheckbox = ({ label, checked, oncheck }) => {
	return (
		<div className="flex mr-4 mt-3">
			<div onClick={() => oncheck(label === "Manual input" ? 0 : parseInt(label))} className="round">
				<input onChange={() => null} type="checkbox" checked={checked} />
				<label htmlFor="checkbox"></label>
			</div>
			<span className="mx-8 text-sm font-bold">{label}</span>
		</div>
	);
};

export default CustomCheckbox;
