import React, { useEffect, useState } from "react";
import OverviewStat from "./OverView-stat";
import OrdersCard from "./OrdersCard";
import TaskCard from "./TaskCard";
import RecentActivities from "./Recent-activities";
import AnnouncementCard from "./AnnouncementCard";
import { getGreeting } from "../../../../utils/getGreeting";
import { connect } from "react-redux";
import { getAllOrders, getOrderStats } from "../../../../redux/actions/order.actions";
import { getRecentTasks } from "../../../../redux/actions/task.actions";
import Pageloader from "../../../../components/page-loader/Pageloader";
import { getActivities } from "../../../../redux/actions/activities.actions";
import { getLeadsStats } from "../../../../redux/actions/leads.actions";
import { numberWithCommas } from "../../../../utils/helper-functions";

const Overview = (props) => {
	const {
		getAllOrders,
		getRecentTasks,
		orders,
		tasks,
		orderStats,
		getActivities,
		allActivities,
		getOrderStats,
		getLeadsStats,
		leadsStat,
		recentTasks,
		role,
	} = props;
	const [user, setUser] = useState({});
	const [greeting, setGreeting] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		onGetUser();
		setGreeting(getGreeting);
		onLoadOverviewData();
		getActivities(1, 20);
		getOrderStats();
		getLeadsStats();
		return;
	}, []);

	const onGetUser = () => {
		let storage = JSON.parse(sessionStorage.getItem("_ark_task"));
		if (storage) {
			setUser(storage.user);
		}
	};

	const onLoadOverviewData = async () => {
		setLoading(true);
		getRecentTasks();
		let orderData = await getAllOrders(1, 3);
		if (orderData?.success) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	return (
		<section className="px-3 lg:px-12 pt-6">
			<h1 className="text-md font-medium lg:text-2xl">
				{greeting}, {user.firstName ? user.firstName : ""}
			</h1>
			<p className="text-xs text-gray-700">Here is a breakdown of how the day looks like:</p>
			{!loading ? (
				<>
					<section className="mt-3 lg:mt-8 ">
						<div className="grid grid-cols-2 lg:grid-cols-7  gap-2">
							<div className="pb-3 mt-2 lg:mt-2 mx-1">
								<OverviewStat value={orderStats?.processed} title={"Processed orders"} />
							</div>
							<div className="pb-3 mt-2 lg:mt-2 mx-1">
								<OverviewStat value={orderStats?.active} title={"Active orders"} />
							</div>
							<div className="pb-3 mt-2 lg:mt-2 mx-1">
								<OverviewStat value={orderStats?.urgent} title={"urgent orders"} />
							</div>
							<div className="pb-3 mt-2 lg:mt-2 mx-1">
								<OverviewStat value={orderStats?.ready} title={"ready for delivery"} />
							</div>
							<div className="pb-3 mt-2 lg:mt-2 mx-1">
								<OverviewStat value={orderStats?.overdue} title={"Overdue"} />
							</div>
							{role !== 1 && role !== 2 ? (
								<div className="pb-3 mt-2 lg:mt-2 mx-1">
									<OverviewStat
										value={`₦ ${numberWithCommas(leadsStat.totalValue ? leadsStat.totalValue : 0)}`}
										title={"Pipeline value"}
									/>
								</div>
							) : (
								""
							)}
							{role !== 1 && role !== 2 ? (
								<div className="pb-3 mt-2 lg:mt-2 mx-1">
									<OverviewStat value={leadsStat?.total} title={"leads"} />
								</div>
							) : (
								""
							)}
						</div>
					</section>
					<section className="grid grid-cols-1 lg:grid-cols-2  gap-2">
						<div className="mt-3 lg:mt-6">
							<OrdersCard orders={orders} />
						</div>
						<div className="mt-3 lg:mt-6">
							<TaskCard tasks={recentTasks} />
						</div>
					</section>
					<div className="grid grid-cols-3  gap-2">
						<RecentActivities allActivities={allActivities} />
						<div className="lg:col-span-1 col-span-3 ">
							<AnnouncementCard role={role} />
						</div>
					</div>
				</>
			) : (
				<div className="mt-8">
					<Pageloader />
				</div>
			)}
		</section>
	);
};

const mapStateToProps = (state) => ({
	tasks: state.tasks.allTasks,
	orders: state.orders.allOrders,
	orderStats: state.orders.orderStats,
	allActivities: state.activities.activities,
	leadsStat: state.leads.leadsStat,
	recentTasks: state.tasks.recentTasks,
});

export default connect(mapStateToProps, {
	getAllOrders,
	getRecentTasks,
	getLeadsStats,
	getActivities,
	getOrderStats,
})(Overview);
