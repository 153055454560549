import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination";
import { StatusIcon, BranchIcon } from "../../../../assets/Svg";
import { AvTimerOutlined, LocalMall, PersonOutline, FlashOnOutlined } from "@material-ui/icons";
import JobLogItem from "./Job-log-item";
import { connect } from "react-redux";
import { filterJobLogs, getAlljobs } from "../../../../redux/actions/joblogs.actions";
import Pageloader from "../../../../components/page-loader/Pageloader";

const JobLogs = (props) => {
	const { getAlljobs, allJobs, kanban, toggleKanbanView, filterJobLogs } = props;
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		onGetAllJobs();
		return;
	}, []);

	const onGetAllJobs = async () => {
		setLoading(true);
		let data = await getAlljobs(currentPage, 20);
		if (data?.success) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const onPageChange = async (page) => {
		let data = await getAlljobs(page, 20);
		setCurrentPage(page);
		return data;
	};

	return (
		<div>
			<div className="mt-10 px-3 lg:px-8">
				<h1 className="text-md font-medium lg:text-2xl">Job Log</h1>
				<p className="text-xs text-gray-700">Here is a list of everyone that has purchased from Ark:</p>
			</div>
			{!kanban && (
				<div className="flex justify-end">
					<span className="py-4">
						<button
							onClick={() => toggleKanbanView()}
							style={{ backgroundColor: "#FEF2EC", color: "#F26925", border: "1px dashed #F26925" }}
							className="py-1 text-xs px-4 mx-3 capitalize rounded inline-flex items-center">
							<span className="px-2">Switch to Board View</span>
						</button>
					</span>

					<div className="w-36 h-12 px-3 mb-6 md:mb-0">
						<div className="relative pt-3">
							<select
								style={{ height: "38px" }}
								onChange={(e) => filterJobLogs(e.target.value)}
								className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none text-xs focus:bg-white focus:border-gray-500"
								id="grid-state">
								<option value="">Filter by</option>
								<option value="">All</option>
								<option value={0}>Order recieved</option>
								<option value={1}>Pre-flight</option>
								<option value={2}>In production</option>
								<option value={3}>Quality control</option>
								<option value={4}>Ready</option>
								<option value={5}>Dispatched</option>
								<option value={6}>On hold</option>
								<option value={7}>Cancelled</option>
								<option value={8}>Awaiting approval</option>
								<option value={9}>Awaiting design</option>
								<option value={10}>Awaiting payment</option>
								<option value={11}>Delivered</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20">
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="min-w-full overflow-scroll bg-white rounded-sm px-3 lg:px-6 py-4">
				{!loading ? (
					<table className="table-auto min-w-full px-2 ">
						<thead className="border-b  border-gray-200">
							<tr className="min-h-24 text-xs text-gray-600 uppercase">
								<th className="px-4 py-2">
									<span className="lg:flex justify-center">
										<BranchIcon />
										<p className="px-2">Job ID</p>
									</span>
								</th>
								<th className="px-4 border-r  border-gray-200 border-l py-2">
									<span className="lg:flex justify-center">
										<BranchIcon />
										<p className="px-2">Order name</p>
									</span>
								</th>
								<th className="px-4 py-2">
									<span className="lg:flex justify-center">
										<LocalMall />
										<p className="px-2">product name</p>
									</span>
								</th>
								<th className="px-4 py-2 border-r  border-gray-200  border-l">
									<span className="lg:flex justify-center">
										<StatusIcon />
										<p className="px-2"> status</p>
									</span>
								</th>
								<th className="px-4 py-2  border-gray-200 border-r">
									<span className="lg:flex justify-center">
										<AvTimerOutlined />
										<p className="px-2">Due date</p>
									</span>
								</th>
								<th className="px-4 py-2  border-gray-200 border-r">
									<span className="lg:flex justify-center">
										<PersonOutline />
										<p className="px-2">Assigned to</p>
									</span>
								</th>
								<th className="px-4 py-2">
									<span className="lg:flex justify-center">
										<FlashOnOutlined />
										<p className="px-2">Action</p>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{allJobs.jobs && allJobs.jobs.length > 0 ? (
								allJobs.jobs.map((job, i) => {
									return <JobLogItem job={job} key={i} />;
								})
							) : (
								<p className="text-gray-700">No job found</p>
							)}
						</tbody>
					</table>
				) : (
					<Pageloader />
				)}
			</div>
			<div className="mt-6 flex px-6 justify-end ">
				<Pagination
					onChange={(page) => onPageChange(page)}
					defaultCurrent={currentPage}
					total={allJobs.count ? allJobs.count : 0}
					pageSize={20}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	allJobs: state.jobLogs.allJobs,
});

export default connect(mapStateToProps, { getAlljobs, filterJobLogs })(JobLogs);
