import React from "react";
import { SearchOutlined } from "@material-ui/icons";
import SelectPointer from "../commons/SelectPointer";
import { connect } from "react-redux";
import { searchTask } from "../../redux/actions/task.actions";
import { searchAlljobs } from "../../redux/actions/joblogs.actions";

const DashboardSearch = (props) => {
	const { kanban, toggleKanbanView, searchTask, searchAlljobs } = props;

	const onSearch = (query) => {
		if (window.location.pathname.includes("mytasks")) {
			searchTask(query);
		} else if (window.location.pathname.includes("joblogs")) {
			searchAlljobs(query);
		}
	};

	return (
		<div className="container content-center dashboard-nav justify-between min-w-full px-12 flex py-6 border-t-2 border-gray-200 ">
			<div style={{ width: "400px" }} className="flex">
				<SearchOutlined />
				<input
					style={{ height: "35px", paddingBottom: "5px" }}
					className=" border-none  w-full  px-4 text-gray-700   focus:bg-white focus:border-orange-500"
					type="text"
					onChange={(e) => onSearch(e.target.value)}
					placeholder="Search..."
				/>
			</div>

			{kanban && (
				<div className=" lg:flex mb-2">
					<button
						style={{
							backgroundColor: "#FEF2EC",
							color: "#F26925",
							border: "1px dashed #F26925",
							height: "38px",
							width: "250px",
						}}
						onClick={() => toggleKanbanView()}
						className=" px-4 rounded mb-3 text-xs">
						{kanban ? "Switch to list view" : "Switch to board view"}
					</button>{" "}
					{!kanban && (
						<div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 text-xs">
							<div className="relative">
								<select
									style={{ height: "38px" }}
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-state">
									<option value="">Filter by</option>
									<option>Completed</option>
									<option>In production</option>
									<option>Ready</option>
									<option>Pre-flight</option>
								</select>
								<SelectPointer />
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default connect(null, { searchTask, searchAlljobs })(DashboardSearch);
