import React from "react";
import StatusButton from "../../components/commons/StatusButton";
import DateButton from "../../components/commons/Date-button";
import StackedImages from "../../components/commons/Stacked-images";
import { FiberManualRecord } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { definedPaymentColor, definedPaymentStatus } from "../../utils/definedValues";

const OrderItem = (props) => {
	const { order, length, index, forCustomer } = props;
	return (
		<tr className="border-b border-gray-200">
			<td className="  border-l-0 border-gray-200 px-4 py-2 text-gray-900 text-xs">
				<div className="lg:flex">
					<span className="mb-2">
						{order?.source === "ARK" && (
							<img
								className="w-8 h-8 mx-2 rounded-full mr-4"
								src={"https://res.cloudinary.com/w3bh4ck/image/upload/v1600259673/ark/ark-logo-dark.png"}
								alt={order.customer.name}
							/>
						)}
						{order?.source === "247PRINTS" && (
							<img
								className="w-8 h-8 mx-2 rounded-full mr-4"
								src={"/uploads/247prints-logo.png"}
								alt={order.customer.name}
							/>
						)}
						{order?.source === "WIICREATE" && (
							<img
								className="w-8 h-8 mx-2 rounded-full mr-4"
								src="/uploads/wii_icon.png"
								alt={order.customer.name}
							/>
						)}
						{order?.source === "" ? (
							<img
								className="w-8 h-8 mx-2 rounded-full mr-4"
								src={"https://res.cloudinary.com/w3bh4ck/image/upload/v1600259673/ark/ark-logo-dark.png"}
								alt={order.customer.name}
							/>
						) : (
							""
						)}
						{order?.source === "PROMOAFRIQUE" && (
							<img
								className="w-8 h-8 mx-2 rounded-full mr-4"
								src="/uploads/promo_afrique.png"
								alt={order.customer.name}
							/>
						)}
					</span>
					<Link to={`/orders/order/${order._id}`}>
						<span className="py-2 font-bold hover:text-orange-400">{order.title}</span>
					</Link>
				</div>
			</td>
			{!forCustomer && (
				<td className=" border-l border-r  border-gray-200 px-4 py-2 text-xs font-bold">
					{order.customer.name}
				</td>
			)}
			{!forCustomer && (
				<td className=" border-r border-gray-200 pt-6 py-2 text-xs font-bold flex">
					<span style={{ color: definedPaymentColor[order.paymentStatus] }}>
						{" "}
						<FiberManualRecord />{" "}
					</span>{" "}
					<span className="pt-1">{definedPaymentStatus[order.paymentStatus]}</span>
				</td>
			)}
			<td className=" border-l border-r border-gray-200 px-4 py-2">
				<StatusButton status={order.status} />
			</td>
			<td className=" border-r border-gray-200  px-4 py-2">
				<DateButton order={order} />
			</td>
			<td className=" border-r-0 w-14 px-4 py-2">
				<StackedImages assignees={order.assignees} />
			</td>
		</tr>
	);
};

export default OrderItem;
