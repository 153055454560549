import { authActionTypes } from "../action-types/auth-action.types";

const initialState = {
	user: {},
	isLoggingIn: false,
	authenticated: false,
	tokenVerified: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case authActionTypes.LOGIN:
			return { ...state, tokenVerified: true };
		case authActionTypes.VERIFY_TOKEN:
			return { ...state, tokenVerified: action.payload };
		default:
			return state;
	}
};
