import React, { useState } from "react";

const SingleUserSelect = (props) => {
	const { users, assignee, setAssignee } = props;
	const [open, setOpen] = useState(false);
	return (
		<div className="w-full md:w-2/3 px-3">
			<label
				className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
				htmlFor="grid-assignee">
				Assignee:
			</label>
			<button
				onClick={() => setOpen(!open)}
				className="appearance-none block w-full bg-gray-100 text-gray-700 border overflow-hidden border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
				type="button">
				{assignee.hasOwnProperty("_id") ? (
					<span className="flex">
						<img
							className="rounded-full w-6 h-6 ml--2"
							src={
								assignee.profilePicture && assignee.profilePicture.length > 0
									? assignee.profilePicture
									: "/uploads/profile-placeholder.jpg"
							}
							alt="Sunset"
						/>
						<span className="mx-4 pt-1">{`${assignee ? assignee.firstName : ""} ${
							assignee ? assignee.lastName : ""
						} (${assignee ? assignee.email : ""})`}</span>
					</span>
				) : (
					<span>Select assignee</span>
				)}
			</button>
			<div
				className={
					open
						? "mt-2 py-2 px-2  bg-white h-64 rounded-lg shadow-xl w-full overflow-scroll"
						: "mt-2 py-2 px-2  bg-white h-64 rounded-lg shadow-xl hidden w-full overflow-scroll "
				}>
				{users.users && users.users.length > 0
					? users.users.map((user, i) => {
							return (
								<a
									key={i}
									onClick={() => {
										setOpen(!open);
										setAssignee(user);
									}}
									className=" px-4 py-2 text-gray-800 hover:text-orange-400">
									<span className="flex cursor-pointer text-xs">
										<img
											className="rounded-full w-6 h-6 ml--2"
											src={
												user.profilePicture && user.profilePicture.length > 0
													? user.profilePicture
													: "/uploads/profile-placeholder.jpg"
											}
											alt="Sunset"
										/>{" "}
										<span className="mx-4 capitalize">{`${user.firstName} ${user.lastName} (${user.email})`}</span>
									</span>
								</a>
							);
					  })
					: "No user found at the moment"}
			</div>
		</div>
	);
};

export default SingleUserSelect;
