import { taskActionTypes } from "../action-types/task-actions.types";

const initialState = {
	allTasks: {},
	overdueTasks: {},
	todayTasks: {},
	doneTasks: {},
	recentTasks: {},
	task: {},
	comments: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case taskActionTypes.GET_ALL_TASKS:
			return { ...state, allTasks: action.payload };
		case taskActionTypes.GET_OVERDUE_TASK:
			return { ...state, overdueTasks: action.payload };
		case taskActionTypes.GET_DONE_TASKS:
			return { ...state, doneTasks: action.payload };
		case taskActionTypes.GET_TODAY_TASKS:
			return { ...state, todayTasks: action.payload };
		case taskActionTypes.GET_RECENT_TASK:
			return { ...state, recentTasks: action.payload };
		case taskActionTypes.GET_SINGLE_TASK:
			return { ...state, task: action.payload };
		case taskActionTypes.GET_TASKS_COMMENTS:
			return { ...state, comments: action.payload };
		default:
			return state;
	}
};
