import React from "react";
import { QueryBuilderOutlined } from "@material-ui/icons";
import { formatDistance } from "date-fns";
import { getNameInitials } from "../../../../utils/helper-functions";

const ActivityItem = ({ event }) => {
	return (
		<>
			<div className="mt-6 flex justify-between py-2">
				<div className="order-title flex">
					{event.user.profilePicture.length > 0 ? (
						<img
							className="rounded-full w-8 h-8 mx-2"
							src={
								event.user.profilePicture.length > 0
									? event.user.profilePicture
									: "/uploads/profile-placeholder.jpg"
							}
							alt="Sunset in the mountains"
						/>
					) : (
						<div
							className="w-8 h-8 mx-2 rounded-full mr-4"
							style={{
								backgroundColor: "#f0efec",
							}}>
							<div className="flex justify-center uppercase">
								<p style={{ marginTop: "7px", fontSize: "12px" }}>
									{getNameInitials(
										`${event.user ? event.user.firstName : "Unknown"} ${
											event.user ? event.user.lastName : "User"
										}`
									)}
								</p>
							</div>
						</div>
					)}
					<p className="text-xs font-bold mx-3 my-auto text-gray-600">
						<span dangerouslySetInnerHTML={{ __html: event.description }} />
					</p>
				</div>
				<div className="flex text-gray-600">
					<QueryBuilderOutlined />
					<span className="mx-2"></span>
					{formatDistance(new Date(event.createdAt), new Date(), {
						addSuffix: true,
					})}
				</div>
			</div>
			<hr className="border-gray-300 mt-2" />
		</>
	);
};

export default ActivityItem;
