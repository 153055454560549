import React from "react";
import { Link } from "react-router-dom";
import ActivityItem from "./Activity-item";

const RecentActivities = ({ allActivities }) => {
	return (
		<div className="mt-6  p-10 bg-white shadow-sm col-span-3 lg:col-span-2 rounded-sm">
			<div className="flex justify-between">
				<span className="text-xs uppercase text-gray-600">Recent activities</span>
				<span style={{ color: "#F26925" }} className="text-xs cursor-pointer">
					<Link to="/dashboard/activities">View all</Link>
				</span>
			</div>
			{allActivities && allActivities.events ? (
				allActivities.events.map((event, i) => {
					if (i < 4) {
						return <ActivityItem key={event._id} event={event} />;
					}
				})
			) : (
				<p>No activity at the moment</p>
			)}
		</div>
	);
};

export default RecentActivities;
