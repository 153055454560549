import React, { useState } from "react";
import { DeleteForeverOutlined } from "@material-ui/icons";
import Modal from "react-modal";
import { CloseIcon, EditIcon } from "../../assets/Svg";
import { roleValues } from "../../utils/definedValues";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { deleteUser, getAllUsers, restoreUser, filterUsers } from "../../redux/actions/users.actions";
import { useToasts } from "react-toast-notifications";
import EditUser from "../profile-page/EditUser";
import { format } from "date-fns";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";
Modal.setAppElement("#root");
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		overflow: "auto",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "500px",
	},
};

const customEditStyle = {
	content: {
		top: "50%",
		left: "50%",
		overflow: "auto",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "90%",
	},
};
const UserListItem = ({ user, deleteUser, getAllUsers, activeView, restoreUser, filterUsers }) => {
	const [openDelete, setOpenDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [restoring, setRestoring] = useState('')
	const { addToast } = useToasts();

	const onDeleteUser = async () => {
		setDeleting(true);
		let res = await deleteUser(user?._id);
		if (res?.success) {
			setOpenDelete(false);
			addToast("User archived successfully", { appearance: "success" });
			getAllUsers(20);
		} else {
			addToast(res?.data?.message, { appearance: "error" });
		}

		setDeleting(false);
	};


	const onRestoreUser = async (id) => {
		setRestoring(id)
		let res = await restoreUser(id)
		if(res.success){
			addToast("User restored successfully", { appearance: "success" });
			filterUsers(20, 'archived')
		}else{
			addToast(res?.data?.message, { appearance: "error" });
		}
		setRestoring('')
	}

	return (
		<tr className="border-t  border-gray-200 h-16">
			<td className="  border-l-0 px-4 py-2 text-gray-900 text-sm">
				<div className="">
					<span className="py-2 hover:text-orange-400">
						{user?.firstName} {user?.lastName}
					</span>
				</div>
			</td>
			<td className=" border-l  border-gray-200 border-r px-4 py-2 text-sm  capitalize">
				{user.department ? user?.department?.name : "N/A"}
			</td>
			<td className=" border-l  border-gray-200 border-r px-4 py-2 text-sm "> {user?.email}</td>
			<td className=" border-l  border-gray-200 border-r px-4 py-2 text-sm  capitalize text-center">
				{user?.phone}
			</td>
			<td className=" border-l  border-gray-200 px-4 py-2 text-sm  border-r text-center">
				{roleValues[user?.role]}
			</td>
			<td className=" border-l  border-gray-200 px-4 py-2 text-sm  border-r text-center">
				{user?.lastLogin?.length > 0 ? format(new Date(user.lastLogin), "do MMMM, yyyy") : "N/A"}
			</td>
			<td className=" border-l  border-gray-200 px-4 py-2 text-sm   text-center">
				{activeView === true ? (
					<div className="flex justify-between">
						<div className="cursor-pointer" onClick={() => setOpenEdit(true)}>
							<EditIcon />
						</div>
						<div onClick={() => setOpenDelete(true)} className="text-red-500 cursor-pointer">
							<DeleteForeverOutlined />
						</div>
					</div>
				) : (
					<p onClick={() => onRestoreUser(user._id)} className="text-xs hover:text-orange-400 cursor-pointer">{restoring === user._id ? "Restoring..." : 'Re-activate'}</p>
				)}
			</td>
			<Modal
				isOpen={openDelete}
				onRequestClose={() => setOpenDelete(!openDelete)}
				contentLabel="Modal"
				style={customStyles}>
				<div className="px-4 mb-4">
					<div className="flex justify-end">
						<button onClick={() => setOpenDelete(!openDelete)}>
							<CloseIcon modalOpen={setOpenDelete} />
						</button>
					</div>
					<div className="pt-12">
						<p>
							Are You sure you want to <span className="text-red-500">archive</span> the user{" "}
							<span className="bold">
								{" "}
								{user?.firstName} {user?.lastName}{" "}
							</span>
						</p>
					</div>
					<div className="flex justify-between pt-12">
						<Button onClick={() => setOpenDelete(false)} variant="outlined">
							Cancel
						</Button>

						<Button onClick={() => onDeleteUser()} color="#f56565">
							<span className="text-red-500">{!deleting ? "Yes, delete" : "Deleting..."}</span>
						</Button>
					</div>
				</div>
			</Modal>

			<Modal
				isOpen={openEdit}
				onRequestClose={() => setOpenEdit(!openEdit)}
				contentLabel="Modal"
				style={customEditStyle}>
				<div className="px-4 mb-4">
					<div className="flex justify-end">
						<button onClick={() => setOpenEdit(!openEdit)}>
							<CloseIcon modalOpen={setOpenEdit} />
						</button>
					</div>
					<EditUser user={user} modalOpen={setOpenEdit} />
				</div>
			</Modal>
		</tr>
	);
};

export default connect(null, { deleteUser, getAllUsers, restoreUser, filterUsers })(UserListItem);
