import React, { useEffect, useState } from "react";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import OverviewStat from "../dashboard/dashboard-tabs/overview/OverView-stat";
import LeadsTable from "./LeadsTable";
import { getLeadsStats, getAllLeads, filterLeads } from "../../redux/actions/leads.actions";
import Pageloader from "../../components/page-loader/Pageloader";
import LeadsKanban from "./leads-kanban/LeadsKanban";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import { numberWithCommas } from "../../utils/helper-functions";

const Leads = (props) => {
	const { getLeadsStats, leadsStat, getAllLeads, allLeads, filterLeads, role } = props;
	const [loading, setLoading] = useState(false);
	const [kanban, setKanban] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		onGetLeadsStat();
		getAllLeads(currentPage, 20);
	}, []);

	const onGetLeadsStat = async () => {
		setLoading(true);
		let stat = await getLeadsStats();
		if (stat && stat.success) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const onPageChange = async (page) => {
		let allLeads = await getAllLeads(page, 20);
		setCurrentPage(page);
		return allLeads;
	};

	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			{role !== 1 && (
				<DashboardNav taskKanban={kanban} setTaskKanban={setKanban} history={props.history} />
			)}
			{/* <LeadSearch kanban={kanban} setKanban={setKanban} /> */}
			<hr className="w-full border-gray-200" />

			{!kanban && (
				<>
					<div className="mt-10 px-3 lg:px-8">
						<h1 className="text-md font-medium lg:text-2xl">Leads</h1>
						<p className="text-xs text-gray-700">Here is a breakdown of all the leads:</p>
					</div>
					{!loading ? (
						<>
							{role !== 1 && (
								<section className="mt-3 lg:mt-8 px-3 lg:px-8">
									<div class="grid grid-cols-2 lg:grid-cols-6  gap-2">
										<div className="pb-3 mt-2 sh lg:mt-2 mx-1">
											<OverviewStat
												value={leadsStat.total ? leadsStat.total : 0}
												title={"Total opportunities"}
											/>
										</div>
										<div className="pb-3 mt-2  lg:mt-2 mx-1">
											<OverviewStat
												value={leadsStat.pending ? leadsStat.pending : 0}
												title={"Pending leads"}
											/>
										</div>
										<div className="pb-3 mt-2  lg:mt-2 mx-1">
											<OverviewStat
												value={`₦ ${leadsStat.totalValue ? numberWithCommas(leadsStat.totalValue) : 0}`}
												title={"total values"}
											/>
										</div>
										<div className="pb-3 mt-2  lg:mt-2 mx-1">
											<OverviewStat
												value={`₦ ${
													leadsStat.expectedValue ? numberWithCommas(leadsStat.expectedValue) : 0
												}`}
												title={"Expected values"}
											/>
										</div>
									</div>
								</section>
							)}

							<div className="mt-8 px-2 ">
								<div className=" lg:flex lg:justify-end mb-2">
									{role !== 1 && (
										<button
											style={{
												backgroundColor: "#FEF2EC",
												color: "#F26925",
												border: "1px dashed #F26925",
												height: "38px",
												width: "180px",
											}}
											onClick={() => setKanban(!kanban)}
											className=" px-4 rounded mb-3 text-xs">
											{kanban ? "Switch to list view" : "Switch to board view"}
										</button>
									)}{" "}
									{/* <button
										onClick={() => setKanban(!kanban)}
										style={{
											backgroundColor: "#FEF6F6",
											color: "F26925",
											border: "1px dashed #F26925",
											height: "40px",
											width: "250px",
										}}
										className=" px-4 rounded mb-3 outline-none text-xs">
										{!kanban ? "Switch to board view" : "Swtch to list view"}
									</button>{" "} */}
									{!kanban && (
										<div className="w-full lg:w-1/6  px-3 mb-6 md:mb-0">
											<div className="relative">
												<select
													onChange={(e) => filterLeads(e.target.value)}
													className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
													id="grid-state">
													<option value="">Filter by</option>
													<option value={0}>Prospect</option>
													<option value={1}>Contact made</option>
													<option value={2}>Meeting booked</option>
													<option value={3}>Proposal</option>
													<option value={4}>Awaiting feedback</option>
													<option value={5}>Negotiating</option>
													<option value={6}>Won deals</option>
													<option value={7}>Lost deals</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							{role !== 1 && <LeadsTable allLeads={allLeads} />}
						</>
					) : (
						<Pageloader />
					)}
				</>
			)}
			{kanban && <LeadsKanban />}
			{!kanban && (
				<div className="mt-6 flex px-6 justify-end ">
					{!loading && (
						<Pagination
							onChange={(page) => onPageChange(page)}
							defaultCurrent={currentPage}
							total={allLeads.count ? allLeads.count : 0}
							pageSize={20}
						/>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	leadsStat: state.leads.leadsStat,
	allLeads: state.leads.allLeads,
});

export default connect(mapStateToProps, { getLeadsStats, filterLeads, getAllLeads })(Leads);
