import React from "react";
import { definedOrderStatus, ordersStatusColor } from "../../utils/definedValues";

const StatusButton = ({ status }) => {
	const style = ordersStatusColor[status];
	return (
		<span className="text-xs" style={style.text}>
			<button
				style={style.button}
				className="py-2 px-4 rounded button-outline m-3 text-xs capitalize cursor-not-allowed">
				{definedOrderStatus[status]}
			</button>{" "}
		</span>
	);
};

export default StatusButton;
