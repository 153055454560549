import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useToasts } from "react-toast-notifications";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ExpandMore } from "@material-ui/icons";
import { definedOrderStatus, ordersStatusColor } from "../../../../utils/definedValues";
import { connect } from "react-redux";

import { getAlljobs, updateSingleItem } from "../../../../redux/actions/joblogs.actions";

const StatusSelect = (props) => {
	const { job, updateSingleItem, getAlljobs } = props;
	const { addToast } = useToasts();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [status, setStatus] = useState(0);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setStatus(parseInt(job?.items?.status));
	}, []);

	const style = ordersStatusColor[parseInt(job.items.status)];

	const onChangeStatus = async (state) => {
		setStatus(state);
		let statusDetail = {
			status: state,
		};
		let data = await updateSingleItem(job._id, job.items._id, statusDetail);
		if (data.success) {
			addToast("Job status updated", { appearance: "success" });
			getAlljobs();
		} else {
			addToast(data.message, { appearance: "error" });
		}
	};

	return (
		<div>
			<Button
				aria-controls="simple-menu"
				className="outline-none"
				style={{ ...style.button, height: "50px" }}
				aria-haspopup="true"
				onClick={handleClick}>
				<span className="text-xs flex" style={style.text}>
					<button
						type="button"
						className="py-2 px-4 rounded button-outline m-1 text-xs capitalize outline-none">
						{definedOrderStatus[parseInt(job.items.status)]}
					</button>{" "}
					<span className="mt-3">
						<ExpandMore />
					</span>
				</span>
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(0);
					}}>
					Order recieved
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(1);
					}}>
					Pre-flight
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(2);
					}}>
					In production
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(3);
					}}>
					Quality control
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(4);
					}}>
					Ready
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(5);
					}}>
					Dispatched
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(6);
					}}>
					On-hold
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(7);
					}}>
					Cancelled
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(8);
					}}>
					Awaiting approval
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(9);
					}}>
					Awaiting design
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(10);
					}}>
					Awaiting payment
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(11);
					}}>
					Delivered
				</MenuItem>
			</Menu>
		</div>
	);
};

export default connect(null, {
	updateSingleItem,
	getAlljobs,
})(StatusSelect);
