import { CalendarToday, DeleteForeverOutlined, PeopleAltOutlined } from "@material-ui/icons";
import Modal from "react-modal";
import { format } from "date-fns";
import { useToasts } from "react-toast-notifications";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CloseIcon, EditIcon, StatusIcon } from "../../assets/Svg";
import Pageloader from "../../components/page-loader/Pageloader";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import { editDepartment, getDepartments, deactivateDepartment, filterDepartments } from "../../redux/actions/users.actions";
import { departmentStatus } from "../../utils/definedValues";
import { Button, Tooltip } from "@material-ui/core";
import Pagination from "rc-pagination";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.1)";
Modal.setAppElement("#root");
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		overflow: "scroll",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
	},
};

const Departments = ({
	history,
	getDepartments,
	allDepartments,
	editDepartment,
	deactivateDepartment,
	filterDepartments
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [activeDepartment, setActiveDepartment] = useState({});
	const [openDepartment, setOpendepartment] = useState(false);
	const [departmentName, setDepartmentName] = useState("");
	const [openDelete, setOpenDelete] = useState(false);
	const { addToast } = useToasts();

	useEffect(() => {
		ongetDepartments();
	}, []);

	const ongetDepartments = async () => {
		setLoading(true);
		let res = await getDepartments(currentPage, 20);
		if (res.success) {
			setLoading(false);
		}
		setLoading(false);
	};

	const onPageChange = async (page) => {
		getDepartments(page, 20);
		setCurrentPage(page);
	};

	const onEditDepartment = async (e) => {
		e.preventDefault();
		setSaving(true);
		let details = { name: departmentName };
		let res = await editDepartment(activeDepartment._id, details);
		if (res.success) {
			addToast("Department edited", { appearance: "success" });
		} else {
			addToast(res?.data?.message, { appearance: "error" });
		}
		setSaving(false);
		getDepartments(currentPage, 20);
		setOpendepartment(false);
	};

	const onDeactivateDepartment = async () => {
		setSaving(true);
		let res = await deactivateDepartment(activeDepartment._id);
		if (res?.success) {
			addToast("Department Deactivated", { appearance: "success" });
		} else {
			addToast(res?.data?.message, { appearance: "error" });
		}
		setSaving(false);
		getDepartments(currentPage, 20);
		setOpenDelete(false);
	};

	return (
		<div>
			<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
				<DashboardNav history={history} />
				<div className="mt-10 px-3 lg:px-8 mb-4 md:flex md:justify-between">
					<div>
						<h1 className="text-md font-medium lg:text-2xl">Departments</h1>
						<p className="text-xs text-gray-700">Here is a list of all departments:</p>
					</div>
				</div>
				{/* <div className="flex justify-end  mb-2">
					<div style={{ width: "120px" }} className="relative pt-1 mx-8 ">
						<select
							style={{ height: "38px" }}
							className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none text-xs focus:bg-white focus:border-gray-500"
							id="grid-state">
							<option value="">Filter by</option>
							<option value={0}>Active</option>
							<option value={1}>Archived</option>
						</select>
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
							<svg
								className="fill-current h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20">
								<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
							</svg>
						</div>
					</div>
				</div> */}


		<div className="flex justify-end  mb-2">
				<div style={{ width: "120px" }} className="relative pt-1 mx-8 ">
					<select
						style={{ height: "38px" }}
						onChange={(e) => filterDepartments(20000, e.target.value)}
						className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none text-xs focus:bg-white focus:border-gray-500"
						id="grid-state">
						<option value="active">Active</option>
						<option value="archived">Archived</option>
					</select>
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
						<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
							<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
						</svg>
					</div>
				</div>
			</div>

				
				{!loading ? (
					<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-6 py-4 mx-6 lg:mx-8">
					<table className="table-auto min-w-full px-2 ">
						<thead className="border-b">
							<tr className="min-h-24 text-xs text-gray-600 uppercase">
								<th className="px-4 py-2">
									<span className="lg:flex justify-center">
										<PeopleAltOutlined />
										<p className="px-2">Department</p>
									</span>
								</th>
								<th className="px-4 border-r border-l py-2">
									<span className="lg:flex justify-center">
										<StatusIcon />
										<p className="px-2">Status</p>
									</span>
								</th>
								<th className="px-4 py-2">
									<span className="lg:flex justify-center">
										<CalendarToday />
										<p className="px-2">Date Created</p>
									</span>
								</th>
								<th className="px-4 py-2 border-l"></th>
							</tr>
						</thead>
						<tbody>
							{allDepartments.departments && allDepartments.departments.length > 0 ? (
								allDepartments.departments.map((dept, i) => {
									return (
										<tr key={i} className="border-b" >
											<td className=" text-center  border-r px-4 py-2 text-sm  capitalize">
												{dept?.name}
											</td>
											<td className=" border-l text-center  border-r px-4 py-2 text-sm  capitalize">
												{departmentStatus[dept?.status]}
											</td>
											<td className=" border-l text-center  border-r px-4 py-2 text-sm  capitalize">
												{format(new Date(dept?.createdAt), "do MMMM, yyyy")}
											</td>
											<td className=" border-l text-center  px-4 py-2 text-sm  capitalize ">
												<div className="flex justify-center">
													<div
														className="cursor-pointer"
														onClick={() => {
															setOpendepartment(true);
															setDepartmentName(dept?.name);
															setActiveDepartment(dept);
														}}>
														<Tooltip title="edit department">
															<EditIcon />
														</Tooltip>
													</div>
													<div
														onClick={() => setOpenDelete(true)}
														className="text-red-500 cursor-pointer mx-4">
														<DeleteForeverOutlined />
													</div>
												</div>
											</td>
											<Modal
												isOpen={openDepartment}
												onRequestClose={() => setOpendepartment(!openDepartment)}
												contentLabel="Modal"
												style={customStyles}>
												<div className="px-4 mb-4">
													<div className="flex justify-end">
														<button onClick={() => setOpendepartment(!openDepartment)}>
															<CloseIcon modalOpen={setOpendepartment} />
														</button>
													</div>
													<form onSubmit={(e) => onEditDepartment(e)}>
														<label
															className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
															htmlFor="grid-city">
															Name of department
														</label>
														<input
															required
															value={departmentName}
															onChange={(e) => setDepartmentName(e.target.value)}
															className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-4 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
															id="grid-first-name"
															type="text"
															placeholder="enter name of department"
														/>
														<div className="mt-8 flex justify-end">
															<button
																type="submit"
																className="bg-orange-500 hover:bg-orange-700 w-1/2 text-white  text-xs py-3 px-4 rounded">
																{!saving ? "Update department" : "loading..."}
															</button>
														</div>
													</form>
												</div>
											</Modal>

											<Modal
												isOpen={openDelete}
												onRequestClose={() => setOpenDelete(!openDelete)}
												contentLabel="Modal"
												style={customStyles}>
												<div className="px-4 mb-4">
													<div className="flex justify-end">
														<button onClick={() => setOpenDelete(!openDelete)}>
															<CloseIcon modalOpen={setOpenDelete} />
														</button>
													</div>
													<div className="pt-12">
														<p>
															Are You sure you want to <span className="text-red-500">archive</span> the
															department <span className="bold"> "{dept?.name}"</span>
														</p>
													</div>
													<div className="flex justify-between pt-12">
														<Button onClick={() => setOpenDelete(false)} variant="outlined">
															Cancel
														</Button>

														<Button onClick={() => onDeactivateDepartment()} color="#f56565">
															<span className="text-red-500">
																{!saving ? "Yes, Deactivate" : "Deactivating..."}
															</span>
														</Button>
													</div>
												</div>
											</Modal>
										</tr>
									);
								})
							) : (
								<tr>No department found</tr>
							)}
						</tbody>
					</table>
					</div>
					
				) : (
					<div className="flex justify-center">
						<Pageloader />
					</div>
				)}

				<div className="mt-6 flex px-6 justify-end ">
					{!loading && (
						<Pagination
							onChange={(page) => onPageChange(page)}
							defaultCurrent={currentPage}
							total={allDepartments.count ? allDepartments.count : 0}
							pageSize={20}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	allDepartments: state.users.departments,
});

export default connect(mapStateToProps, { getDepartments, editDepartment, deactivateDepartment, filterDepartments })(
	Departments
);
