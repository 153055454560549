const { jobLogsActionTypes } = require("../action-types/joblogs-action.types");

const initialState = {
	allJobs: {},
	job: {},
	comments: {},
	openModal: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case jobLogsActionTypes.GET_ALL_JOBS:
			return { ...state, allJobs: action.payload };
		case jobLogsActionTypes.SET_OPEN_MODAL:
			return { ...state, openModal: action.payload };
		case jobLogsActionTypes.GET_SINGLE_JOB:
			return { ...state, job: action.payload };
		case jobLogsActionTypes.GET_JOB_COMMENTS:
			return { ...state, comments: action.payload };
		default:
			return state;
	}
};
