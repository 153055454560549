import React from "react";

const OverviewStat = ({ title, value }) => {
	return (
		<div className="bg-white full-width text-center h-24 p-4 rounded-sm shadow overflow-auto">
			<p className="text-gray-900  text-xs md:text-sm ">{value}</p>
			<p className="text-gray-600 text-xs lg:text-xs uppercase pt-2">{title}</p>
		</div>
	);
};

export default OverviewStat;
