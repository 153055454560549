import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import Overview from "./dashboard-tabs/overview/Overview";
import DashboardSearch from "../../components/dashboard-search/DashboardSearch";
import JobLogs from "./dashboard-tabs/job-logs/JobLogs";
import MyTask from "./dashboard-tabs/my-tasks/MyTask";
import TaskKanban from "./dashboard-tabs/my-tasks/kanban/TaskKanban";
import JobKanban from "./dashboard-tabs/job-logs/job-kanban/JobKanban";
import { connect } from "react-redux";
import { getRecentTasks } from "../../redux/actions/task.actions";
import AllActivities from "./dashboard-tabs/activities/AllActivities";
import AllAnnouncements from "./dashboard-tabs/activities/AllAnnouncements";

const Dashboard = (props) => {
	const { tab } = useParams();
	const { getRecentTasks, tasks, role } = props;
	const [activeTab, setTab] = useState("overview");
	const [kanban, setKanban] = useState(false);

	const changeTab = (selected) => {
		setTab(selected);
	};

	useEffect(() => {
		getAllTasks();
		return;
	}, []);

	const toggleKanbanView = () => {
		setKanban(!kanban);
	};

	const getAllTasks = async () => {
		let allTasks = await getRecentTasks();
		return allTasks;
	};

	return (
		<div
			style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}
			className="dashboard-page pb-4">
			{role !== 1 && (
				<DashboardNav
					role={role}
					kanban={kanban}
					setKanban={setKanban}
					activeTab={tab}
					changeTab={changeTab}
					history={props.history}
				/>
			)}
			{tab === "mytasks" || tab === "joblogs" ? (
				<DashboardSearch kanban={kanban} toggleKanbanView={toggleKanbanView} />
			) : (
				""
			)}
			{role !== 1 ? (
				<section>
					{tab === "overview" && (
						<div>
							<Overview role={role} />
						</div>
					)}
					{tab === "activities" && (
						<div>
							<AllActivities />
						</div>
					)}
					{tab === "announcements" && (
						<div>
							<AllAnnouncements />
						</div>
					)}
					{tab === "joblogs" && (
						<div>
							{!kanban ? <JobLogs kanban={kanban} toggleKanbanView={toggleKanbanView} /> : <JobKanban />}
						</div>
					)}
					{tab === "mytasks" && (
						<div>
							{!kanban ? (
								<MyTask kanban={kanban} toggleKanbanView={toggleKanbanView} />
							) : (
								<TaskKanban tasks={tasks} />
							)}
						</div>
					)}
				</section>
			) : (
				<p>Restricted page</p>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	tasks: state.tasks.allTasks,
});

export default connect(mapStateToProps, { getRecentTasks })(Dashboard);
