import React from "react";

const CustomFormLabel = ({ label }) => {
	return (
		<label
			className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2"
			htmlFor="grid-first-name">
			{label}
		</label>
	);
};

export default CustomFormLabel;
