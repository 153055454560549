import React from "react";
import { Link } from "react-router-dom";
import TaskCardItem from "./Task-card-item";

const TaskCard = ({ tasks }) => {
	return (
		<div className="order-card full-width pt-4 bg-white px-8 pb-4 shadow-sm rounded-sm ">
			<div className="flex justify-between">
				<span className="text-xs uppercase text-gray-600">Recent tasks</span>
				<Link to="/dashboard/mytasks">
					<span style={{ color: "#F26925" }} className="text-xs cursor-pointer">
						<Link to="/dashboard/mytasks">View all</Link>
					</span>
				</Link>
			</div>
			<div>
				{tasks.tasks && tasks.tasks.length > 0 ? (
					tasks.tasks.map((task, i) => {
						if (i < 4) {
							return <TaskCardItem task={task} key={i} />;
						}
					})
				) : (
					<p>No task added</p>
				)}
			</div>
		</div>
	);
};

export default TaskCard;
