import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useToasts } from "react-toast-notifications";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ExpandMore } from "@material-ui/icons";
import { connect } from "react-redux";

import { changeLeadStatus, getAllLeads, updateLead } from "../../redux/actions/leads.actions";
import { definedLeadStyle, leadStatusValues } from "../../utils/definedValues";

const SwitchLeadStatus = (props) => {
	const { leadStatus, updateLead, getAllLeads, leadId, changeLeadStatus } = props;
	const { addToast } = useToasts();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [status, setStatus] = useState(0);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setStatus(leadStatus);
	}, []);

	const style = definedLeadStyle[parseInt(leadStatus)];

	const onChangeStatus = async (state) => {
		setStatus(state);
		let statusDetail = {
			status: state,
		};
		let data = await changeLeadStatus(leadId, statusDetail);
		if (data.success) {
			addToast("Lead status updated", { appearance: "success" });
			getAllLeads(1, 20);
		} else {
			addToast(data.message, { appearance: "error" });
		}
	};

	return (
		<div>
			<Button
				aria-controls="simple-menu"
				className="outline-none"
				style={{ ...style.button, padding: "0px" }}
				aria-haspopup="true"
				onClick={handleClick}>
				<span className="text-xs flex" style={style.text}>
					<button
						type="button"
						className="py-2 px-4 rounded button-outline m-1 text-xs capitalize outline-none">
						{leadStatusValues[parseInt(leadStatus)]}
					</button>{" "}
					<span className="mt-3">
						<ExpandMore />
					</span>
				</span>
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(0);
					}}>
					Prospect
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(1);
					}}>
					Contact made
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(2);
					}}>
					Meeting booked
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(3);
					}}>
					Proposal
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(4);
					}}>
					Awaiting feedback
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(5);
					}}>
					Negotiating
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(6);
					}}>
					Won deal
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(7);
					}}>
					Lost deal
				</MenuItem>
			</Menu>
		</div>
	);
};

export default connect(null, {
	getAllLeads,
	updateLead,
	changeLeadStatus,
})(SwitchLeadStatus);
