import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Add, CalendarTodayOutlined, GroupOutlined, MoreVert } from "@material-ui/icons";
import { connect } from "react-redux";
import { changeLeadStatus, getAllLeads, updateLead } from "../../../redux/actions/leads.actions";
import { numberWithCommas } from "../../../utils/helper-functions";
import { format } from "date-fns";
import { Link } from "react-router-dom";

// const style = {
// 	button: { backgroundColor: "#EB5757" },
// 	text: {
// 		color: "#ffffff",
// 	},
// };

const STATUS_CODE = {
	prospect: 0,
	contactMade: 1,
	meetingBooked: 2,
	proposal: 3,
	awaitingFeedback: 4,
	negotiating: 5,
	wonDeals: 6,
	lostDeals: 7,
};

// a little function to help us with reordering the result
/**
 * @description reoder the generated list
 */
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

class LeadsKanban extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			greeting: "",
			prospect: [],
			contactMade: [],
			meetingBooked: [],
			proposal: [],
			awaitingFeedback: [],
			negotiating: [],
			wonDeals: [],
			lostDeals: [],
		};
	}

	/**
	 * A semi-generic way to handle multiple lists. Matches
	 * the IDs of the droppable container to the names of the
	 * source arrays stored in the state.
	 */
	id2List = {
		prospectColumn: "prospect",
		contactMadeColumn: "contactMade",
		meetingBookedColumn: "meetingBooked",
		proposalColumn: "proposal",
		awaitingFeedbackColumn: "awaitingFeedback",
		negotiatingColumn: "negotiating",
		wonDealsColumn: "wonDeals",
		lostDealsColumn: "lostDeals",
	};

	getList = (id) => this.state[this.id2List[id]];

	onDragEnd = (result) => {
		const { source, destination } = result;
		// dropped outside the list
		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const items = reorder(this.getList(source.droppableId), source.index, destination.index);
		} else {
			const result = move(
				this.getList(source.droppableId),
				this.getList(destination.droppableId),
				source,
				destination
			);
			let sourceId = source.droppableId;
			let destinationId = destination.droppableId;
			this.setState({
				[this.id2List[sourceId]]: result[sourceId],
				[this.id2List[destinationId]]: result[destinationId],
			});
			// loop through new list and persist
			let destinationArray = result[destinationId];
			let destinationStatus = STATUS_CODE[this.id2List[destinationId]];
			destinationArray.map((leadCard) => {
				let currentStatus = {
					status: parseInt(destinationStatus),
				};
				this.props.changeLeadStatus(leadCard._id, currentStatus);
			});
		}
		return;
	};

	componentDidMount() {
		this.ongetAllLeads();
	}

	ongetAllLeads = async () => {
		const { getAllLeads } = this.props;
		this.setState({
			loading: true,
		});
		let fetchedLeads = await getAllLeads(null, 2000000);
		if (fetchedLeads.success) {
			this.setState({
				loading: false,
			});
			this.sortAllLeads(fetchedLeads.data.leads);
		} else {
			this.setState({
				loading: false,
			});
		}
	};

	sortAllLeads = (data) => {
		const {
			prospect,
			contactMade,
			meetingBooked,
			proposal,
			awaitingFeedback,
			negotiating,
			wonDeals,
			lostDeals,
			changeLeadStatus,
		} = this.state;
		if (data) {
			let tempProspect = [...prospect];
			let tempContactMade = [...contactMade];
			let tempMeetingBooked = [...meetingBooked];
			let tempProposal = [...proposal];
			let tempAwaitingFeedback = [...awaitingFeedback];
			let tempNegotiating = [...negotiating];
			let tempWonDeals = [...wonDeals];
			let tempLostDeals = [...lostDeals];
			data.map((lead) => {
				if (lead.leadStatus === 0) {
					tempProspect.push(lead);
				} else if (lead.leadStatus === 1) {
					tempContactMade.push(lead);
				} else if (lead.leadStatus === 2) {
					tempMeetingBooked.push(lead);
				} else if (lead.leadStatus === 3) {
					tempProposal.push(lead);
				} else if (lead.leadStatus === 4) {
					tempAwaitingFeedback.push(lead);
				} else if (lead.leadStatus === 5) {
					tempNegotiating.push(lead);
				} else if (lead.leadStatus === 6) {
					tempWonDeals.push(lead);
				} else if (lead.leadStatus === 7) {
					tempLostDeals.push(lead);
				}
			});
			this.setState({
				prospect: tempProspect,
				contactMade: tempContactMade,
				meetingBooked: tempMeetingBooked,
				proposal: tempProposal,
				awaitingFeedback: tempAwaitingFeedback,
				negotiating: tempNegotiating,
				wonDeals: tempWonDeals,
				lostDeals: tempLostDeals,
			});
		}
	};

	render() {
		const {
			loading,
			prospect,
			contactMade,
			meetingBooked,
			proposal,
			awaitingFeedback,
			negotiating,
			wonDeals,
			lostDeals,
		} = this.state;
		return (
			<div>
				<div className="bg-white p-2  min-h-screen rounded-sm">
					<div className="px-4 md:px-8">
						<section className="mt-6">
							<DragDropContext onDragEnd={this.onDragEnd}>
								<div className="flex gap-4 overflow-x-scroll">
									<Droppable droppableId="prospectColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Prospect({prospect.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{/* Task card */}
												{prospect && loading === false ? (
													prospect.map((prospectCard, i) => (
														<Draggable
															key={prospectCard._id}
															draggableId={`prospectCard${prospectCard._id}`}
															index={i}>
															{(provided, snapshot) => (
																<Link to={`/leads/lead/${prospectCard._id}`}>
																	<div
																		className="bg-white mx-3 pb-4 rounded-sm mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			{prospectCard.description}
																		</p>
																		<div className="px-4 pt-4">
																			<p className="text-sm text-orange-600">
																				₦{numberWithCommas(prospectCard.potentialValue)}
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs uppercase">{prospectCard.organisation}</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																			<span className="pr-2">
																				{" "}
																				<CalendarTodayOutlined />{" "}
																			</span>
																			<p className="text-xs">
																				{format(new Date(prospectCard.createdAt), "do MMM yyyy")}
																			</p>
																		</div>
																	</div>
																</Link>
															)}
														</Draggable>
													))
												) : (
													<p className="text-xs text-gray-600 px-4">Loading...</p>
												)}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="contactMadeColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Contact made({contactMade.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{contactMade
													? contactMade.map((contacted, i) => (
															<Draggable
																key={contacted._id}
																draggableId={`contacted${contacted._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<Link to={`/leads/lead/${contacted._id}`}>
																		<div
																			className="bg-white mx-3 pb-4 rounded-sm mt-4"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{contacted.description}
																			</p>
																			<div className="px-4 pt-4">
																				<p className="text-sm text-orange-600">
																					₦{numberWithCommas(contacted.potentialValue)}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<GroupOutlined />{" "}
																				</span>
																				<p className="text-xs uppercase">{contacted.organisation}</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<CalendarTodayOutlined />{" "}
																				</span>
																				<p className="text-xs">
																					{format(new Date(contacted.createdAt), "do MMM yyyy")}
																				</p>
																			</div>
																		</div>
																	</Link>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="meetingBookedColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Meeting Booked({meetingBooked.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{meetingBooked
													? meetingBooked.map((meeting, i) => (
															<Draggable
																key={meeting._id}
																draggableId={`meeting${meeting._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<Link to={`/leads/lead/${meeting._id}`}>
																		<div
																			className="bg-white mx-3 pb-4 rounded-sm mt-4"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{meeting.description}
																			</p>
																			<div className="px-4 pt-4">
																				<p className="text-sm text-orange-600">
																					₦{numberWithCommas(meeting.potentialValue)}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<GroupOutlined />{" "}
																				</span>
																				<p className="text-xs uppercase">{meeting.organisation}</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<CalendarTodayOutlined />{" "}
																				</span>
																				<p className="text-xs">
																					{format(new Date(meeting.createdAt), "do MMM yyyy")}
																				</p>
																			</div>
																		</div>
																	</Link>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="proposalColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Proposal({proposal.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{proposal
													? proposal.map((proposalCard, i) => (
															<Draggable
																key={proposalCard._id}
																draggableId={`proposalCard${proposalCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<Link to={`/leads/lead/${proposalCard._id}`}>
																		<div
																			className="bg-white mx-3 pb-4 rounded-sm mt-4"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{proposalCard.description}
																			</p>
																			<div className="px-4 pt-4">
																				<p className="text-sm text-orange-600">
																					₦{numberWithCommas(proposalCard.potentialValue)}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<GroupOutlined />{" "}
																				</span>
																				<p className="text-xs uppercase">{proposalCard.organisation}</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<CalendarTodayOutlined />{" "}
																				</span>
																				<p className="text-xs">
																					{format(new Date(proposalCard.createdAt), "do MMM yyyy")}
																				</p>
																			</div>
																		</div>
																	</Link>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="awaitingFeedbackColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Awaiting feedback({awaitingFeedback.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.awaitingFeedback
													? this.state.awaitingFeedback.map((awaiting, i) => (
															<Draggable
																key={awaiting._id}
																draggableId={`awaiting${awaiting._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<Link to={`/leads/lead/${awaiting._id}`}>
																		<div
																			className="bg-white mx-3 pb-4 rounded-sm mt-4"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{awaiting.description}
																			</p>
																			<div className="px-4 pt-4">
																				<p className="text-sm text-orange-600">
																					₦{numberWithCommas(awaiting.potentialValue)}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<GroupOutlined />{" "}
																				</span>
																				<p className="text-xs uppercase">{awaiting.organisation}</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<CalendarTodayOutlined />{" "}
																				</span>
																				<p className="text-xs">
																					{format(new Date(awaiting.createdAt), "do MMM yyyy")}
																				</p>
																			</div>
																		</div>
																	</Link>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="negotiatingColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Negotiating({negotiating.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.negotiating
													? this.state.negotiating.map((negotiatingCard, i) => (
															<Draggable
																key={negotiatingCard._id}
																draggableId={`negotiatingCard${negotiatingCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<Link to={`/leads/lead/${negotiatingCard._id}`}>
																		<div
																			className="bg-white mx-3 pb-4 rounded-sm mt-4"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{negotiatingCard.description}
																			</p>
																			<div className="px-4 pt-4">
																				<p className="text-sm text-orange-600">
																					₦{numberWithCommas(negotiatingCard.potentialValue)}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<GroupOutlined />{" "}
																				</span>
																				<p className="text-xs uppercase">
																					{negotiatingCard.organisation}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<CalendarTodayOutlined />{" "}
																				</span>
																				<p className="text-xs">
																					{format(new Date(negotiatingCard.createdAt), "do MMM yyyy")}
																				</p>
																			</div>
																		</div>
																	</Link>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="wonDealsColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Won Deals({wonDeals.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{wonDeals
													? wonDeals.map((wonDealCard, i) => (
															<Draggable
																key={wonDealCard._id}
																draggableId={`wonDealCard${wonDealCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<Link to={`/leads/lead/${wonDealCard._id}`}>
																		<div
																			className="bg-white mx-3 pb-4 rounded-sm mt-4"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{wonDealCard.description}
																			</p>
																			<div className="px-4 pt-4">
																				<p className="text-sm text-orange-600">
																					₦{numberWithCommas(wonDealCard.potentialValue)}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<GroupOutlined />{" "}
																				</span>
																				<p className="text-xs uppercase">{wonDealCard.organisation}</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<CalendarTodayOutlined />{" "}
																				</span>
																				<p className="text-xs">
																					{format(new Date(wonDealCard.createdAt), "do MMM yyyy")}
																				</p>
																			</div>
																		</div>
																	</Link>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="lostDealsColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-sm w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Lost Deals({lostDeals.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.lostDeals
													? this.state.lostDeals.map((lostDealCard, i) => (
															<Draggable
																key={lostDealCard._id}
																draggableId={`lostDealCard${lostDealCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<Link to={`/leads/lead/${lostDealCard._id}`}>
																		<div
																			className="bg-white mx-3 pb-4 rounded-sm mt-4"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{lostDealCard.description}
																			</p>
																			<div className="px-4 pt-4">
																				<p className="text-sm text-orange-600">
																					₦{numberWithCommas(lostDealCard.potentialValue)}
																				</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<GroupOutlined />{" "}
																				</span>
																				<p className="text-xs uppercase">{lostDealCard.organisation}</p>
																			</div>
																			<div className="px-4 pt-4 flex  text-gray-600 text-sm">
																				<span className="pr-2">
																					{" "}
																					<CalendarTodayOutlined />{" "}
																				</span>
																				<p className="text-xs">
																					{format(new Date(lostDealCard.createdAt), "do MMM yyyy")}
																				</p>
																			</div>
																		</div>
																	</Link>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</div>
							</DragDropContext>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	allLeads: state.leads.allLeads,
});

export default connect(mapStateToProps, { getAllLeads, updateLead, changeLeadStatus })(LeadsKanban);
