import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Add, MoreVert } from "@material-ui/icons";
import { getGreeting } from "../../../../../utils/getGreeting";
import { connect } from "react-redux";
import { changeTaskStatus, getAllTasks } from "../../../../../redux/actions/task.actions";
import DateButton from "../../../../../components/commons/Date-button";

const STATUS_CODE = {
	todos: 0,
	progress: 1,
	review: 2,
	done: 3,
	onHold: 4,
	cancelled: 5,
};

const style = {
	button: { backgroundColor: "#EB5757" },
	text: {
		color: "#ffffff",
	},
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);
	destClone.splice(droppableDestination.index, 0, removed);
	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

const grid = 8;

class TaskKanban extends Component {
	constructor(props) {
		super(props);
		this.state = {
			greeting: "",
			todos: [],
			progress: [],
			review: [],
			done: [],
			onHold: [],
			cancelled: [],
			tasks: [],
			user: {},
		};
	}

	/**
	 * A semi-generic way to handle multiple lists. Matches
	 * the IDs of the droppable container to the names of the
	 * source arrays stored in the state.
	 */
	id2List = {
		todoColumn: "todos",
		inProgress: "progress",
		reviewColumn: "review",
		doneColumn: "done",
		onHoldColumn: "onHold",
		cancelledColumn: "cancelled",
	};

	getList = (id) => this.state[this.id2List[id]];

	onDragEnd = (result) => {
		const { changeTaskStatus } = this.props;
		const { source, destination } = result;
		// dropped outside the list
		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const items = reorder(this.getList(source.droppableId), source.index, destination.index);

			let state = { items };
			// console.log("same column", items);
		} else {
			const result = move(
				this.getList(source.droppableId),
				this.getList(destination.droppableId),
				source,
				destination
			);
			let sourceId = source.droppableId;
			let destinationId = destination.droppableId;

			this.setState({
				[this.id2List[sourceId]]: result[sourceId],
				[this.id2List[destinationId]]: result[destinationId],
			});
			// let sourceArray = result[sourceId];
			let destinationArray = result[destinationId];
			let destinationStatus = STATUS_CODE[this.id2List[destinationId]];
			destinationArray.map((destTask) => {
				let currentStatus = {
					status: parseInt(destinationStatus),
				};
				changeTaskStatus(destTask._id, currentStatus);
			});
			// console.log("result", sourceArray, destinationArray, destinationStatus);
		}
	};

	componentDidMount() {
		this.onGetAllTasks();
		this.onGetUser();
		this.setState({
			greeting: getGreeting(),
		});
	}

	onGetAllTasks = async () => {
		const { getAllTasks } = this.props;
		let res = await getAllTasks();
		if (res.success) {
			this.setState(
				{
					tasks: res?.data?.tasks,
				},
				() => {
					this.sortFetchedTasks(res?.data?.tasks);
				}
			);
			return res;
		}
	};

	onGetUser = () => {
		let storage = JSON.parse(sessionStorage.getItem("_ark_task"));
		if (storage) {
			this.setState({
				user: storage.user,
			});
		}
	};

	componentDidUpdate(prevProps, prevState) {}

	// Prefill the task columns with the existing tasks
	sortFetchedTasks = (data) => {
		const { todos, progress, review, done, onHold, cancelled } = this.state;
		if (data) {
			let tempProgress = [...progress];
			let tempTodos = [...todos];
			let tempReview = [...review];
			let tempDone = [...done];
			let tempOnHold = [...onHold];
			let tempCancelled = [...cancelled];
			data.map((task, i) => {
				if (task.status === 0) {
					tempTodos.push(task);
				} else if (task.status === 1) {
					tempProgress.push(task);
				} else if (task.status === 2) {
					tempReview.push(task);
				} else if (task.status === 3) {
					tempDone.push(task);
				} else if (task.status === 4) {
					tempOnHold.push(task);
				} else if (task.status === 5) {
					tempCancelled.push(task);
				}
			});
			this.setState({
				progress: tempProgress,
				todos: tempTodos,
				review: tempReview,
				done: tempDone,
				onHold: tempOnHold,
				cancelled: tempCancelled,
			});
		}
	};

	render() {
		const { greeting, progress, todos, done, onHold, review, cancelled } = this.state;
		return (
			<div>
				<div className="bg-white  min-h-screen rounded-md">
					<div className="px-4 md:px-8">
						<section>
							<h1 className="text-md font-medium lg:text-3xl">
								{greeting}, {this.state.user.firstName ? this.state.user.firstName : ""}
							</h1>
							<p className="text-xs mb-1 lg:mb-2 text-gray-700">Here is a breakdown of your tasks:</p>
						</section>

						<section className="mt-6">
							<DragDropContext onDragEnd={this.onDragEnd}>
								<div className="flex gap-4 overflow-x-scroll">
									<Droppable droppableId="todoColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md  m-2 w-64"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">Todo({todos.length})</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{/* Task card */}
												{this.state.todos
													? this.state.todos.map((todo, i) => (
															<Draggable key={todo._id} draggableId={`todo${todo._id}`} index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 h-32 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p className="text-xs text-gray-900 px-4 pt-4">{todo.title}</p>
																		<span className="text-xs flex">
																			<DateButton task={todo} />
																		</span>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="inProgress">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														In Progress({progress.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.progress
													? this.state.progress.map((progressCard, i) => (
															<Draggable
																key={progressCard._id}
																draggableId={`progressCard${progressCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 h-32 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			{progressCard.title}
																		</p>
																		<span className="text-xs flex">
																			<DateButton task={progressCard} />
																		</span>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="reviewColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														In review({review.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.review
													? this.state.review.map((reviewCard, i) => (
															<Draggable
																key={reviewCard._id}
																draggableId={`reviewCard${reviewCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 h-32 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p className="text-xs text-gray-900 px-4 pt-4">{reviewCard.title}</p>
																		<span className="text-xs flex">
																			<DateButton task={reviewCard} />
																		</span>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="doneColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">Done({done.length})</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.done
													? this.state.done.map((doneCard, i) => (
															<Draggable
																key={doneCard._id}
																draggableId={`doneCard${doneCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 h-32 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p className="text-xs text-gray-900 px-4 pt-4">{doneCard.title}</p>
																		<span className="text-xs flex">
																			<DateButton task={doneCard} />
																		</span>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="onHoldColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														On-hold({onHold.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.onHold
													? this.state.onHold.map((onHoldCard, i) => (
															<Draggable
																key={onHoldCard._id}
																draggableId={`onHoldCard${onHoldCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 h-32 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p className="text-xs text-gray-900 px-4 pt-4">{onHoldCard.title}</p>
																		<span className="text-xs flex">
																			<DateButton task={onHoldCard} />
																		</span>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="cancelledColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Cancelled({cancelled.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.cancelled
													? this.state.cancelled.map((cancelledCard, i) => (
															<Draggable
																key={cancelledCard._id}
																draggableId={`cancelledCard${cancelledCard._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 h-32 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			{cancelledCard.title}
																		</p>
																		<span className="text-xs flex">
																			<DateButton task={cancelledCard} />
																		</span>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</div>
							</DragDropContext>
						</section>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	allTasks: state.tasks.allTasks,
});

export default connect(mapStateToProps, { changeTaskStatus, getAllTasks })(TaskKanban);
