import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useClickAway } from "react-use";
import Modal from "react-modal";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import addNotification from "react-push-notification";
import {
	SearchOutlined,
	Notifications,
	ExpandMore,
	ExitToApp,
	MenuOutlined,
	PersonAddOutlined,
	SettingsOutlined,
	PeopleAltOutlined,
	ContactsOutlined,
	FiberManualRecord,
	List,
} from "@material-ui/icons";
import { ArkLogo, CloseIcon } from "../../assets/Svg";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import { logout } from "../../redux/actions/auth.actions";
import { Drawer, MenuItem } from "@material-ui/core";
import SideBarList from "./SideBarList";
import CreateUser from "../containers/CreateUser";
import AddDepartment from "../containers/AddDepartment";
import { getNameInitials, streamNotification } from "../../utils/helper-functions";
import AddCustomer from "../containers/AddCustomer";
import AddOder from "../containers/AddOder";
import AddTask from "../containers/AddTask";
import AddLeads from "../containers/AddLeads";
import AddProducts from "../containers/AddProducts";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";
Modal.setAppElement("#root");
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		overflow: "scroll",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		height: "600px",
		width: "90%",
	},
};
const customOrderStyle = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "90%",
		height: "600px",
		overflowY: "scroll",
		zIndex: "2000",
	},
};

const customTaskStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "90%",
		height: "650px",
		overflowY: "scroll",
	},
};

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
		padding: "5px",
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));

const useStyle = makeStyles({
	menuButton: {
		color: "#ffffff",
		cursor: "pointer",
	},
	nested: {
		paddingLeft: 4,
	},
});

const Navbar = (props) => {
	const { logout, role } = props;
	const [anchorEl2, setAnchorEl2] = React.useState(null);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openInvite, setOpenInvite] = useState(false);
	const [openDepartment, setOpendepartment] = useState(false);
	const [openNotification, setOpenNotification] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [isPaused, setPause] = useState(false);
	const [newMessage, setNewMessage] = useState(false);
	const [expandAdd, setExpandAdd] = useState(false);
	const [openAddProduct, setOpenAddProduct] = useState(false);
	const [openAddCustomer, setOpenCustomer] = useState(false);
	const [openLeads, setOpenLeads] = useState(false);
	const [openAddTask, setopenAddTask] = useState(false);
	const [openAddOrder, setOpenAddOder] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const ws = useRef(null);

	const messageHistory = useRef(null);
	const [user, setUser] = useState({});
	const classes = useStyle();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	useClickAway(messageHistory, () => {
		setOpenNotification(false);
	});

	useEffect(() => {
		let socketUrl = "";
		if (window.location.href.includes("erp")) {
			socketUrl = "wss://erp.ark.com.ng/v1/notifications";
		}
		if (window.location.href.includes("localhost")) {
			socketUrl = "ws://ark-beta.herokuapp.com/v1/notifications";
		}
		if (window.location.href.includes("netlify")) {
			socketUrl = "wss://ark-beta.herokuapp.com/v1/notifications";
		}
		ws.current = new WebSocket(socketUrl);
		// return () => {
		// 	if (ws.current) {
		// 		ws.current.open();
		// 	}
		// };
	}, []);

	useEffect(() => {
		if (!ws.current) return;

		ws.current.onmessage = (e) => {
			setNewMessage(true);
			if (isPaused) return;
			const message = JSON.parse(e.data);
			let tempNotifications = [...notifications];
			tempNotifications.push(message.description);
			setNotifications(tempNotifications);
			addNotification({
				title: "ERP",
				subtitle: "Notification",
				message: message.description,
				theme: "darkblue",
				native: true, // when using native, your OS will handle theming.
			});
		};
	}, [isPaused]);

	useEffect(() => {
		onGetUser();
	}, []);

	const handleClose = () => {
		setAnchorEl(null);
	};
	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setOpenDrawer(open);
	};

	const onGetUser = () => {
		let storage = JSON.parse(sessionStorage.getItem("_ark_task"));
		if (storage) {
			setUser(storage.user);
		}
	};

	const userStorage = JSON.parse(sessionStorage.getItem("_ark_task"));

	// messageHistory.current = useMemo(() => messageHistory.current.concat(lastMessage), [lastMessage]);

	// const handleClickChangeSocketUrl = useCallback(() => setSocketUrl("wss://demos.kaazing.com/echo"), []);

	// const connectionStatus = {
	// 	[ReadyState.CONNECTING]: "Connecting",
	// 	[ReadyState.OPEN]: "Open",
	// 	[ReadyState.CLOSING]: "Closing",
	// 	[ReadyState.CLOSED]: "Closed",
	// 	[ReadyState.UNINSTANTIATED]: "Uninstantiated",
	// }[readyState];

	const open = Boolean(anchorEl2);
	const id = open ? "simple-popover" : undefined;

	return (
		<>
			<div className="container custom-nav bg-black min-w-full ">
				{streamNotification()}
				<div className="flex p-4 justify-between">
					<div className="text-white flex">
						<Link to="/">
							<ArkLogo />
						</Link>
						<div className=" ml-20 text-sm hidden lg:flex">
							{role !== 1 && (
								<span
									className={
										props.history.location.pathname.includes("/dashboard")
											? "active-header-link px-4 hover:text-orange-400"
											: "inactive-header-link px-4 hover:text-orange-400"
									}>
									<Link to="/dashboard/overview">Dashboard</Link>
								</span>
							)}
							<span
								className={
									props.history.location.pathname.includes("/orders")
										? "active-header-link px-4 hover:text-orange-400"
										: "inactive-header-link px-4 hover:text-orange-400"
								}>
								<Link to="/orders">Orders</Link>
							</span>
							{role !== 1 && role !== 2 ? (
								<span
									className={
										props.history.location.pathname.includes("/leads")
											? "active-header-link px-4 hover:text-orange-400"
											: "inactive-header-link px-4 hover:text-orange-400"
									}>
									<Link to="/leads">Leads</Link>
								</span>
							) : (
								""
							)}
							{role !== 1 ? (
								<span
									className={
										props.history.location.pathname.includes("/customers")
											? "active-header-link px-4 hover:text-orange-400"
											: "inactive-header-link px-4 hover:text-orange-400"
									}>
									<Link to="/customers">Customers</Link>
								</span>
							) : (
								""
							)}
							{role !== 1 ? (
								<span
									className={
										props.history.location.pathname.includes("/products")
											? "active-header-link px-4 hover:text-orange-400"
											: "inactive-header-link px-4 hover:text-orange-400"
									}>
									<Link to="/products">Products</Link>
								</span>
							) : (
								""
							)}
						</div>
					</div>{" "}
					{openNotification === true && (
						<div style={{ marginRight: "10px" }}>
							<div className="notification-container bg-white shadow-sm rounded-sm p-6">
								<div
									onClick={() => setOpenNotification(!setOpenNotification)}
									className="flex justify-end">
									<CloseIcon />
								</div>
								<div>
									<hr className="text-gray-600 mt-4" />
									<div ref={messageHistory} className="text-gray-600 italic">
										{notifications.length > 0 ? (
											notifications.map((item, i) => {
												return (
													<p key={i} className="text-xs font-bold mx-3 my-auto text-gray-600">
														<span dangerouslySetInnerHTML={{ __html: item }} />
													</p>
												);
											})
										) : (
											<p className="text-xs">No notification</p>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="hidden lg:flex">
						<span className="action-icons px-2 z-1">
							<div>
								<div>
									<span
										onClick={() => {
											setNewMessage(false);
											setOpenNotification(!openNotification);
										}}>
										<Notifications />
										{newMessage && (
											<span style={{ fontSize: "12px", marginLeft: "-10px" }} className="text-green-300">
												<FiberManualRecord fontSize="inherit" />
											</span>
										)}
									</span>
								</div>
							</div>
						</span>

						<span className="mb-2">
							<Link to="/profile">
								{userStorage.user.profilePicture && userStorage.user.profilePicture.length > 0 ? (
									<img
										className="w-8 h-8 mx-2 rounded-full mr-4"
										src={
											userStorage.user.profilePicture && userStorage.user.profilePicture.length > 0
												? userStorage.user.profilePicture
												: "/uploads/profile-placeholder.jpg"
										}
										alt="photo"
									/>
								) : (
									<div
										className="w-8 h-8 mx-2 rounded-full mr-4"
										style={{
											backgroundColor: "#f0efec",
										}}>
										<div className="flex justify-center uppercase">
											<p style={{ marginTop: "7px", fontSize: "12px" }}>
												{getNameInitials(
													`${userStorage.user ? userStorage.user.firstName : "Unknown"} ${
														userStorage.user ? userStorage.user.lastName : "User"
													}`
												)}
											</p>
										</div>
									</div>
								)}
							</Link>
						</span>
						<div onClick={handleClick} className="flex cursor-pointer">
							<span className="text-white text-xs py-1">{user.firstName ? user.firstName : ""}</span>
							<span
								aria-controls="customized-menu"
								aria-haspopup="true"
								className="action-icons px-1 pt-1">
								<ExpandMore />
							</span>
						</div>
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							className="px-3"
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}>
							{role !== 1 && role !== 2 ? (
								<div
									onClick={() => {
										setOpenInvite(true);
										setAnchorEl("");
									}}
									className="w-48 flex cursor-pointer text-sm py-1 hover:bg-orange-600 hover:text-white">
									<span>
										<PersonAddOutlined />
									</span>{" "}
									<span className="mx-6">Invite user</span>
								</div>
							) : (
								""
							)}
							{role !== 1 && role !== 2 ? (
								<Link to="/users">
									<div
										onClick={() => {
											setAnchorEl("");
										}}
										className="w-48 flex cursor-pointer text-sm py-1 hover:bg-orange-600 hover:text-white">
										<span>
											<ContactsOutlined />
										</span>{" "}
										<span className="mx-6">View users</span>
									</div>
								</Link>
							) : (
								""
							)}

							{role !== 1 && role !== 2 ? (
								<div
									onClick={() => {
										setOpendepartment(true);
										setAnchorEl("");
									}}
									className="w-48 flex cursor-pointer text-sm py-1 hover:bg-orange-600 hover:text-white">
									<span>
										<PeopleAltOutlined />
									</span>{" "}
									<span className="mx-6">Add Department</span>
								</div>
							) : (
								""
							)}
							{role !== 1 && role !== 2 ? (
								<Link to="/departments">
									<div className="w-48 flex cursor-pointer text-sm py-1 hover:bg-orange-600 hover:text-white">
										<span>
											<List />
										</span>{" "}
										<span className="mx-6">View departments</span>
									</div>
								</Link>
							) : (
								""
							)}
							<div className="w-48 flex cursor-pointer text-sm py-1 hover:bg-orange-600 hover:text-white">
								<span>
									<SettingsOutlined />
								</span>{" "}
								<Link to="/profile">
									<span className="mx-6">Profile</span>
								</Link>
							</div>
							<div
								onClick={() => logout()}
								className="w-48 flex cursor-pointer text-sm py-1 hover:bg-orange-600 hover:text-white">
								<span>
									<ExitToApp />
								</span>{" "}
								<span className="mx-6">logout</span>
							</div>
						</StyledMenu>
					</div>
					<div className="menu-icon lg:hidden flex">
						<MenuOutlined onClick={() => setOpenDrawer(!openDrawer)} className={classes.menuButton} />
						<Drawer anchor={"left"} open={openDrawer} onClose={toggleDrawer(!openDrawer)}>
							<SideBarList
								expandAdd={expandAdd}
								setExpandAdd={setExpandAdd}
								openAddProduct={openAddProduct}
								setOpenAddProduct={setOpenAddProduct}
								openAddCustomer={openAddCustomer}
								setOpenCustomer={setOpenCustomer}
								openLeads={openLeads}
								setOpenLeads={setOpenLeads}
								openAddTask={openAddTask}
								setopenAddTask={setopenAddTask}
								openAddOrder={openAddOrder}
								setOpenAddOder={setOpenAddOder}
								setOpenDrawer={setOpenDrawer}
								setOpendepartment={setOpendepartment}
								logout={logout}
							/>
						</Drawer>
					</div>
				</div>
				<Modal
					isOpen={openAddCustomer}
					onRequestClose={() => setOpenCustomer(!openAddCustomer)}
					contentLabel="Modal"
					style={customStyles}>
					<div className="px-4 mb-4">
						<div className="flex justify-end">
							<button onClick={() => setOpenCustomer(!openAddCustomer)}>
								<CloseIcon modalOpen={setOpenCustomer} />
							</button>
						</div>
						<AddCustomer modalOpen={setOpenCustomer} />
					</div>
				</Modal>
				<Modal
					isOpen={openAddOrder}
					onRequestClose={() => setOpenAddOder(!openAddOrder)}
					contentLabel="Modal"
					style={customOrderStyle}>
					<div>
						<div className="flex justify-between">
							<p className="text-xl mt-4">Create a new order</p>
							<button onClick={() => setOpenAddOder(!openAddOrder)}>
								<CloseIcon modalOpen={setOpenAddOder} />
							</button>
						</div>
						<AddOder modalOpen={setOpenAddOder} />
					</div>
				</Modal>
				<Modal
					isOpen={openAddProduct}
					onRequestClose={() => setOpenAddProduct(!openAddProduct)}
					contentLabel="Modal"
					style={customStyles}>
					<div>
						<div className="flex justify-end">
							<button onClick={() => setOpenAddProduct(!openAddProduct)}>
								<CloseIcon modalOpen={setOpenAddProduct} />
							</button>
						</div>
						<AddProducts modalOpen={setOpenAddProduct} />
					</div>
				</Modal>

				<Modal
					isOpen={openLeads}
					onRequestClose={() => setOpenLeads(!openLeads)}
					contentLabel="Modal"
					style={customStyles}>
					<div>
						<div className="flex justify-end">
							<button onClick={() => setOpenLeads(!openLeads)}>
								<CloseIcon modalOpen={setOpenLeads} />
							</button>
						</div>
						<AddLeads modalOpen={setOpenLeads} />
					</div>
				</Modal>

				<Modal
					isOpen={openAddTask}
					onRequestClose={() => setopenAddTask(!openAddTask)}
					contentLabel="Modal"
					style={customTaskStyles}>
					<div>
						<div className="flex justify-end">
							<button onClick={() => setopenAddTask(!openAddTask)}>
								<CloseIcon modalOpen={setopenAddTask} />
							</button>
						</div>
						<AddTask modalOpen={setopenAddTask} />
					</div>
				</Modal>
				<Modal
					isOpen={openInvite}
					onRequestClose={() => setOpenInvite(!openInvite)}
					contentLabel="Modal"
					style={customStyles}>
					<div className="px-4 mb-4">
						<div className="flex justify-end">
							<button onClick={() => setOpenInvite(!openInvite)}>
								<CloseIcon modalOpen={setOpenInvite} />
							</button>
						</div>
						<CreateUser modalOpen={setOpenInvite} />
					</div>
				</Modal>
				<Modal
					isOpen={openDepartment}
					onRequestClose={() => setOpendepartment(!openDepartment)}
					contentLabel="Modal"
					style={customStyles}>
					<div className="px-4 mb-4">
						<div className="flex justify-end">
							<button onClick={() => setOpendepartment(!openDepartment)}>
								<CloseIcon modalOpen={setOpendepartment} />
							</button>
						</div>
						<AddDepartment modalOpen={setOpendepartment} />
					</div>
				</Modal>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
