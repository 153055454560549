import { QueryBuilderOutlined } from "@material-ui/icons";
import { formatDistance } from "date-fns";
import React from "react";
import { getNameInitials } from "../../../../utils/helper-functions";

const AllAnnouncementItem = ({ event, lastItem }) => {
	return (
		<>
			<div className="mt-6 flex justify-between py-2">
				<div className="order-title flex">
					{event.createdBy.profilePicture.length > 0 ? (
						<img
							className="rounded-full w-8 h-8 mx-2"
							src={
								event.createdBy.profilePicture.length > 0
									? event.createdBy.profilePicture
									: "/uploads/profile-placeholder.jpg"
							}
							alt="Sunset in the mountains"
						/>
					) : (
						<div
							className="w-8 h-8 mx-2 rounded-full mr-4"
							style={{
								backgroundColor: "#f0efec",
							}}>
							<div className="flex justify-center uppercase">
								<p style={{ marginTop: "7px", fontSize: "12px" }}>
									{getNameInitials(
										`${event.createdBy ? event.createdBy.firstName : "Unknown"} ${
											event.createdBy ? event.createdBy.lastName : "User"
										}`
									)}
								</p>
							</div>
						</div>
					)}
					<div className="pr-12 text-sm">
						<p className="text-xs font-bold mx-3 my-auto text-gray-600">{event?.message}</p>
					</div>
				</div>
				<div className="flex text-gray-600 ">
					<QueryBuilderOutlined />
					<span className="mx-2 text-xs">
						{" "}
						{formatDistance(new Date(event.createdAt), new Date(), {
							addSuffix: true,
						})}
					</span>
				</div>
			</div>
			{!lastItem && <hr className="border-gray-200 w-full" />}
		</>
	);
};

export default AllAnnouncementItem;
