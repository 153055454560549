import axios from "axios";
const { productsActionTypes } = require("../action-types/product-actions.type");

export const createProducts = (productDetails) => async (dispatch) => {
	let url = "/products";
	try {
		let add = await axios.put(url, productDetails);
		const { success } = add.data;
		if (success) {
			return add.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

//
export const getAllProducts = (page, limit, all) => async (dispatch) => {
	let url = "";
	if (all === true) {
		url = `/products?limit=${limit}`;
	} else {
		url = `/products?page=${page}&limit=${limit}`;
	}
	try {
		let prod = await axios.get(url);
		const { success, data } = prod.data;
		if (success) {
			dispatch({
				type: productsActionTypes.GET_ALL_PRODUCTS,
				payload: data,
			});
			return prod.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const filterProducts = (filter) => async (dispatch) => {
	let url = "";
	if (filter.length > 0) {
		url = `/products?category=${filter}`;
	} else {
		url = `/products`;
	}
	try {
		let prod = await axios.get(url);
		const { success, data } = prod.data;
		if (success) {
			dispatch({
				type: productsActionTypes.GET_ALL_PRODUCTS,
				payload: data,
			});
			return prod.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const searchProducts = (query) => async (dispatch) => {
	let url = "";
	if (query.length > 0) {
		url = `/products?query=${query}`;
	} else {
		url = `/products?page=${1}&limit=${20}`;
	}
	try {
		let prod = await axios.get(url);
		const { success, data } = prod.data;
		if (success) {
			dispatch({
				type: productsActionTypes.GET_ALL_PRODUCTS,
				payload: data,
			});
			return prod.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getSingleProduct = (id) => async (dispatch) => {
	let url = `/products/${id}`;
	try {
		let prod = await axios.get(url);
		const { success, data } = prod.data;
		if (success) {
			dispatch({
				type: productsActionTypes.GET_SINGLE_PRODUCT,
				payload: data,
			});
			return prod.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const updateProduct = (id, productDetails) => async (dispatch) => {
	let url = `/products/${id}`;
	try {
		let add = await axios.patch(url, productDetails);
		const { success } = add.data;
		if (success) {
			return add.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const deleteProduct = (id) => async () => {
	let url = `/products/${id}`;
	try {
		let data = await axios.delete(url);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};
