import { authActionTypes } from "../action-types/auth-action.types";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const login = (loginDetails) => async (dispatch) => {
	let url = "/auth/login";
	try {
		const loginUser = await axios.post(url, loginDetails);
		const { data, success } = loginUser.data;
		if (success) {
			sessionStorage.setItem("_ark_task", JSON.stringify(data));
			setTimeout(() => {
				// window.location.replace("/dashboard");
			}, 200);
		}
		return loginUser.data;
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const logout = () => async (dispatch) => {
	sessionStorage.clear();
	window.location.replace("/");
};

export const verifyToken = () => async (dispatch) => {
	let credentials = JSON.parse(sessionStorage.getItem("_ark_task"));
	if (credentials && credentials.token) {
		let token = credentials.token;
		let decoded = jwt_decode(token);
		if (Date.now() >= Date.parse(decoded.exp)) {
			dispatch({
				type: authActionTypes.VERIFY_TOKEN,
				payload: false,
			});
			window.sessionStorage.clear();
			window.location.replace("/");
		} else {
			dispatch({
				type: authActionTypes.VERIFY_TOKEN,
				payload: true,
			});
		}
	}
};

export const inviteUser = (userDetails) => async (dispatch) => {
	let url = "/users";
	try {
		const user = await axios.put(url, userDetails);
		const { success } = user.data;
		if (success) {
			return user.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateProfile = (userDetails) => async (dispatch) => {
	let url = "/users";
	try {
		const user = await axios.patch(url, userDetails);
		const { success } = user.data;
		if (success) {
			return user.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updatePassword = (details) => async () => {
	let url = "/users";
	try {
		let users = await axios.post(url, details);
		const { success } = users.data;
		if (success) {
			return users.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getSingleUser = (id) => async (dispatch) => {
	let url = `/users/${id}`;
	try {
		const user = await axios.get(url);
		const { success } = user.data;
		if (success) {
			return user.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const forgotPassword = (email) => async (dispatch) => {
	let url = `/auth/forgot-password`;
	try {
		const data = await axios.post(url, email);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const resetPassword = (details) => async (dispatch) => {
	let url = `/auth/reset-password`;
	try {
		const data = await axios.post(url, details);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};
