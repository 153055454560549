import { DeleteOutline } from "@material-ui/icons";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import SelectPointer from "../../components/commons/SelectPointer";
import { editUser, getAllUsers, getDepartments } from "../../redux/actions/users.actions";
import { useToasts } from "react-toast-notifications";

const EditUser = ({ user, getDepartments, allDepartments, editUser, modalOpen, getAllUsers }) => {
	const [uploading, setUploading] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setlastName] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("");
	const [phone, setPhone] = useState("");
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);
	const [editProfile, setEditProfile] = useState(false);
	const [profilePicture, setProfilePicture] = useState("");
	const [department, setDepartment] = useState("");
	const [departmentDefault, setDepartmentDefault] = useState({});
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const { addToast } = useToasts();

	useEffect(() => {
		setFirstName(user?.firstName);
		setlastName(user?.lastName);
		setEmail(user?.email);
		setPhone(user?.phone);
		setRole(user?.role);
		setDepartment(user?.department?._id);
		setDepartmentDefault({ value: user?.department?._id, label: user?.department?.name });
		setProfilePicture(user?.profilePicture);
	}, []);

	useEffect(() => {
		getDepartments();
	}, []);

	useEffect(() => {
		let copyDepartments = [];
		if (allDepartments.departments && allDepartments.departments.length > 0) {
			allDepartments.departments.map((dept) => {
				copyDepartments.push({
					value: dept._id,
					label: dept.name,
				});
			});
			setDepartmentOptions(copyDepartments);
		}
	}, [allDepartments]);

	const onSaveUserDetails = async (e) => {
		e.preventDefault();
		setSaving(true);
		let details = {
			firstName,
			lastName,
			email,
			phone,
			role,
			department,
		};
		if (profilePicture.length > 0) {
			details.profilePicture = profilePicture;
		}
		let res = await editUser(user?._id, details);
		if (res.success) {
			modalOpen(false);
			getAllUsers(20);
			addToast("User profile edited", { appearance: "success" });
		} else {
			addToast(res?.error, { appearance: "error" });
		}
		setSaving(false);
	};

	return (
		<div>
			<div className="min-w-full bg-white rounded-md p-4 px-3 md:px-8 mt-8">
				<div className=" md:flex">
					<div className="w-full md:w-1/4 align-middle">
						<img
							className=" md:ml-5  rounded-full"
							height="200px"
							width="200px"
							alt="profile"
							src={profilePicture?.length > 0 ? profilePicture : "/uploads/profile-placeholder.jpg"}
						/>
						<div className="flex mt-4 text-xs">
							<div className="flex mx-10  cursor-pointer overflow-hidden">
								<input
									accept="image/*"
									style={{ top: "0", bottom: "0", left: "0", width: "2px" }}
									className="opacity-0"
									type="file"
								/>
							</div>
							{/* <div className="flex cursor-pointer text-red-600">
								<DeleteOutline /> <span className="text-red-600">Delete</span>
							</div> */}
						</div>
					</div>
					<div className="w-full md:w-3/4">
						<div className="flex justify-between">
							<div>
								<p className="text-sm text-gray-900 bold">Personal information</p>
							</div>
						</div>
						<hr className="border-gray-200 w-full my-4" />
						{/* form */}
						<form onSubmit={(e) => onSaveUserDetails(e)}>
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="grid-first-name">
										First Name
									</label>
									<input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
										id="grid-first-name"
										type="text"
										value={firstName}
										placeholder="first name"
									/>
								</div>
								<div className="w-full md:w-1/2 px-3">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="grid-last-name">
										Last Name
									</label>
									<input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-last-name"
										type="text"
										value={lastName}
										placeholder="last name"
									/>
								</div>
							</div>

							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="grid-first-email">
										Email Address
									</label>
									<input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
										id="grid-first-email"
										type="text"
										value={email}
										placeholder="email"
									/>
								</div>
								<div className="w-full md:w-1/2 px-3">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="grid-last-phone">
										Phone number
									</label>
									{/* <input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-last-phone"
										value={phone}
										type="text"
										placeholder="+2349044844748"
									/> */}
									<PhoneInput
										country={"ng"}
										enableSearch={true}
										value={phone}
										onChange={(val) => {
											if (val && val.length > 0) {
												let formattedPhone = val.split("");
												formattedPhone.unshift("+");
												setPhone(formattedPhone.join(""));
											}
										}}
									/>
								</div>
							</div>
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
										htmlFor="grid-city">
										User role:
									</label>
									<div className="relative">
										<select
											value={role}
											onChange={(e) => setRole(parseInt(e.target.value))}
											className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state">
											<option value={1}>Customer</option>
											<option value={2}>Team member</option>
											<option value={3}>Manager</option>
											<option value={4}>Admin</option>
										</select>
										<SelectPointer />
									</div>
								</div>
								<div className="w-full md:w-1/2 px-3">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
										htmlFor="grid-phone">
										Department
									</label>
									<Select
										styles={{
											backgroundColor: "#d6d8db",
										}}
										onChange={(val) => {
											setDepartment(val.value);
											setDepartmentDefault(val);
										}}
										value={departmentDefault}
										options={departmentOptions}
									/>
								</div>
							</div>
							<div className="mt-6">
								<button
									type="submit"
									className="bg-orange-500 h-8 hover:bg-orange-700 text-white  text-xs py-1 px-2 rounded w-1/5">
									{saving ? "Saving..." : `Save`}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	allDepartments: state.users.departments,
});

export default connect(mapStateToProps, { getDepartments, editUser, getAllUsers })(EditUser);
