import { AddCircleOutlineOutlined, Delete, InfoTwoTone } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import SelectPointer from "../../components/commons/SelectPointer";
import {
	createTask,
	getTasks,
	getOverdueTasks,
	getTodayTasks,
	getRecentTasks,
	getDoneTasks,
} from "../../redux/actions/task.actions";
import { getAllUsers } from "../../redux/actions/users.actions";
import "react-datepicker/dist/react-datepicker.css";
const AddTask = (props) => {
	const {
		getAllUsers,
		users,
		createTask,
		modalOpen,
		getTasks,
		getOverdueTasks,
		getTodayTasks,
		getRecentTasks,
	} = props;
	const { addToast } = useToasts();
	const [saving, setSaving] = useState(false);
	const [userOptions, setUserOptions] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [selectedAssignee, setSelectedAssignee] = useState([]);

	/**
	 * @desciption: change selected assignee
	 */
	const changeSelectedUser = (index, user) => {
		let copyTasks = [...tasks];
		copyTasks[index].assignedTo = user;
		setTasks(copyTasks);
	};

	const addAnotherTask = () => {
		let tempTasks = [...tasks];
		let credentials = JSON.parse(sessionStorage.getItem("_ark_task"));
		let tempSelectedAssignee = [...selectedAssignee];
		tempSelectedAssignee.push({
			label: `${credentials?.user?.firstName} ${credentials?.user?.lastName}`,
			value: credentials?.user?._id,
		});
		setSelectedAssignee(tempSelectedAssignee);
		tempTasks.push({
			title: "",
			assignedTo: credentials?.user?._id,
			attachments: [],
			dueAt: new Date(),
			type: 0,
		});
		setTasks(tempTasks);
	};

	useEffect(() => {
		onGetAllUsers();
		return;
	}, []);

	const onGetAllUsers = () => {
		getAllUsers(1000);
	};

	const onCreateTask = async (e) => {
		e.preventDefault();
		setSaving(true);
		let taskDetails = {
			tasks: tasks,
		};
		let task = await createTask(taskDetails);
		if (task?.success) {
			addToast(`New task created`, { appearance: "success" });
			setSaving(false);
			getOverdueTasks();
			getTasks();
			getTodayTasks();
			setTimeout(() => {
				modalOpen(false);
			}, 1000);
		} else {
			addToast(task?.message, { appearance: "error" });
			setSaving(false);
		}
	};

	const editTask = (index, label, value) => {
		let tempTasks = [...tasks];
		tempTasks[index][label] = value;
		setTasks(tempTasks);
	};

	const onDeleteTask = (index) => {
		let tempTasks = [...tasks];
		let tempSelectedAssignee = [...selectedAssignee];
		tempSelectedAssignee.splice(index, 1);
		setSelectedAssignee(tempSelectedAssignee);
		tempTasks.splice(index, 1);
		setTasks(tempTasks);
	};

	useEffect(() => {
		let copyUserOptions = [];
		if (users.users && users.users.length > 0) {
			users.users.map((user) => {
				copyUserOptions.push({
					value: user._id,
					label: `${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName : ""}`,
				});
			});
			setUserOptions(copyUserOptions);
			let credentials = JSON.parse(sessionStorage.getItem("_ark_task"));
			let tempSelectedAssignee = [...selectedAssignee];
			tempSelectedAssignee.push({
				label: `${credentials?.user?.firstName} ${credentials?.user?.lastName}`,
				value: credentials?.user?._id,
			});
			setSelectedAssignee(tempSelectedAssignee);
			if (credentials && credentials.user) {
				setTasks([
					{
						assignedTo: credentials.user._id,
						attachments: [],
						dueAt: new Date(),
						type: 0,
						title: "",
					},
				]);
			}
		}
	}, [users]);

	const changeSelectedAssignee = (val, index) => {
		let tempSelectedAssignee = [...selectedAssignee];
		tempSelectedAssignee.splice(index, 1, val);
		setSelectedAssignee(tempSelectedAssignee);
	};

	return (
		<form onSubmit={(e) => onCreateTask(e)}>
			<p className="text-xl mt-4">Create a new task</p>
			<div className="mt-4">
				{tasks && tasks.length > 0
					? tasks?.map((task, index) => {
							return (
								<div key={index}>
									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-first-name">
												Title:
											</label>
											<input
												required
												value={task.title}
												onChange={(e) => editTask(index, "title", e.target.value)}
												className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
												type="text"
												placeholder="Include a title for this task"
											/>
										</div>
									</div>

									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full md:w-1/3 px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-code">
												Task code:
											</label>
											<div className="relative text-xs">
												<select
													required
													value={task.type}
													onChange={(e) => editTask(index, "type", parseInt(e.target.value))}
													className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="grid-code">
													<option value={0}>General (default)</option>
													<option value={1}>Request for quote (RFQ)</option>
													<option value={2}>Design request</option>
													<option value={3}>Proposal</option>
													<option value={4}>Follow up</option>
													<option value={5}>Other</option>
												</select>
												<SelectPointer />
											</div>
										</div>
									</div>
									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full md:w-1/2 px-3">
											<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
												Assignee:
											</label>
											<Select
												value={selectedAssignee[index]}
												onChange={(val) => {
													changeSelectedAssignee(val, index);
													changeSelectedUser(index, val.value);
												}}
												options={userOptions}
											/>
										</div>

										<div className="w-full md:w-1/3 px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-date">
												Due date:
											</label>
											<div className="relative">
												<DatePicker
													wrapperClassName="customize-date"
													className="customize-date"
													dateFormat="yyyy-MM-dd h:mm aa"
													selected={task.dueAt}
													showTimeSelect={true}
													onChange={(date) => editTask(index, "dueAt", date)}
												/>
											</div>
										</div>
									</div>
									<hr className="border-gray-500 border-dashed my-4" />
									{index > 0 && (
										<span onClick={() => onDeleteTask(index)} className="cursor-pointer text-red-500">
											<Delete />
										</span>
									)}
								</div>
							);
					  })
					: ""}

				<div className=" -mx-3 px-3 mb-3 mt-6 text-orange-600">
					<span onClick={() => addAnotherTask()} className="flex cursor-pointer">
						<AddCircleOutlineOutlined />
						Add another task
					</span>
				</div>
				<div className="mt-4">
					<button
						type="submit"
						disabled={saving}
						className="bg-orange-500 hover:bg-orange-700 w-1/2 text-white  text-xs py-2 px-4 rounded">
						{!saving ? "Create new task" : "loading..."}
					</button>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => ({
	users: state.users.allUsers,
});

export default connect(mapStateToProps, {
	getAllUsers,
	createTask,
	getTasks,
	getOverdueTasks,
	getTodayTasks,
	getRecentTasks,
})(AddTask);
