import React, { useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import CustomFormLabel from "../../components/commons/FormLabel";
import SelectPointer from "../../components/commons/SelectPointer";
import CustomCheckbox from "../../components/commons/custom-checkbox/custom-checkbox";
import { Delete, AddCircleOutlineOutlined } from "@material-ui/icons";
import { createProducts, getAllProducts } from "../../redux/actions/products.actions";

const AddProducts = (props) => {
	const { createProducts, modalOpen, getAllProducts } = props;
	const { addToast } = useToasts();
	const [quantityOptions, setQuantityOptions] = useState([]);
	const [category, setCategory] = useState(1);
	const [workFlow, setWorkFlow] = useState([1]);
	const [name, setName] = useState("");
	const [savingProduct, setSavingProducts] = useState(false);

	const addRemoveQuantityOption = (value) => {
		let temQuantityOptions = [...quantityOptions];
		if (temQuantityOptions.includes(value)) {
			let filterOptions = temQuantityOptions.filter((opt) => opt !== value);
			setQuantityOptions(filterOptions);
		} else {
			temQuantityOptions.push(value);
			setQuantityOptions(temQuantityOptions);
		}
	};

	const addWorkFlow = () => {
		let tempWorkFlow = [...workFlow];
		tempWorkFlow.push(1);
		setWorkFlow(tempWorkFlow);
	};

	const deleteWorkFlow = (index) => {
		let tempWorkFlow = [...workFlow];
		tempWorkFlow.splice(index, 1);
		setWorkFlow(tempWorkFlow);
	};

	const editWorkFlow = (index, value) => {
		let tempWorkFlow = [...workFlow];
		tempWorkFlow.splice(index, 1, value);
		setWorkFlow(tempWorkFlow);
	};

	const onCreateProduct = async () => {
		setSavingProducts(true);
		let productDetails = {
			name,
			category,
			workflow: workFlow,
			options: quantityOptions,
		};
		let create = await createProducts(productDetails);
		if (create.success) {
			addToast("New product Created", { appearance: "success" });
			setSavingProducts(false);
			getAllProducts(1, 20);
			setTimeout(() => {
				modalOpen(false);
			}, 2000);
		} else {
			setSavingProducts(false);
			addToast("Enter all required details", { appearance: "error" });
		}
	};

	return (
		<>
			<h3 className="text-gray-900 font-bold">Create a new Product</h3>
			<p className="text-xs text-gray-600">Enter product details below:</p>
			<div className="flex flex-wrap -mx-3 mb-3 mt-6">
				<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
					<CustomFormLabel label={"Product name"} />
					<input
						value={name}
						onChange={(e) => setName(e.target.value)}
						className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
						id="grid-first-name"
						type="text"
						placeholder="Enter product name"
					/>
				</div>
				<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
					<CustomFormLabel label={"Category"} />
					<div className="relative">
						<select
							onChange={(e) => setCategory(parseInt(e.target.value))}
							className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-state"
							value={category}>
							<option value={1}>Business Stationary</option>
							<option value={2}>Marketing Material</option>
							<option value={3}>Clothing & Apparel</option>
							<option value={4}>Labels & Packaging</option>
							<option value={5}>Publications</option>
							<option value={6}>Photo products & Art</option>
							<option value={7}>Promotional Products</option>
							<option value={8}>Signs & Display</option>
							<option value={9}>Other</option>
						</select>
						<SelectPointer />
					</div>
				</div>
			</div>

			<div className=" -mx-3 px-3 mb-3 mt-6">
				<CustomFormLabel label={"quantity options:"} />
				<div className="lg:flex ">
					{/* <CustomCheckbox
						label={"Manual input"}
						oncheck={addRemoveQuantityOption}
						checked={quantityOptions.includes("Manual input")}
					/> */}
					<div className="flex mr-4 mt-3">
						<div onClick={() => addRemoveQuantityOption(0)} className="round">
							<input onChange={() => null} type="checkbox" checked={quantityOptions.includes(0)} />
							<label htmlFor="checkbox"></label>
						</div>
						<span className="mx-8 text-sm font-bold">Manual input</span>
					</div>
					<CustomCheckbox
						label={"25"}
						oncheck={addRemoveQuantityOption}
						checked={quantityOptions.includes(25)}
					/>
					<CustomCheckbox
						label={"50"}
						oncheck={addRemoveQuantityOption}
						checked={quantityOptions.includes(50)}
					/>
					<CustomCheckbox
						label={"100"}
						oncheck={addRemoveQuantityOption}
						checked={quantityOptions.includes(100)}
					/>
					<CustomCheckbox
						label={"200"}
						oncheck={addRemoveQuantityOption}
						checked={quantityOptions.includes(200)}
					/>
					<CustomCheckbox
						label={"500"}
						oncheck={addRemoveQuantityOption}
						checked={quantityOptions.includes(500)}
					/>
					<CustomCheckbox
						label={"1000"}
						oncheck={addRemoveQuantityOption}
						checked={quantityOptions.includes(1000)}
					/>
				</div>
			</div>

			<div className=" -mx-3 px-3 mb-3 mt-6">
				<CustomFormLabel label={"workflow:"} />
				{workFlow.map((flow, i) => {
					return (
						<div key={i} className="flex mt-3">
							<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-1">
								<div className="relative">
									<select
										value={flow}
										onChange={(e) => editWorkFlow(i, parseInt(e.target.value))}
										className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-state">
										<option value={1}>Printing</option>
										<option value={2}>Pasting</option>
										<option value={3}>Cutting</option>
										<option value={4}>Laminating</option>
										<option value={5}>Binding</option>
										<option value={6}>Embossing</option>
										<option value={7}>Foil Stamping</option>
										<option value={8}>Perfortaing</option>
										<option value={9}>Die-cutting</option>
										<option value={10}>Numbering</option>
										<option value={11}>Corner Rounding</option>
										<option value={12}>Folding</option>
										<option value={13}>Collating</option>
										<option value={14}>Creasing</option>
										<option value={15}>Punching</option>
										<option value={16}>Gluing</option>
										<option value={17}>Quality Control</option>
										<option value={18}>Dispatched</option>
										<option value={19}>Weeding</option>
										<option value={20}>Heat Pressing</option>
										<option value={21}>Plotting</option>
										<option value={22}>Sewing</option>
										<option value={23}>Installing</option>
										<option value={24}>Mounting</option>
									</select>
									<SelectPointer />
								</div>
							</div>
							{i !== 0 && (
								<span onClick={() => deleteWorkFlow(i)} className="mt-3 cursor-pointer">
									<Delete />
								</span>
							)}
						</div>
					);
				})}
			</div>

			<div className=" -mx-3 px-3 mb-3 mt-6 text-orange-600">
				<span onClick={() => addWorkFlow()} className="flex cursor-pointer">
					<AddCircleOutlineOutlined />
					Add new step
				</span>
			</div>
			<div className="mt-6">
				<button
					onClick={() => onCreateProduct()}
					className="bg-orange-500 hover:bg-orange-700 text-white font-bold text-xs py-2 px-4 rounded">
					{!savingProduct ? "Create new product" : "Saving..."}
				</button>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	products: state.products.products,
});

export default connect(mapStateToProps, { createProducts, getAllProducts })(AddProducts);
