import { format } from "date-fns/esm";

export const getGreeting = () => {
	let currentDate = new Date();
	let formatted = format(currentDate, "bbbb");
	switch (formatted) {
		case "a.m.":
			return "Good Morning";
		case "noon":
			return "Good day";
		case "p.m.":
			return "Good Evening";
		case "midnight":
			return "Good morning";
		default:
			break;
	}
};
