import React from "react";
import { createBrowserHistory } from "history";
import PrivateRoute from "../route-config/Private-route";
import PublicRoute from "../route-config/Public-route";
import { Router, Switch } from "react-router-dom";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import Dashboard from "../pages/dashboard/Dashboard";
import Leads from "../pages/leads/Leads";
import Customers from "../pages/customers/Customers";
import Products from "../pages/products/Products";
import Orders from "../pages/orders/Orders";
import ViewProduct from "../pages/products/ViewProduct";
import ViewCustomer from "../pages/customers/ViewCustomer";
import ViewLead from "../pages/leads/ViewLead";
import ViewOrder from "../pages/orders/ViewOrder";
import ProfilePage from "../pages/profile-page/ProfilePage";
import ForgotPassword from "../pages/auth/ForgotPassword";
import PasswordReset from "../pages/auth/PasswordReset";
import Users from "../pages/users/Users";
import NotFound from "../pages/NotFound";
import Departments from "../pages/departments/Departments";

const history = createBrowserHistory();

const Routes = () => (
	<Router history={history}>
		<Switch>
			<PublicRoute exact path="/" component={Login} />
			<PublicRoute exact path="/signup" component={Signup} />
			<PrivateRoute exact path="/dashboard/:tab" component={Dashboard} />
			<PrivateRoute exact path="/leads" component={Leads} />
			<PrivateRoute exact path="/users" component={Users} />
			<PrivateRoute exact path="/leads/lead/:id" component={ViewLead} />
			<PrivateRoute exact path="/customers" component={Customers} />
			{/* <PrivateRoute component={NotFound} /> */}
			<PrivateRoute exact path="/customers/customer/:id" component={ViewCustomer} />
			<PrivateRoute exact path="/products" component={Products} />
			<PrivateRoute exact path="/products/product/:id" component={ViewProduct} />
			<PrivateRoute exact path="/orders" component={Orders} />
			<PrivateRoute exact path="/orders/order/:id" component={ViewOrder} />
			<PrivateRoute exact path="/orders/order/:id" component={ViewOrder} />
			<PrivateRoute exact path="/profile" component={ProfilePage} />
			<PrivateRoute exact path="/departments" component={Departments} />
			<PublicRoute exact path="/forgot-password" component={ForgotPassword} />
			<PublicRoute exact path="/reset-password" component={PasswordReset} />
		</Switch>
	</Router>
);

export default Routes;
