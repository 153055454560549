import React from "react";
import { GroupOutlined, AttachFileOutlined } from "@material-ui/icons";
import { BranchIcon } from "../../../../assets/Svg";
import DateButton from "../../../../components/commons/Date-button";
import StatusButton from "../../../../components/commons/StatusButton";
import { Link } from "react-router-dom";

const OrdercardItem = ({ order }) => {
	return (
		<div className="card-tem mt-6">
			<div className="order-title flex">
				{order?.source === "ARK" && (
					<img
						className="w-8 h-8 mx-2 rounded-full mr-4"
						src={"https://res.cloudinary.com/w3bh4ck/image/upload/v1600259673/ark/ark-logo-dark.png"}
						alt={order.customer.name}
					/>
				)}
				{order?.source === "" ? (
					<img
						className="w-8 h-8 mx-2 rounded-full mr-4"
						src={"https://res.cloudinary.com/w3bh4ck/image/upload/v1600259673/ark/ark-logo-dark.png"}
						alt={order.customer.name}
					/>
				) : (
					""
				)}
				{order?.source === "247PRINTS" && (
							<img
								className="w-8 h-8 mx-2 rounded-full mr-4"
								src={"/uploads/247prints-logo.png"}
								alt={order.customer.name}
							/>
						)}
				{order?.source === "WIICREATE" && (
					<img
						className="w-8 h-8 mx-2 rounded-full mr-4"
						src="/uploads/wii_icon.png"
						alt={order.customer.name}
					/>
				)}
				{order?.source === "PROMOAFRIQUE" && (
					<img
						className="w-8 h-8 mx-2 rounded-full mr-4"
						src="/uploads/promo_afrique.png"
						alt={order.customer.name}
					/>
				)}
				<Link to={`/orders/order/${order._id}`}>
					<p className="text-xs font-bold mx-3 my-auto hover:text-orange-400 cursor-pointer">
						{order.title}
					</p>
				</Link>
			</div>
			<div className="flex mt-3 px-2 justify-between">
				<span className="text-xs text-gray-700">
					<GroupOutlined /> <span>{order.customer.name}</span>
				</span>
				<div className="flex justify-between">
					<span className="text-xs cursor-pointer">
						<AttachFileOutlined />
					</span>
					<span className="mx-2 flex text-gray-600 text-xs">
						<BranchIcon /> <span className="mx-1 ">{order.items.length} job cards</span>
					</span>
				</div>
			</div>
			<div className="flex justify-between">
				<span>
					<DateButton order={order} />
				</span>
				<span>
					<StatusButton status={order.status} />
				</span>
			</div>
			<hr className="border-gray-300" />
		</div>
	);
};

export default OrdercardItem;
