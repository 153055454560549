import React, { useState } from "react";
import { StatusIcon } from "../../assets/Svg";
import { AvTimerOutlined, PersonOutline, WorkOutline, LocalPhoneOutlined } from "@material-ui/icons";
import LeadsTableItem from "./LeadsTableItem";
import { connect } from "react-redux";
import { getAllLeads } from "../../redux/actions/leads.actions";

const LeadsTable = (props) => {
	const { allLeads, getAllLeads } = props;
	useState(() => {
		getAllLeads(null, 1000000);
		return;
	}, []);
	return (
		<div className="overflow-scroll bg-white rounded-sm px-3 lg:px-6 py-4 mx-6 lg:mx-8">
			<table className="table-auto min-w-full px-2 ">
				<thead className="">
					<tr className="min-h-24 text-xs text-gray-600 uppercase border-b">
						<th className="px-4 py-2">
							<span className="lg:flex justify-center">
								<PersonOutline />
								<p className="px-2">Contact name</p>
							</span>
						</th>
						<th className="px-4 border-r border-l border-gray-200 py-2">
							<span className="lg:flex justify-center">
								<WorkOutline />
								<p className="px-2">Company</p>
							</span>
						</th>
						<th className="px-4 py-2">
							<span className="lg:flex justify-center">
								<LocalPhoneOutlined />
								<p className="px-2">Phone number</p>
							</span>
						</th>
						<th className="px-4 py-2 border-r  border-gray-200 border-l">
							<span className="lg:flex justify-center">
								<PersonOutline />
								<p className="px-2">Lead source</p>
							</span>
						</th>
						<th className="px-4 py-2 border-r  border-gray-200">
							<span className="lg:flex justify-center">
								<AvTimerOutlined />
								<p className="px-2">Lead created</p>
							</span>
						</th>
						<th className="px-4 py-2 border-r  border-gray-200">
							<span className="lg:flex justify-center">
								<StatusIcon />
								<p className="px-2">Lead status</p>
							</span>
						</th>
						<th className="px-4 py-2">
							<span className="lg:flex justify-center">
								<StatusIcon />
								<p className="px-2">Deal value</p>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					{allLeads.leads && allLeads.leads.length > 0 ? (
						allLeads.leads.map((lead, i) => {
							return <LeadsTableItem key={i} lead={lead} />;
						})
					) : (
						<tr>No lead found</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default connect(null, { getAllLeads })(LeadsTable);
