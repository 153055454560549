export const taskActionTypes = {
	GET_ALL_TASKS: "GET_ALL_TASKS",
	GET_SINGLE_TASK: "GET_SINGLE_TASK",
	GET_OVERDUE_TASK: "GET_OVERDUE_TASK",
	GET_TASKS_COMMENTS: "GET_TASKS_COMMENTS",
	GET_TODAY_TASKS: "GET_TODAY_TASKS",
	GET_UPCOMING_TASK: "GET_UPCOMING_TASK",
	GET_DONE_TASKS: "GET_DONE_TASKS",
	GET_RECENT_TASK: "GET_RECENT_TASK",
};
