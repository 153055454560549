import React, { useState } from "react";
import DateButton from "../../../../components/commons/Date-button";
import StackedImages from "../../../../components/commons/Stacked-images";
import Modal from "react-modal";
import { Print } from "@material-ui/icons";
import ViewJob from "./ViewJob";
import { EditIcon } from "../../../../assets/Svg";
import SwitchJobStatus from "./SwitchJobStatus";
import { downloadJobCard } from "../../../../redux/actions/joblogs.actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "80%",
		height: "850px",
		paddingLeft: "0px",
		paddingRight: "0px",
		paddingBottom: "20px",
		overflow: "scroll",
		zIndex: "2000",
	},
};

const JobLogItem = ({ job, downloadJobCard }) => {
	const [open, setOpen] = useState(false);
	const [downloading, setDownloading] = useState(false);

	const onDownloadcard = async () => {
		setDownloading(job?.items?._id);
		let res = await downloadJobCard(job?._id, job?.items._id);
		// console.log("download", res);
		setDownloading("");
		return res;
	};

	return (
		<tr className="border-b">
			<td className="  border-l-0 px-4 py-2 text-gray-900 text-xs">
				<div onClick={() => setOpen(!open)} className="lg:flex cursor-pointer hover:text-orange-400">
					<span className="py-2 text-xs">{job.shortCode}</span>
				</div>
				<Modal
					isOpen={open}
					onRequestClose={() => setOpen(!open)}
					contentLabel="Modal"
					style={customStyles}>
					<div style={{ zIndex: "10000" }} className="px-0">
						<ViewJob job={job} setOpen={setOpen} />
					</div>
				</Modal>
			</td>
			<td className=" border-l  border-gray-200 border-r px-4 py-2 text-xs  hover:text-orange-400 cursor-pointer ">
				<Link to={`/orders/order/${job._id}`}>{job.title}</Link>
			</td>
			<td className=" border-r  px-4 py-2">
				<span className="py-2 text-xs">{job.items.product.name}</span>
			</td>
			<td className=" border-r  border-gray-200  px-4 py-2">
				<span className="py-2 text-xs">
					{" "}
					{/* <StatusButton status={job.items.status} />{" "} */}
					<SwitchJobStatus job={job} />
				</span>
			</td>
			<td className=" border-r  border-gray-200  px-4 py-2">
				<DateButton job={job} />
			</td>
			<td className=" border-r  border-gray-200 text-center  px-4 py-2">
				<StackedImages assignees={job.assignees} />
			</td>
			<td className=" px-4 py-2 flex text-center">
				<span onClick={() => setOpen(!open)} className="cursor-pointer">
					{" "}
					<EditIcon />{" "}
				</span>{" "}
				<span onClick={() => onDownloadcard()} className="text-red-600 mx-3 cursor-pointer">
					{downloading === job?.items?._id ? "Processing" : <Print />}
				</span>
			</td>
		</tr>
	);
};

export default connect(null, { downloadJobCard })(JobLogItem);
