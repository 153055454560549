import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Add, AttachFileOutlined, GroupOutlined, MoreVert } from "@material-ui/icons";
import { getGreeting } from "../../../../../utils/getGreeting";
import { OrderIcon } from "../../../../../assets/Svg";
import { connect } from "react-redux";
import {
	getAlljobs,
	setOpenModal,
	updateSingleItem,
} from "../../../../../redux/actions/joblogs.actions";
import ViewJob from "../ViewJob";
import DateButton from "../../../../../components/commons/Date-button";
import ViewJobKanban from "../ViewJobKanban";

// const style = {
// 	button: { backgroundColor: "orange" },
// 	text: {
// 		color: "#ffffff",
// 	},
// };

/**
 * @description: status codes for jobs
 */
const STATUS_CODE = {
	recieved: 0,
	preflight: 1,
	inProduction: 2,
	qualityControl: 3,
	ready: 4,
	dispatched: 5,
	onHold: 6,
	cancelled: 7,
	awaitingApproval: 8,
	awaitingDesign: 9,
	awaitingPayment: 10,
	delivered: 11,
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "80%",
		height: "850px",
		paddingLeft: "0px",
		paddingRight: "0px",
		paddingBottom: "20px",
		overflow: "scroll",
	},
};

class JobKanban extends Component {
	constructor() {
		super();
		this.state = {
			greeting: "",
			loading: false,
			recieved: [],
			preflight: [],
			inProduction: [],
			qualityControl: [],
			ready: [],
			dispatched: [],
			onHold: [],
			cancelled: [],
			awaitingApproval: [],
			awaitingDesign: [],
			awaitingPayment: [],
			delivered: [],
			open: "",
		};
	}

	/**
	 * A semi-generic way to handle multiple lists. Matches
	 * the IDs of the droppable container to the names of the
	 * source arrays stored in the state.
	 */
	id2List = {
		recievedColumn: "recieved",
		preflightColumn: "preflight",
		inProductionColumn: "inProduction",
		qualityControlColumn: "qualityControl",
		readyColumn: "ready",
		dispatchColumn: "dispatched",
		onHoldColumn: "onHold",
		cancelledColumn: "cancelled",
		awaitingApprovalColumn: "awaitingApproval",
		awaitingDesignColumn: "awaitingDesign",
		awaitingPaymentColumn: "awaitingPayment",
		deliveredColumn: "delivered",
	};

	getList = (id) => this.state[this.id2List[id]];

	onDragEnd = (result) => {
		const { source, destination } = result;
		const { updateSingleItem } = this.props;
		// dropped outside the list
		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const items = reorder(this.getList(source.droppableId), source.index, destination.index);
			let state = { items };
			let sourceId = source.droppableId;
			this.setState({
				[this.id2List[sourceId]]: state.items,
			});
		} else {
			const result = move(
				this.getList(source.droppableId),
				this.getList(destination.droppableId),
				source,
				destination
			);
			let sourceId = source.droppableId;
			let destinationId = destination.droppableId;
			this.setState({
				[this.id2List[sourceId]]: result[sourceId],
				[this.id2List[destinationId]]: result[destinationId],
			});
			let destinationArray = result[destinationId];
			let destinationStatus = STATUS_CODE[this.id2List[destinationId]];
			destinationArray.map((destJob) => {
				let currentStatus = {
					status: parseInt(destinationStatus),
				};
				updateSingleItem(destJob._id, destJob.items._id, currentStatus);
			});
		}
	};

	componentDidMount() {
		this.setState({
			greeting: getGreeting(),
		});
		this.ongetAllJobs();
	}

	ongetAllJobs = async () => {
		this.setState({
			loading: true,
		});
		let fetchedJobs = await this.props.getAlljobs();
		this.setState({
			loading: false,
		});
		if (fetchedJobs?.success) {
			this.sortFetchedJobs(fetchedJobs.data.jobs);
		}
	};

	sortFetchedJobs = (data) => {
		const {
			recieved,
			preflight,
			inProduction,
			qualityControl,
			ready,
			dispatched,
			onHold,
			cancelled,
			awaitingApproval,
			awaitingDesign,
			awaitingPayment,
			delivered,
		} = this.state;
		if (data && data.length > 0) {
			let tempRecieved = [...recieved];
			let tempPreflight = [...preflight];
			let tempInProduction = [...inProduction];
			let tempQualityControl = [...qualityControl];
			let tempReady = [...ready];
			let tempDispatched = [...dispatched];
			let tempOnHold = [...onHold];
			let tempCancelled = [...cancelled];
			let tempAwaitingApproval = [...awaitingApproval];
			let tempAwaitingDesign = [...awaitingDesign];
			let tempAwaitingPayment = [...awaitingPayment];
			let tempDelivered = [...delivered];
			data.map((log) => {
				if (log.items.status === 0) {
					tempRecieved.push(log);
				} else if (log.items.status === 1) {
					tempPreflight.push(log);
				} else if (log.items.status === 2) {
					tempInProduction.push(log);
				} else if (log.items.status === 3) {
					tempQualityControl.push(log);
				} else if (log.items.status === 4) {
					tempReady.push(log);
				} else if (log.items.status === 5) {
					tempDispatched.push(log);
				} else if (log.items.status === 6) {
					tempOnHold.push(log);
				} else if (log.items.status === 7) {
					tempCancelled.push(log);
				} else if (log.items.status === 8) {
					tempAwaitingApproval.push(log);
				} else if (log.items.status === 9) {
					tempAwaitingDesign.push(log);
				} else if (log.items.status === 10) {
					tempAwaitingPayment.push(log);
				} else if (log.items.status === 11) {
					tempDelivered.push(log);
				}
			});
			this.setState({
				recieved: tempRecieved,
				preflight: tempPreflight,
				inProduction: tempInProduction,
				qualityControl: tempQualityControl,
				ready: tempReady,
				dispatched: tempDispatched,
				onHold: tempOnHold,
				cancelled: tempCancelled,
				awaitingApproval: tempAwaitingApproval,
				awaitingDesign: tempAwaitingDesign,
				awaitingPayment: tempAwaitingPayment,
				delivered: tempDelivered,
			});
		}
	};

	closeModal = () => {
		this.setState({
			open: "",
		});
	};

	render() {
		const {
			open,
			preflight,
			recieved,
			inProduction,
			qualityControl,
			ready,
			dispatched,
			onHold,
			cancelled,
			awaitingApproval,
			awaitingDesign,
			awaitingPayment,
			delivered,
		} = this.state;
		return (
			<div>
				<hr className="border-gray-200" />
				<div className="bg-white pt-4  min-h-screen rounded-md">
					<div className="px-4 md:px-8">
						<section className="mt-6">
							<DragDropContext onDragEnd={this.onDragEnd}>
								<div className="flex gap-2 overflow-x-scroll">
									<Droppable droppableId="recievedColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md m-2  w-64"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Order recieved({recieved.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{/* Task card */}
												{this.state.recieved && this.state.loading === false ? (
													this.state.recieved.map((recievedCard, i) => (
														<Draggable
															key={recievedCard.items._id}
															draggableId={`recievedCard${recievedCard.items._id}`}
															index={i}>
															{(provided, snapshot) => (
																<div
																	className="bg-white mx-3 pb-4 rounded-md mt-4"
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}>
																	<p
																		onClick={() => this.props.setOpenModal(recievedCard.items._id)}
																		className="text-xs text-gray-900 px-4 pt-4">
																		{recievedCard.items?.product?.name}
																	</p>
																	<p className="text-xs text-gray-900 px-4 pt-4">
																		<span className="bold capitalize">Quantity:</span>{" "}
																		{recievedCard.items?.quantity}
																	</p>
																	<div className="flex justify-between">
																		<span className="text-xs flex">
																			<DateButton job={recievedCard} />
																		</span>
																		<span className="text-gray-500 pt-4 px-2">
																			<AttachFileOutlined />
																		</span>
																	</div>

																	<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																		<span className="pr-2">
																			{" "}
																			<GroupOutlined />{" "}
																		</span>
																		<p className="text-xs hover:text-orange-400">
																			<Link to={`/customers/customer/${recievedCard.customer._id}`}>
																				{recievedCard.customer.name}
																			</Link>
																		</p>
																	</div>
																	<div className="px-4 pt-4 flex  text-gray-600 text-xs hover:text-orange-400">
																		<span className="pr-2">
																			{" "}
																			<OrderIcon />{" "}
																		</span>
																		<Link to={`/orders/order/${recievedCard._id}`}>
																			<p className="text-xs">{recievedCard.title}</p>
																		</Link>
																	</div>
																	<Modal
																		isOpen={this.props.openModal === recievedCard.items._id}
																		onRequestClose={this.closeModal}
																		contentLabel="Modal"
																		style={customStyles}>
																		<div className="px-0">
																			<ViewJobKanban
																				targetItem={this.state.open}
																				job={recievedCard}
																				closeModal={this.closeModal}
																			/>
																		</div>
																	</Modal>
																</div>
															)}
														</Draggable>
													))
												) : (
													<p className="px-4 text-sm text-gray-600">Loading...</p>
												)}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="preflightColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Pre-flight({preflight.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.preflight
													? this.state.preflight.map((preflightCard, i) => (
															<Draggable
																key={preflightCard.items._id}
																draggableId={`preflightCard${preflightCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() => this.props.setOpenModal(preflightCard.items._id)}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{preflightCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{preflightCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={preflightCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${preflightCard.customer._id}`}>
																					{preflightCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<Link to={`/orders/order/${preflight._id}`}>
																				<p className="text-xs hover:text-orange-400">
																					{preflightCard.title}
																				</p>
																			</Link>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === preflightCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={preflightCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="inProductionColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														In Production({inProduction.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.inProduction
													? this.state.inProduction.map((inProd, i) => (
															<Draggable
																key={inProd.items._id}
																draggableId={`inProd${inProd.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() => this.props.setOpenModal(inProd.items._id)}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{inProd.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{inProd.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={inProd} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${inProd.customer._id}`}>
																					{inProd.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs hover:text-orange-400">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<Link to={`/orders/order/${inProd._id}`}>
																				<p className="text-xs hover:text-orange-400">{inProd.title}</p>
																			</Link>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === inProd.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={inProd}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="qualityControlColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Quality control({qualityControl.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.qualityControl
													? this.state.qualityControl.map((qualityControlCard, i) => (
															<Draggable
																key={qualityControlCard.items._id}
																draggableId={`qualityControlCard${qualityControlCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() =>
																				this.props.setOpenModal(qualityControlCard.items._id)
																			}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{qualityControlCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{qualityControlCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={qualityControlCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link
																					to={`/customers/customer/${qualityControlCard.customer._id}`}>
																					{qualityControlCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs hover:text-orange-400">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<Link to={`/orders/order/${qualityControlCard._id}`}>
																				<p className="text-xs">{qualityControlCard.title}</p>
																			</Link>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === qualityControlCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={qualityControlCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="readyColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">Ready({ready.length})</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.ready
													? this.state.ready.map((readyCard, i) => (
															<Draggable
																key={readyCard.items._id}
																draggableId={`readyCard${readyCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() => this.props.setOpenModal(readyCard.items._id)}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{readyCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{readyCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={readyCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>

																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${readyCard?.customer._id}`}>
																					{readyCard?.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/orders/order/${readyCard._id}`}>
																					{readyCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === readyCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={readyCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="dispatchColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Dispatched({dispatched.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.dispatched
													? this.state.dispatched.map((dispatchCard, i) => (
															<Draggable
																key={dispatchCard.items._id}
																draggableId={`dispatchCard${dispatchCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() => this.props.setOpenModal(dispatchCard.items._id)}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{dispatchCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{dispatchCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={dispatchCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${dispatchCard.customer._id}`}>
																					{dispatchCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/orders/order/${dispatchCard._id}`}>
																					{dispatchCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === dispatchCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={dispatchCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
									{/* On hold column */}
									<Droppable droppableId="onHoldColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														On-hold({onHold.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.onHold
													? this.state.onHold.map((onHoldCard, i) => (
															<Draggable
																key={onHoldCard.items._id}
																draggableId={`onHoldCard${onHoldCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() => this.props.setOpenModal(onHoldCard.items._id)}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{onHoldCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{onHoldCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={onHoldCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${onHoldCard.customer._id}`}>
																					{onHoldCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/orders/order/${onHoldCard._id}`}>
																					{onHoldCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === onHoldCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={onHoldCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
									<Droppable droppableId="cancelledColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Cancelled({cancelled.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.cancelled
													? this.state.cancelled.map((cancelledCard, i) => (
															<Draggable
																key={cancelledCard.items._id}
																draggableId={`cancelledCard${cancelledCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() => this.props.setOpenModal(cancelledCard.items._id)}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{cancelledCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{cancelledCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={cancelledCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${cancelledCard.customer._id}`}>
																					{cancelledCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/orders/order/${cancelledCard._id}`}>
																					{cancelledCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === cancelledCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={cancelledCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
									<Droppable droppableId="awaitingApprovalColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Awaiting approval({awaitingApproval.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.awaitingApproval
													? this.state.awaitingApproval.map((awaitingApprovalCard, i) => (
															<Draggable
																key={awaitingApprovalCard.items._id}
																draggableId={`awaitingApprovalCard${awaitingApprovalCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() =>
																				this.props.setOpenModal(awaitingApprovalCard.items._id)
																			}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{awaitingApprovalCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{awaitingApprovalCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton awaitingApprovalCard />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link
																					to={`/customers/customer/${awaitingApprovalCard.customer._id}`}>
																					{awaitingApprovalCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/orders/order/${awaitingApprovalCard.customer._id}`}>
																					{awaitingApprovalCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === awaitingApprovalCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={awaitingApprovalCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
									<Droppable droppableId="awaitingDesignColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Awaiting design({awaitingDesign.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.awaitingDesign
													? this.state.awaitingDesign.map((awaitDesignCard, i) => (
															<Draggable
																key={awaitDesignCard.items._id}
																draggableId={`awaitDesignCard${awaitDesignCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() => this.props.setOpenModal(awaitDesignCard.items._id)}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{awaitDesignCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{awaitDesignCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={awaitDesignCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${awaitDesignCard.customer._id}`}>
																					{awaitDesignCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs">
																				<Link to={`/orders/order/${awaitDesignCard._id}`}>
																					{awaitDesignCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === awaitDesignCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={awaitDesignCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="awaitingPaymentColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														awaiting payment({awaitingPayment.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.awaitingPayment
													? this.state.awaitingPayment.map((awaitingPaymentCard, i) => (
															<Draggable
																key={awaitingPaymentCard.items._id}
																draggableId={`awaitingPaymentCard${awaitingPaymentCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<p
																			onClick={() =>
																				this.props.setOpenModal(awaitingPaymentCard.items._id)
																			}
																			className="text-xs text-gray-900 px-4 pt-4">
																			{awaitingPaymentCard.items?.product?.name}
																		</p>
																		<p className="text-xs text-gray-900 px-4 pt-4">
																			<span className="bold capitalize">Quantity:</span>{" "}
																			{awaitingPaymentCard.items?.quantity}
																		</p>
																		<div className="flex justify-between">
																			<span className="text-xs flex">
																				<DateButton job={awaitingPaymentCard} />
																			</span>
																			<span className="text-gray-500 pt-4 px-2">
																				<AttachFileOutlined />
																			</span>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link
																					to={`/customers/customer/${awaitingPaymentCard.customer._id}`}>
																					{awaitingPaymentCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/oders/order/${awaitingPaymentCard.customer._id}`}>
																					{awaitingPaymentCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === awaitingPaymentCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={awaitingPaymentCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>

									<Droppable droppableId="deliveredColumn">
										{(provided, snapshot) => (
											<div
												style={{ backgroundColor: "#F6F6F6", minWidth: "15rem" }}
												className="min-h-screen rounded-md w-64 m-2"
												ref={provided.innerRef}>
												<div className="flex justify-between p-4">
													<span className="uppercase text-xs text-gray-600">
														Delivered({delivered.length})
													</span>
													<span className="flex text-gray-600 text-xs">
														<span className="cursor-pointer">
															<MoreVert />
														</span>
														<span className="ml-2 cursor-pointer"></span>
													</span>
												</div>
												{this.state.delivered
													? this.state.delivered.map((deliveredCard, i) => (
															<Draggable
																key={deliveredCard.items._id}
																draggableId={`deliveredCard${deliveredCard.items._id}`}
																index={i}>
																{(provided, snapshot) => (
																	<div
																		className="bg-white mx-3 pb-4 rounded-md mt-4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<div
																			onClick={() => this.props.setOpenModal(deliveredCard.items._id)}>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				{deliveredCard.items?.product?.name}
																			</p>
																			<p className="text-xs text-gray-900 px-4 pt-4">
																				<span className="bold capitalize">Quantity:</span>{" "}
																				{deliveredCard.items?.quantity}
																			</p>
																			<div className="flex justify-between">
																				<span className="text-xs flex">
																					<DateButton job={deliveredCard} />
																				</span>
																				<span className="text-gray-500 pt-4 px-2">
																					<AttachFileOutlined />
																				</span>
																			</div>
																		</div>

																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<GroupOutlined />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/customers/customer/${deliveredCard.customer._id}`}>
																					{deliveredCard.customer.name}
																				</Link>
																			</p>
																		</div>
																		<div className="px-4 pt-4 flex  text-gray-600 text-xs">
																			<span className="pr-2">
																				{" "}
																				<OrderIcon />{" "}
																			</span>
																			<p className="text-xs hover:text-orange-400">
																				<Link to={`/oders/order/${deliveredCard.customer._id}`}>
																					{deliveredCard.title}
																				</Link>
																			</p>
																		</div>
																		<Modal
																			isOpen={this.props.openModal === deliveredCard.items._id}
																			onRequestClose={this.closeModal}
																			contentLabel="Modal"
																			style={customStyles}>
																			<div className="px-0">
																				<ViewJobKanban
																					targetItem={this.state.open}
																					job={deliveredCard}
																					closeModal={this.closeModal}
																				/>
																			</div>
																		</Modal>
																	</div>
																)}
															</Draggable>
													  ))
													: ""}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</div>
							</DragDropContext>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	allJobs: state.jobLogs.allJobs,
	openModal: state.jobLogs.openModal,
});

export default connect(mapStateToProps, { getAlljobs, updateSingleItem, setOpenModal })(JobKanban);
