import axios from "axios";
import {
	startOfTomorrow,
	endOfYesterday,
	startOfMonth,
	endOfMonth,
	startOfYear,
	endOfYear,
} from "date-fns/esm";
import { baseURL } from "../../utils/base-url";
import { orderActionTypes } from "../action-types/order-action.types";

export const getOrderStats = (filter) => async (dispatch) => {
	let url = "";

	if (filter && filter === "today") {
		let afterYesterday = endOfYesterday().toISOString();
		let beforeTomorrow = startOfTomorrow().toISOString();
		url = `/stats/orders?after=${afterYesterday}&before=${beforeTomorrow}`;
	} else if (filter && filter === "month") {
		let beginningOfMonth = startOfMonth(new Date()).toISOString();
		let monthEnd = endOfMonth(new Date()).toISOString();
		url = `/stats/orders?after=${beginningOfMonth}&before=${monthEnd}`;
	} else if (filter && filter === "year") {
		let beginningOfYear = startOfYear(new Date()).toISOString();
		let yearEnd = endOfYear(new Date()).toISOString();
		url = `/stats/orders?after=${beginningOfYear}&before=${yearEnd}`;
	} else {
		url = `/stats/orders`;
	}

	try {
		let stats = await axios.get(url);
		const { success, data } = stats.data;
		if (success) {
			dispatch({
				type: orderActionTypes.GET_ORDER_STATS,
				payload: data,
			});
			return stats.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const createNewOrder = (orderDetails) => async (dispatch) => {
	let url = "/orders";
	try {
		let order = await axios.put(url, orderDetails);
		const { success } = order.data;
		if (success) {
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const getAllOrders = (page, limit) => async (dispatch) => {
	let url = "";
	if (page && limit) {
		url = `/orders?page=${page}&limit=${limit}`;
	} else {
		url = `/orders?limit=${limit}`;
	}
	try {
		let order = await axios.get(url);
		const { success, data } = order.data;
		if (success) {
			dispatch({
				type: orderActionTypes.GET_ALL_ORDERS,
				payload: data,
			});
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const filterOrders = (filter) => async (dispatch) => {
	let url = "";
	if (filter.length > 0) {
		url = `/orders?limit=100000&status=${filter}`;
	} else {
		url = `/orders?limit=${20}&page=1`;
	}
	try {
		let order = await axios.get(url);
		const { success, data } = order.data;
		if (success) {
			dispatch({
				type: orderActionTypes.GET_ALL_ORDERS,
				payload: data,
			});
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const orderCardFilter = (filter) => async (dispatch) => {
	let url = "";
	if (filter.length > 0) {
		url = `/orders?cardFilter=${filter}`;
	} else {
		url = `/orders?limit=${20}&page=1`;
	}
	try {
		let order = await axios.get(url);
		const { success, data } = order.data;
		if (success) {
			dispatch({
				type: orderActionTypes.GET_ALL_ORDERS,
				payload: data,
			});
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const getAllCustomerOrders = (page, limit, customerId) => async (dispatch) => {
	let url = "";
	if (page && limit) {
		url = `/orders?page=${page}&limit=${limit}&customer=${customerId}`;
	} else {
		url = `/orders?limit=${limit}&customer=${customerId}`;
	}
	try {
		let order = await axios.get(url);
		const { success, data } = order.data;
		if (success) {
			dispatch({
				type: orderActionTypes.GET_CUSTOMER_ORDERS,
				payload: data,
			});
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const searchOrders = (query) => async (dispatch) => {
	let url = "";
	if (query.length > 0) {
		url = `/orders?query=${query}`;
	} else {
		url = `/orders?page=1&limit=200`;
	}
	try {
		let order = await axios.get(url);
		const { success, data } = order.data;
		if (success) {
			dispatch({
				type: orderActionTypes.GET_ALL_ORDERS,
				payload: data,
			});
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const getSingleOrder = (id) => async (dispatch) => {
	let url = `/orders/${id}`;

	try {
		let order = await axios.get(url);
		const { success, data } = order.data;
		if (success) {
			dispatch({
				type: orderActionTypes.GET_SINGLE_ORDER,
				payload: data,
			});
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateOrder = (orderDetails, id) => async (dispatch) => {
	let url = `/orders/${id}`;

	try {
		let order = await axios.patch(url, orderDetails);
		const { success } = order.data;
		if (success) {
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateOrderItems = (orderItems, id) => async (dispatch) => {
	let url = `/orders/${id}/item`;

	try {
		let order = await axios.patch(url, orderItems);
		const { success } = order.data;
		if (success) {
			return order.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateOrderStatus = (id) => async (dispatch) => {
	let url = `/orders/${id}`;
	try {
		let stats = await axios.get(url);
		const { success } = stats.data;
		if (success) {
			return stats.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const attachFileToOrder = (id, file) => async (dispatch) => {
	let url = `/orders/${id}/attachment`;
	try {
		let data = await axios.put(url, file);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const addJobComment = (id, comment) => async (dispatch) => {
	let url = `/comments/job/${id}`;
	try {
		let data = await axios.put(url, comment);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

/**
 * User should be able to delete order
 * @param {*} id
 */
export const deleteOrder = (id) => async (dispatch) => {
	let url = `/orders/${id}`;
	try {
		let data = await axios.delete(url);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

// delete files attached to an order item
export const deleteItemAttachement = (orderId, jobId, details) => async () => {
	let url = `${baseURL}/orders/${orderId}/item/${jobId}/attachment`;
	const request = new Request(url, {
		method: "DELETE",
		headers: new Headers({
			Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("_ark_task")).token,
			Accept: "application/json",
			"Content-Type": "application/json",
		}),
		body: JSON.stringify(details),
	});
	let deleteData = await fetch(request);
	let data = await deleteData.json();
	return data;
};

export const addItemAttachment = (orderId, jobId, details) => async () => {
	let url = `/orders/${orderId}/item/${jobId}/attachment`;
	try {
		let data = await axios.put(url, details);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};
