import React, { useEffect, useState } from "react";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import ProductTable from "./ProductTable";
import { filterProducts, getAllProducts } from "../../redux/actions/products.actions";
import ScreenLoader from "../../components/screen-loader/ScreenLoader";

const Products = (props) => {
	const { getAllProducts, products, filterProducts } = props;
	const [currentPage, setCurrentPage] = useState(1);
	const [loadingProducts, setLoadingProducts] = useState(false);
	const [filterBy, setFilterBy] = useState("");

	const onGetAllProducts = async () => {
		setLoadingProducts(true);
		let allProd = await getAllProducts(currentPage, 20);
		if (allProd && allProd.success) {
			setLoadingProducts(false);
		} else {
			setLoadingProducts(false);
		}
	};

	const onPageChange = async (page) => {
		let allProd = await getAllProducts(page, 20);
		setCurrentPage(page);
		return allProd;
	};

	useEffect(() => {
		onGetAllProducts();
	}, []);

	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			<DashboardNav history={props.history} />
			<div className="mt-10 px-3 lg:px-8 mb-4 md:flex md:justify-between">
				<div>
					<h1 className="text-md font-medium lg:text-2xl">Products</h1>
					<p className="text-xs text-gray-700">Here is a list of all products we offer:</p>
				</div>

				<div className="w-full lg:w-3/6  px-3 mb-6 md:mb-0 flex justify-end">
					<div className="relative mx-1">
						<select
							onChange={(e) => setFilterBy(e.target.value)}
							className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
							id="grid-state">
							<option value="">Filter by</option>
							<option value="category">Category</option>
						</select>
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
							<svg
								className="fill-current h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20">
								<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
							</svg>
						</div>
					</div>
					{filterBy === "category" && (
						<div className="relative">
							<select
								onChange={(e) => filterProducts(e.target.value)}
								className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
								id="grid-state">
								<option value="">Select</option>
								<option value={1}>Business Stationary</option>
								<option value={2}>Marketing Material</option>
								<option value={3}>Clothing & Apparel</option>
								<option value={4}>Labels & Packaging</option>
								<option value={5}>Publications</option>
								<option value={6}>Photo products & Art</option>
								<option value={7}>Promotional Products</option>
								<option value={8}>Signs & Display</option>
								<option value={9}>Other</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20">
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						</div>
					)}
				</div>
			</div>
			{!loadingProducts ? <ProductTable products={products} /> : <ScreenLoader />}
			<div className="mt-6 flex px-6 justify-end ">
				{!loadingProducts && (
					<Pagination
						onChange={(page) => onPageChange(page)}
						defaultCurrent={currentPage}
						total={products.count ? products.count : 0}
						pageSize={20}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	products: state.products.products,
});

export default connect(mapStateToProps, { getAllProducts, filterProducts })(Products);
