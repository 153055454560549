import axios from "axios";
import { baseURL } from "../../utils/base-url";
const { jobLogsActionTypes } = require("../action-types/joblogs-action.types");

export const getAlljobs = (page, limit) => async (dispatch) => {
	let url = "";
	if (page) {
		url = `/jobs?page=${page}&limit=${limit}`;
	} else {
		url = `/jobs?limit=200000`;
	}
	try {
		let stats = await axios.get(url);
		const { success, data } = stats.data;
		if (success) {
			dispatch({
				type: jobLogsActionTypes.GET_ALL_JOBS,
				payload: data,
			});
			return stats.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const searchAlljobs = (query) => async (dispatch) => {
	let url = "";
	if (query.length > 0) {
		url = `/jobs?query=${query}`;
	} else {
		url = `/jobs?page=1&limit=20`;
	}
	try {
		let stats = await axios.get(url);
		const { success, data } = stats.data;
		if (success) {
			dispatch({
				type: jobLogsActionTypes.GET_ALL_JOBS,
				payload: data,
			});
			return stats.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const filterJobLogs = (filterBy) => async (dispatch) => {
	let url = "";
	if (filterBy.length > 0) {
		url = `/jobs?status=${filterBy}`;
	} else {
		url = `/jobs?page=1&limit=20`;
	}
	try {
		let stats = await axios.get(url);
		const { success, data } = stats.data;
		if (success) {
			dispatch({
				type: jobLogsActionTypes.GET_ALL_JOBS,
				payload: data,
			});
			return stats.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const getSingleJob = (orderId, jobId) => async (dispatch) => {
	let url = `/orders/${orderId}/${jobId}`;
	try {
		let stats = await axios.get(url);
		const { success, data } = stats.data;
		if (success) {
			dispatch({
				type: jobLogsActionTypes.GET_SINGLE_JOB,
				payload: data,
			});
			return stats.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateSingleItem = (orderId, jobId, details) => async (dispatch) => {
	let url = `/orders/${orderId}/${jobId}`;
	try {
		let update = await axios.post(url, details);
		const { success } = update.data;
		if (success) {
			return update.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const setOpenModal = (jobId) => async (dispatch) => {
	dispatch({
		type: jobLogsActionTypes.SET_OPEN_MODAL,
		payload: jobId,
	});
};

export const addJobAttachment = (jobId, orderId, file) => async (dispatch) => {
	let url = `/orders/${orderId}/item/${jobId}/attachment`;
	try {
		let res = await axios.put(url, file);
		const { success } = res.data;
		if (success) {
			return res.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateJobItemFiles = (jobId, orderId, file) => async (dispatch) => {
	let url = `/orders/${orderId}/item/${jobId}/files`;
	try {
		let res = await axios.put(url, file);
		const { success } = res.data;
		if (success) {
			return res.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const getJobComments = (orderId, jobId) => async (dispatch) => {
	let url = `/comments/job/${orderId}?jobId=${jobId}`;
	try {
		let res = await axios.get(url);
		const { success, data } = res.data;
		if (success) {
			dispatch({
				type: jobLogsActionTypes.GET_JOB_COMMENTS,
				payload: data,
			});
			return res.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const assignSupervisor = (orderId, assignee) => async (dispatch) => {
	let url = `/orders/${orderId}/assignee`;
	try {
		let res = await axios.put(url, assignee);
		const { success } = res.data;
		if (success) {
			return res.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateWorkflow = (orderId, jobId, workflowDetails) => async (dispatch) => {
	let url = `/orders/${orderId}/${jobId}/workflow`;
	try {
		let res = await axios.post(url, workflowDetails);
		const { success } = res.data;
		if (success) {
			return res.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const deleteOrderFiles = (orderId, jobId, fileId, token) => async (dispatch) => {
	let url = `${baseURL}/orders/${orderId}/item/${jobId}/attachment`;

	// Default options are marked with *
	const response = await fetch(url, {
		method: "DELETE",
		cache: "no-cache",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(fileId),
	});
	return response.json();
};

export const downloadJobCard = (orderId, jobId) => async () => {
	let url = `/orders/${orderId}/${jobId}/print`;
	try {
		let res = await axios({
			url: url,
			method: "GET",
			responseType: "arraybuffer", // important
		});
		if (res) {
			const blob = window.URL.createObjectURL(
				new Blob([res.data], {
					type: "application/pdf",
				})
			);

			const link = document.createElement("a");
			link.href = blob;
			// link.setAttribute("download", "ERP-job-file.pdf");
			// // document.body.appendChild(link);
			link.click();
		}
		return res;
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};
