const { activitiesActionTypes } = require("../action-types/activities-action.types");

const initialState = {
	activities: {},
	announcements: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case activitiesActionTypes.GET_ACTIVITIES:
			return { ...state, activities: action.payload };
		case activitiesActionTypes.GET_ANNOUNCEMENTS:
			return { ...state, announcements: action.payload };
		default:
			return state;
	}
};
