import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
	AttachFileOutlined,
	AvTimerOutlined,
	DeleteForever,
	DeleteOutlined,
	KeyboardBackspaceOutlined,
	QueryBuilderOutlined,
	Send,
} from "@material-ui/icons";

import { useToasts } from "react-toast-notifications";
import { format } from "date-fns";
import Pageloader from "../../../../components/page-loader/Pageloader";
import { EditIcon } from "../../../../assets/Svg";
import SelectPointer from "../../../../components/commons/SelectPointer";
import {
	getTask,
	getTasks,
	updateTask,
	attachTaskFile,
	deleteTaskfile,
	addTaskComment,
	getTaskComments,
	deleteTask,
} from "../../../../redux/actions/task.actions";
import { connect } from "react-redux";
import { getAllUsers, uploadImage } from "../../../../redux/actions/users.actions";
import SingleUserSelect from "../../../../components/commons/SingleUserSelect";
import { formatDistance, formatISO } from "date-fns/esm";
import { getActivities, filteredActivity } from "../../../../redux/actions/activities.actions";
import { Tooltip } from "@material-ui/core";
import { baseURL } from "../../../../utils/base-url";

const ViewTask = (props) => {
	const {
		setViewSingle,
		activeTask,
		getTask,
		users,
		getAllUsers,
		updateTask,
		getActivities,
		getTasks,
		activities,
		attachTaskFile,
		uploadImage,
		deleteTaskfile,
		addTaskComment,
		getTaskComments,
		comments,
		task,
		deleteTask,
		filteredActivity,
	} = props;
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const [savingTask, setSavingTask] = useState(false);
	const [fetchedTask, setFetchedTask] = useState({});
	const [edit, setEdit] = useState(true);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [dueDate, setDueDate] = useState(new Date());
	const [taskCode, setTaskCode] = useState(0);
	const [assignee, setAssignee] = useState("");
	const [uploading, setUploading] = useState(false);
	const [comment, setComment] = useState("");
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (activeTask) {
			loadTask();
			getAllUsers(1000);
			// getActivities(1, 4);
		}
	}, []);

	const loadTask = async (load) => {
		if (load === true) {
			setLoading(true);
		}
		let loadedTask = await getTask(activeTask);
		const { success, data } = loadedTask;
		if (success) {
			setFetchedTask(data);
			setTitle(data.title);
			setDescription(data.description);
			setTaskCode(data.type);
			setAssignee(data.assignedTo);
			setDueDate(new Date(data.dueAt));
			setLoading(false);
			getTaskComments(data._id);
			filteredActivity(data._id);
		}
		setLoading(false);
	};

	const onUpdateTask = async (e) => {
		e.preventDefault();
		setSavingTask(true);
		let taskDetails = {
			title,
			type: taskCode,
			dueAt: formatISO(new Date(dueDate)),
			assignedTo: assignee._id,
		};
		let update = await updateTask(activeTask, taskDetails);
		if (update.success) {
			getTasks();
			addToast(`Task updated`, { appearance: "success" });
			setSavingTask(false);
			filteredActivity(fetchedTask._id);
		} else {
			addToast(update.message, { appearance: "error" });
		}
		setSavingTask(false);
	};

	const onUploadFile = async (file) => {
		setUploading(true);
		const data = new FormData();
		data.append("file", file);
		const config = { headers: { "Content-Type": "multipart/form-data" } };
		let upload = await uploadImage(data, config);
		if (upload.success) {
			// addToast(upload.message, { appearance: "success" });
			let fileDetails = { url: upload.data };
			let attach = await attachTaskFile(activeTask, fileDetails);
			if (attach.success) {
				addToast("File uploaded", { appearance: "success" });
				loadTask();
			} else {
				addToast(attach.data?.message, { appearance: "error" });
			}
			// setProfilePicture(upload.data);
			// let tempUser = user;
			// tempUser.profilePicture = upload.data;
			// setUser(tempUser);
		} else {
			addToast(upload?.message, { appearance: "error" });
		}

		setUploading(false);
	};

	const onDeletFile = async (fileId) => {
		let token = JSON.parse(sessionStorage.getItem("_ark_task")).token;
		let details = {
			attachment: fileId,
		};
		let deleteData = await deleteTaskfile(fetchedTask._id, details, baseURL, token);
		if (deleteData?.success) {
			addToast("File deleted", { appearance: "success" });
			loadTask();
		} else {
			addToast("File could not be deleted", { appearance: "error" });
		}
	};

	const onAddComment = async () => {
		if (comment.length > 0) {
			let commentDetails = {
				message: comment,
			};
			let res = await addTaskComment(fetchedTask._id, commentDetails);
			if (res.success) {
				getTaskComments(fetchedTask._id);
				setComment("");
			}
		}
	};

	const onDeleteTask = async () => {
		setDeleting(true);
		let res = await deleteTask(fetchedTask._id);
		if (res?.success) {
			addToast("Task deleted", { appearance: "success" });
			setTimeout(() => {
				window.location.replace("/dashboard/mytasks");
			}, 1000);
		} else {
			addToast(res?.message, { appearance: "error" });
		}
		setDeleting(false);
	};

	return (
		<div>
			<>
				<div
					onClick={() => setViewSingle(false)}
					className="flex mx-8 mt-4 lg:mt-8 cursor-pointer text-gray-700">
					<KeyboardBackspaceOutlined /> <span>Back</span>
				</div>
				<div className="mt-10 px-3 lg:px-8 mb-4">
					<h1 className="text-md font-medium lg:text-2xl pb-2">{title}</h1>
					<p className="text-xs text-gray-700 bold">
						Date created: {format(new Date(task.createdAt ? task.createdAt : new Date()), "d LLL yyyy")}
					</p>
				</div>
				<div className="lg:flex mx-6 lg:mx-8 mt-4">
					<div
						style={{ height: "600px" }}
						className="lg:w-2/3 w-full mx-2 bg-white rounded-sm px-4 lg:px-8 pb-4">
						<div className="flex justify-between px-2 pt-4">
							<p className="text-l">Task information</p>
							<div className="flex">
								<div
									onClick={() => setEdit(!edit)}
									className="flex text-xs uppercase cursor-pointer text-gray-700">
									<EditIcon /> <span className="pt-1 mx-2">EDIT</span>
								</div>
								<div
									onClick={() => onDeleteTask()}
									className="flex text-xs uppercase cursor-pointer text-red-500">
									<DeleteOutlined />{" "}
									<span className="pt-1 mx-2">{!deleting ? "Delete" : "Deleting..."}</span>
								</div>
							</div>
						</div>
						<hr className="border-gray-200 w-full" />
						{!loading ? (
							<>
								<form onSubmit={(e) => onUpdateTask(e)} className="w-full mt-3 lg:mt-6">
									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full  px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-first-name">
												Title:
											</label>
											<input
												required
												disabled={edit}
												value={title}
												onChange={(e) => setTitle(e.target.value)}
												className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
												id="grid-first-name"
												type="text"
												placeholder="Include a description that explains a bit about this task"
											/>
										</div>
									</div>
									<div className="flex flex-wrap -mx-3 mb-2 mt-6">
										<div className="w-full px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-code">
												Task code:
											</label>
											<div className="relative text-xs">
												<select
													required
													disabled={edit}
													value={taskCode}
													onChange={(e) => setTaskCode(e.target.value)}
													className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="grid-code">
													<option value={0}>General (default)</option>
													<option value={1}>Request for quote (RFQ)</option>
													<option value={2}>Design request</option>
													<option value={3}>Proposal</option>
													<option value={4}>Follow up</option>
													<option value={5}>Other</option>
												</select>
												<SelectPointer />
											</div>
										</div>
									</div>

									<div className="flex flex-wrap -mx-3 mb-6">
										<SingleUserSelect users={users} assignee={assignee} setAssignee={setAssignee} />

										<div className="w-full md:w-1/3 px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-date">
												Due date:
											</label>
											<div className="relative">
												<DatePicker
													disabled={edit}
													wrapperClassName="customize-date"
													className="customize-date"
													dateFormat="yyyy-MM-dd h:mm aa"
													selected={dueDate}
													onChange={(date) => setDueDate(date)}
												/>
											</div>
										</div>
									</div>
									<div className="w-full md:w-1/3 px-3">
										<label
											className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
											htmlFor="grid-date">
											Attachements:
										</label>
										<div style={{ width: "300px" }} className="flex overflow-x-scroll">
											{fetchedTask?.attachments?.length > 0 ? (
												fetchedTask.attachments.map((attachment, i) => {
													return (
														<span key={i} className="mx-2 flex">
															<img
																src={
																	attachment.url.length > 0
																		? attachment.url
																		: "/uploads/profile-placeholder.jpg"
																}
																height="50"
																width="50"
																alt="attach"
															/>
															<Tooltip title={"delete file"}>
																<span
																	onClick={() => onDeletFile(attachment._id)}
																	className="cursor-pointer">
																	<DeleteForever className="text-red-400" fontSize={"small"} />
																</span>
															</Tooltip>
														</span>
													);
												})
											) : (
												<p className="mx-2 text-gray-700 text-xs italic">No file added</p>
											)}
										</div>
										<div className=" -mx-3 px-3 mb-3 mt-6 text-orange-600 text-xs">
											<label className="flex cursor-pointer">
												<AttachFileOutlined />
												<input
													type="file"
													onChange={(e) => onUploadFile(e.target.files[0])}
													className="hidden"
												/>
												Browse to attach files
											</label>
										</div>
									</div>

									{!edit && (
										<div className="mt-6">
											<button
												type="submit"
												className="bg-orange-500 hover:bg-orange-700 text-white  text-xs py-2 px-4 rounded">
												{!savingTask ? "Update task" : "Saving..."}
											</button>
										</div>
									)}
								</form>
							</>
						) : (
							<div className="flex justify-center">
								<Pageloader />
							</div>
						)}
					</div>
					<div className="lg:w-1/3 w-full mx-2">
						<div className="bg-white rounded-sm px-2 lg:px-4 my-3 pb-2">
							<div className="flex justify-between px-2 pt-4 pb-3">
								<p className="text-sm font-bold">Comments</p>
							</div>
							<section className="mt-6 mb-6">
								<p className="capitalize text-gray-900 text-lg">Comments</p>
								<div style={{ overflowY: "scroll", maxHeight: "300px" }} className=" overflow-y-scroll">
									{comments.comments && comments.comments.length > 0 ? (
										comments.comments.map((commentItem, i) => {
											return (
												<div key={i}>
													<div className="md:flex justify-between mt-6">
														<div className="md:flex text-xs">
															<img
																src={"/uploads/profile-placeholder.jpg"}
																className="h-6 w-6 rounded-full"
																alt="profile-image"
															/>
															<span className="mx-2 ">
																{commentItem.createdBy.firstName} {commentItem.createdBy.lastName}
															</span>
														</div>
														<div className="flex text-gray-600 text-xs">
															<QueryBuilderOutlined />
															<span className="mx-1">
																{formatDistance(new Date(commentItem.createdAt), new Date(), {
																	addSuffix: true,
																})}
															</span>
														</div>
													</div>
													<div className="px-8">
														<p className="text-xs text-gray-700">{commentItem.message}</p>
													</div>
												</div>
											);
										})
									) : (
										<p className="text-xs text-gray-700">No comment</p>
									)}
								</div>

								<div className="flex mt-6">
									<span className="pt-2">
										<img
											src={
												JSON.parse(sessionStorage.getItem("_ark_task")) &&
												JSON.parse(sessionStorage.getItem("_ark_task")).user.profilePicture.length > 0
													? JSON.parse(sessionStorage.getItem("_ark_task")).user.profilePicture
													: "/uploads/profile-placeholder.jpg"
											}
											className="h-6 w-6 rounded-full"
											alt="profile-image"
										/>
									</span>
									<input
										className="appearance-none block w-full mx-2 bg-orange-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="email"
										type="text"
										value={comment}
										onChange={(e) => setComment(e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												onAddComment();
											}
										}}
										placeholder="comment..."
									/>
									<span
										onClick={() => onAddComment()}
										onKeyDown={(e) => console.log("enter event", e)}
										className="absolute right-0 pt-3 cursor-pointer mr-12 md:mr-32">
										<Send />
									</span>
								</div>
							</section>
							{/* <p className="font-bold text-xs">Date created:</p>
							<p className="text-xs text-gray-600">date</p> */}
						</div>

						<div className="bg-white rounded-sm px-2 lg:px-4 my-3">
							<div className="flex justify-between px-2 pt-4 pb-3">
								<p className="text-sm font-bold">Recent activities</p>
							</div>
							<hr className="border-gray-300 pb-2" />
							{activities.events
								? activities.events.map((event, i) => {
										if (i < 4) {
											return (
												<div key={i} className="text-xs pb-4 flex justify-between mt-2">
													<div style={{ width: "180px" }}>
														<p className="text-xs font-bold">
															<span dangerouslySetInnerHTML={{ __html: event.description }} />
														</p>
														<p className="text-xs text-gray-600">
															{event.user.firstName} {event.user.lastName}
														</p>
													</div>
													<div className="flex text-gray-600">
														<AvTimerOutlined /> <span></span>
														{formatDistance(new Date(event.createdAt), new Date(), {
															addSuffix: true,
														})}
													</div>
												</div>
											);
										}
								  })
								: ""}
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

const mapStateToProps = (state) => ({
	task: state.tasks.task,
	users: state.users.allUsers,
	activities: state.activities.activities,
	comments: state.tasks.comments,
});

export default connect(mapStateToProps, {
	getTask,
	getAllUsers,
	updateTask,
	getTasks,
	attachTaskFile,
	deleteTaskfile,
	uploadImage,
	getActivities,
	addTaskComment,
	getTaskComments,
	deleteTaskfile,
	filteredActivity,
	deleteTask,
})(ViewTask);
