import React, { useState } from "react";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Cleave from "cleave.js/react";
import { useToasts } from "react-toast-notifications";
import SelectPointer from "../../components/commons/SelectPointer";
import { createNewLead, getAllLeads } from "../../redux/actions/leads.actions";

const AddLeads = (props) => {
	const { createNewLead, modalOpen, getAllLeads } = props;
	const { addToast } = useToasts();
	const [saving, setSaving] = useState(false);
	const [description, setDescription] = useState("");
	const [leadSource, setLeadSource] = useState(1);
	const [leadStatus, setLeadStatus] = useState(0);
	const [dealValue, setDealValue] = useState(0);
	const [company, setCompany] = useState("");
	const [contactName, setContactName] = useState("");
	const [role, setRole] = useState("");
	const [phone, setPhone] = useState("");

	const onCreateLead = async (e) => {
		e.preventDefault();
		setSaving(true);
		let leadDetails = {
			contact: {
				name: contactName,
				context: role,
			},
			description,
			organisation: company,
			phone,
			source: {
				name: contactName,
				context: parseInt(leadSource),
			},
			potentialValue: parseInt(dealValue),
		};
		let createLead = await createNewLead(leadDetails);
		if (createLead.success) {
			setSaving(false);
			modalOpen(false);
			getAllLeads(1, 20);
			addToast("New Lead Created", { appearance: "success" });
		} else {
			addToast(createLead.message, { appearance: "error" });
			setSaving(false);
		}
	};

	return (
		<>
			<h3 className="text-gray-900 ">Create a new lead</h3>
			<p className="text-xs text-gray-600 mt-2">Enter your lead details below:</p>
			<div className="flex justify-center mt-4">
				<form onSubmit={(e) => onCreateLead(e)} className="w-full mt-3">
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								Request details:
							</label>
							<textarea
								required
								rows="4"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-password"
								type="text"
								placeholder="include a description that explains a bit about this opportunity here"
							/>
						</div>
					</div>

					<div className="flex flex-wrap -mx-3 mb-2">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								Lead source:
							</label>
							<div className="relative">
								<select
									value={leadSource}
									onChange={(e) => setLeadSource(e.target.value)}
									className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-state">
									<option value={1}>Employee referral</option>
									<option value={2}>Customer referral</option>
									<option value={3}>Social media</option>
									<option value={4}>Website</option>
									<option value={5}>Paid ads</option>
									<option value={6}>LinkedIn</option>
									<option value={7}>Work-ins</option>
									<option value={8}>Other</option>
									<option value={9}>Unknown</option>
								</select>
								<SelectPointer />
							</div>
						</div>
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-email">
								Lead status:
							</label>
							<div className="relative">
								<select
									value={leadStatus}
									onChange={(e) => setLeadStatus(e.target.value)}
									className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-state">
									<option value={0}>Prospect</option>
									<option value={2}>Contact Made</option>
									<option value={3}>Meeting booked</option>
									<option value={4}>Proposal</option>
									<option value={5}>Awating feedback</option>
									<option value={6}>Negotiating</option>
									<option value={7}>Won deal</option>
									<option value={8}>Lost deal</option>
								</select>
								<SelectPointer />
							</div>
						</div>
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-email">
								Deal value(N):
							</label>
							<Cleave
								value={dealValue}
								onChange={(e) => setDealValue(e.target.value.replace(/[^0-9_.]/g, ""))}
								required
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								placeholder="value"
								options={{
									numeral: true,
									numeralThousandsGroupStyle: "thousand",
									prefix: "NGN ",
								}}
							/>
						</div>
					</div>

					<div className="flex flex-wrap -mx-3 mb-6 mt-6">
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								company
							</label>
							<input
								value={company}
								onChange={(e) => setCompany(e.target.value)}
								required
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-first-name"
								type="text"
								placeholder="Enter client name"
							/>
						</div>
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								contact name
							</label>
							<input
								value={contactName}
								onChange={(e) => setContactName(e.target.value)}
								required
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-last-name"
								type="text"
								placeholder="Who in the company reached out?"
							/>
						</div>
					</div>

					<div className="flex flex-wrap -mx-3 mb-6 mt-6">
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								Role
							</label>
							<input
								value={role}
								onChange={(e) => setRole(e.target.value)}
								required
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-first-name"
								type="text"
								placeholder="Role of contact person"
							/>
						</div>
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-city">
								Phone number
							</label>
							<PhoneInput
								country={"ng"}
								enableSearch={true}
								value={phone}
								onChange={(val) => {
									if (val && val.length > 0) {
										let formattedPhone = val.split("");
										formattedPhone.unshift("+");
										setPhone(formattedPhone.join(""));
									}
								}}
							/>
							{/* <input
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								required
								className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-last-name"
								type="text"
								placeholder="08034494894"
							/> */}
						</div>
						<div className="mt-6 px-4">
							<button
								type="submit"
								className="bg-orange-500 hover:bg-orange-700 text-white  text-xs py-2 px-4 rounded">
								{!saving ? "Create new lead" : "Saving..."}
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default connect(null, { createNewLead, getAllLeads })(AddLeads);
