import React, { useState, useEffect } from "react";
import NavProgressBar from "./NavProgressBar";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import {
	AddCircleOutlineOutlined,
	GroupOutlined,
	SearchOutlined,
	LocalMall,
	AssignmentIndOutlined,
} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import { OrderIcon, CloseIcon } from "../../assets/Svg";
import AddProducts from "../containers/AddProducts";
import AddCustomer from "../containers/AddCustomer";
import AddLeads from "../containers/AddLeads";
import SelectPointer from "../../components/commons/SelectPointer";
import AddTask from "../containers/AddTask";
import AddOder from "../containers/AddOder";
import { connect } from "react-redux";
import { getAllOrders, getOrderStats, searchOrders } from "../../redux/actions/order.actions";
import { searchLeads } from "../../redux/actions/leads.actions";
import { searchCustomers } from "../../redux/actions/customers.actions";
import { searchProducts } from "../../redux/actions/products.actions";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
	},
};

const customTaskStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "70%",
		height: "650px",
		overflow: "auto",
	},
};

const customOrderStyle = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		height: "850px",
		overflow: "auto",
	},
};

const useStyles = makeStyles((theme) => ({
	menuItem: {
		fontSize: "12px",
		"&:hover": {
			backgroundColor: "#f26925",
		},
	},
}));

const DashboardNav = (props) => {
	const {
		activeTab,
		history,
		taskKanban,
		searchLeads,
		searchCustomers,
		setTaskKanban,
		searchOrders,
		searchProducts,
		getAllOrders,
		getOrderStats,
		orderStats,
		allOrders,
	} = props;
	const classes = useStyles();
	const [openAddProduct, setOpenAddProduct] = useState(false);
	const [openAddCustomer, setOpenCustomer] = useState(false);
	const [openLeads, setOpenLeads] = useState(false);
	const [openAddTask, setopenAddTask] = useState(false);
	const [openAddOrder, setOpenAddOder] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSearch = (query) => {
		if (window.location.pathname.includes("orders")) {
			searchOrders(query);
		} else if (window.location.pathname.includes("leads")) {
			searchLeads(query);
		} else if (window.location.pathname.includes("customers")) {
			searchCustomers(query);
		} else if (window.location.pathname.includes("products")) {
			searchProducts(query);
		}
	};

	useEffect(() => {
		getOrderStats();
	}, [allOrders]);

	return (
		<div>
			<div className="container content-center dashboard-nav min-w-full px-8 lg:flex lg:justify-between hidden">
				{history.location.pathname.includes("/dashboard") && (
					<div className="dashboard-nav-links flex space-between ">
						<Link to="/dashboard/overview">
							<div
								className={
									activeTab === "overview"
										? "mx-4 py-5 cursor-pointer active"
										: "mx-4 py-5 cursor-pointer"
								}>
								<span>Overview</span>
							</div>
						</Link>
						<Link to="/dashboard/joblogs">
							<div
								className={
									activeTab === "joblogs"
										? "mx-4 py-5 cursor-pointer active"
										: "mx-4 py-5 cursor-pointer"
								}>
								<span>Job Log</span>
							</div>
						</Link>
						<Link to="/dashboard/mytasks">
							<div
								className={
									activeTab === "mytasks"
										? "mx-4 py-5 cursor-pointer active"
										: "mx-4 py-5 cursor-pointer"
								}>
								<span>My Tasks</span>
							</div>
						</Link>
					</div>
				)}
				{history.location.pathname.includes("/dashboard") && (
					<div className="dashboard-stat ml-32">
						<div className="flex">
							<span className="py-5">
								Completed orders:{" "}
								<span className="done" style={{ color: "#f26925" }}>
									{orderStats?.ready}{" "}
								</span>{" "}
								0f {allOrders.count ? allOrders.count : 0}
							</span>
							<span className="py-5">
								<NavProgressBar ready={orderStats?.ready} total={allOrders?.count} />
							</span>
							<span>
								{/* <FilterNavStat /> */}
								<div className="px-3 py-4 ">
									<div className="relative">
										<select
											onChange={(e) => getOrderStats(e.target.value)}
											className="block appearance-none w-24 text-gray-700 py-1 px-1 pr-8  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state">
											<option value="">All</option>
											<option value="today"> For today</option>
											<option value="month"> This month</option>
											<option value="year">This year</option>
										</select>
										<SelectPointer />
									</div>
								</div>
							</span>
						</div>
					</div>
				)}
				{history.location.pathname.includes("/leads") ||
				history.location.pathname.includes("/customers") ||
				history.location.pathname.includes("/orders") ||
				history.location.pathname.includes("/users") ||
				history.location.pathname.includes("/departments") ||
				history.location.pathname.includes("/products") ? (
					<div className="flex">
						<span className="pt-6">
							<SearchOutlined />
						</span>
						<input
							onChange={(e) => onSearch(e.target.value)}
							className=" border-none  w-full  px-4 text-gray-700  focus:outline-none focus:bg-white focus:border-orange-500"
							type="text"
							placeholder="Search..."
						/>
					</div>
				) : (
					""
				)}
				<div className="dashboard-nav-actions flex flex-end py-5">
					{history.location.pathname.includes("/leads") && taskKanban ? (
						<div className=" px-2 ">
							<div className=" lg:flex lg:justify-end mb-2 pr-4">
								<button
									onClick={() => setTaskKanban(!taskKanban)}
									style={{
										backgroundColor: "#FEF2EC",
										color: "#F26925",
										border: "1px dashed #F26925",
										height: "38px",
										width: "180px",
									}}
									className=" px-4 rounded mb-3 text-xs">
									{taskKanban ? "Switch to list view" : "Switch to board view"}
								</button>{" "}
							</div>
						</div>
					) : (
						""
					)}
					<div
						className="flex-end"
						style={{ color: "#f26925", cursor: "pointer" }}
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClick}>
						<AddCircleOutlineOutlined style={{ color: "#f26925", marginRight: "10px" }} />
						<span>Create new</span>
					</div>

					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						style={{ marginTop: "40px", paddingRight: "10px", fontSize: "12px" }}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}>
						<MenuItem
							onClick={() => {
								setOpenAddOder(!openAddOrder);
								handleClose();
							}}
							className={classes.menuItem}>
							<span className="mx-4">
								<OrderIcon />
							</span>
							Order
						</MenuItem>
						<MenuItem
							onClick={() => {
								setOpenCustomer(!openAddCustomer);
								handleClose();
							}}
							className={classes.menuItem}>
							<span className="mx-4">
								<GroupOutlined />
							</span>
							Customer
						</MenuItem>
						<MenuItem
							onClick={() => {
								setOpenLeads(!openLeads);
								handleClose();
							}}
							className={classes.menuItem}>
							<span className="mx-4">
								<GroupOutlined />
							</span>
							Lead
						</MenuItem>
						<MenuItem
							className={classes.menuItem}
							onClick={() => {
								setOpenAddProduct(!openAddProduct);
								handleClose();
							}}>
							<span className="mx-4">
								<LocalMall />
							</span>
							Product
						</MenuItem>
						<MenuItem
							className={classes.menuItem}
							onClick={() => {
								setopenAddTask(!openAddTask);
								handleClose();
							}}>
							<span className="mx-4">
								<AssignmentIndOutlined />
							</span>
							Task
						</MenuItem>
					</Menu>
				</div>
			</div>

			<Modal
				isOpen={openAddCustomer}
				onRequestClose={() => setOpenCustomer(!openAddCustomer)}
				contentLabel="Modal"
				style={customStyles}>
				<div className="px-4 mb-4">
					<div className="flex justify-end">
						<button onClick={() => setOpenCustomer(!openAddCustomer)}>
							<CloseIcon modalOpen={setOpenCustomer} />
						</button>
					</div>
					<AddCustomer modalOpen={setOpenCustomer} />
				</div>
			</Modal>

			<Modal
				isOpen={openAddProduct}
				onRequestClose={() => setOpenAddProduct(!openAddProduct)}
				contentLabel="Modal"
				style={customStyles}>
				<div>
					<div className="flex justify-end">
						<button onClick={() => setOpenAddProduct(!openAddProduct)}>
							<CloseIcon modalOpen={setOpenAddProduct} />
						</button>
					</div>
					<AddProducts modalOpen={setOpenAddProduct} />
				</div>
			</Modal>

			<Modal
				isOpen={openLeads}
				onRequestClose={() => setOpenLeads(!openLeads)}
				contentLabel="Modal"
				style={customStyles}>
				<div>
					<div className="flex justify-end">
						<button onClick={() => setOpenLeads(!openLeads)}>
							<CloseIcon modalOpen={setOpenLeads} />
						</button>
					</div>
					<AddLeads modalOpen={setOpenLeads} />
				</div>
			</Modal>

			<Modal
				isOpen={openAddTask}
				onRequestClose={() => setopenAddTask(!openAddTask)}
				contentLabel="Modal"
				style={customTaskStyles}>
				<div>
					<div className="flex justify-end">
						<button onClick={() => setopenAddTask(!openAddTask)}>
							<CloseIcon modalOpen={setopenAddTask} />
						</button>
					</div>
					<AddTask modalOpen={setopenAddTask} />
				</div>
			</Modal>
			<Modal
				isOpen={openAddOrder}
				onRequestClose={() => setOpenAddOder(!openAddOrder)}
				contentLabel="Modal"
				style={customOrderStyle}>
				<div>
					<div className="flex justify-between">
						<p className="text-xl mt-4">Create a new order</p>
						<button onClick={() => setOpenAddOder(!openAddOrder)}>
							<CloseIcon modalOpen={setOpenAddOder} />
						</button>
					</div>
					<AddOder modalOpen={setOpenAddOder} />
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	allOrders: state.orders.allOrders,
	orderStats: state.orders.orderStats,
});

export default connect(mapStateToProps, {
	searchOrders,
	searchProducts,
	searchLeads,
	searchCustomers,
	getAllOrders,
	getOrderStats,
})(DashboardNav);
