import React from "react";
import StatusSelect from "./StatusSelect";
import DateButton from "../../../../components/commons/Date-button";
import { connect } from "react-redux";

const MyTaskItem = (props) => {
	const { task, openSingleTask, markAsDone } = props;

	const changeStatus = (id, status) => {
		if (status === 3) {
			markAsDone(id, 2);
		} else {
			markAsDone(id, 3);
		}
	};

	return (
		<tr className="border-t h-16 border-b border-gray-200">
			<td className="px-4 py-2 text-gray-700 text-sm cursor-pointer">
				<span className="flex  hover:text-orange-400">
					<div onClick={() => changeStatus(task._id, task.status)} className="round mr-4">
						<input type="checkbox" checked={task.status === 3} />
						<label htmlFor="checkbox"></label>
					</div>
					<p
						onClick={() => openSingleTask(task._id)}
						className={
							task.status === 3
								? "text-xs text-gray-600 cursor-pointer font-bold mx-3 pt-1 pb-2 line-through"
								: "text-xs cursor-pointer font-bold mx-3 pt-1 pb-2"
						}>
						{task.title}
					</p>
				</span>
			</td>
			<td className=" py-2 text-sm  capitalize">
				<span>
					<DateButton task={task} />
				</span>
			</td>
			<td className=" py-2 text-sm  capitalize text-right">
				<span>
					<StatusSelect task={task} />
				</span>
			</td>
		</tr>
	);
};

export default connect(null, null)(MyTaskItem);
