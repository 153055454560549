import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import { EditIcon, CloseIcon, StatusIcon, OrderIcon } from "../../assets/Svg";
import CustomFormLabel from "../../components/commons/FormLabel";
import SelectPointer from "../../components/commons/SelectPointer";
import {
	Delete,
	AddCircleOutlineOutlined,
	KeyboardBackspaceOutlined,
	PersonOutline,
	AvTimerOutlined,
	DeleteOutlined,
} from "@material-ui/icons";
import Pageloader from "../../components/page-loader/Pageloader";
import {
	getSingleCustomer,
	updateCustomer,
	addCustomerAddress,
	deleteCustomerAddress,
	deleteCustomer,
} from "../../redux/actions/customers.actions";
import Pagination from "rc-pagination";
import { getAllCustomerOrders } from "../../redux/actions/order.actions";
import OrderItem from "../orders/OrderItem";
import { format } from "date-fns/esm";

const customStyles = {
	content: {
		top: "40%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
	},
};
const ViewCustomer = (props) => {
	const { id } = useParams();
	const [loadingOrders, setLoadingOrders] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const {
		getSingleCustomer,
		updateCustomer,
		customer,
		addCustomerAddress,
		deleteCustomerAddress,
		getAllCustomerOrders,
		customerOrders,
		deleteCustomer,
	} = props;
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [orders, setOrders] = useState(0);
	const [name, setName] = useState("");
	const [contacts, setContacts] = useState([]);
	const [savingCustomer, setsavingCustomer] = useState(false);
	const [industry, setIndustry] = useState(1);
	const [addresses, setAddresses] = useState([]);
	const [address, setAddress] = useState("");
	const [openNewAddress, setOpenNewAddress] = useState(false);
	const [companyAnniversary, setcompanyAnniversary] = useState("");
	const [openEditAddress, setOpenEditAddress] = useState("");
	const [deleting, setDeleting] = useState(false);
	useEffect(() => {
		onGetSingleCustomer();
		onGetCustomerOrders();
	}, []);

	const onGetCustomerOrders = async () => {
		setLoadingOrders(true);
		let data = await getAllCustomerOrders(currentPage, 20, id);
		if (data.success) {
			setLoadingOrders(false);
		} else {
			setLoadingOrders(false);
		}
	};

	const deleteContact = (index) => {
		let tempContacst = [...contacts];
		tempContacst.splice(index, 1);
		setContacts(tempContacst);
	};

	const onGetSingleCustomer = async () => {
		if (id) {
			setLoading(true);
			let singleCustomer = await getSingleCustomer(id);
			if (singleCustomer.success) {
				const { data } = singleCustomer;
				setName(data.name);
				setIndustry(data.industry);
				setContacts(data.contacts);
				setcompanyAnniversary(data.anniversary);
				setOrders(data.orders);
				setAddresses(data.addresses);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	const addContact = () => {
		let tempContacts = [...contacts];
		tempContacts.push({ name: "", isPrimary: false, email: "", phone: "" });
		setContacts(tempContacts);
	};

	const editContact = (label, index, value) => {
		const tempContacts = [...contacts];
		tempContacts[index][label] = value;
		setContacts(tempContacts);
	};

	const onChangePrimaryContact = (label, index, value) => {
		const tempContacts = [...contacts];
		tempContacts.map((person, i) => {
			if (i === index) {
				tempContacts[index][label] = value;
			} else {
				person.isPrimary = false;
			}
		});
		setContacts(tempContacts);
	};

	const onChangeAddress = (index, value) => {
		let copyAddresses = [...addresses];
		copyAddresses[index].address = value;
		setAddresses(copyAddresses);
	};

	const onUpdateCustomerAddress = async () => {
		setsavingCustomer(true);
		let copyAddress = [];
		addresses.map((item) => {
			copyAddress.push({ address: item.address });
		});
		let details = { name, industry: parseInt(industry), contacts, addresses: copyAddress };
		if (JSON.stringify(companyAnniversary).length > 0) {
			details.anniversary = companyAnniversary;
		}
		let update = await updateCustomer(id, details);
		if (update.success) {
			addToast("Customer address updated", { appearance: "success" });
			setsavingCustomer(false);
			setOpenEditAddress(false);
		} else {
			addToast(update.message, { appearance: "error" });
			setsavingCustomer(false);
		}
	};

	const onUpdateCustomer = async () => {
		setsavingCustomer(true);
		let customerDetails = {
			name,
			industry: parseInt(industry),
			contacts,
		};
		if (JSON.stringify(companyAnniversary).length > 0) {
			customerDetails.anniversary = companyAnniversary;
		}
		let update = await updateCustomer(id, customerDetails);
		if (update.success) {
			addToast("Customer update", { appearance: "success" });
			setsavingCustomer(false);
		} else {
			addToast(update.message, { appearance: "error" });
			setsavingCustomer(false);
		}
	};

	const onSaveAddress = async () => {
		let newAddress = { address };
		let data = await addCustomerAddress(id, newAddress);
		if (data.success) {
			addToast("New address added", { appearance: "success" });
			onGetSingleCustomer();
			setTimeout(() => {
				setOpenNewAddress(!openNewAddress);
			}, 1000);
		} else {
			addToast(data.message, { appearance: "error" });
		}
	};

	/**
	 * @description: function for deleting folders
	 * @param {*} i id of folder to be deleted
	 */
	const onDeleteAddress = async (i) => {
		let address = {
			address: addresses[i]._id,
		};
		let delAddress = await deleteCustomerAddress(id, address);
		if (delAddress?.success) {
			onGetSingleCustomer();
			addToast("Address deleted", { appearance: "success" });
			getSingleCustomer(id);
		} else {
			addToast(delAddress?.message, { appearance: "error" });
		}
	};

	/**
	 * make a network call on page change
	 */
	const onPageChange = async (page) => {
		let data = await getAllCustomerOrders(page, 20, id);
		setCurrentPage(page);
		return data;
	};

	const onDeleteCustomer = async () => {
		setDeleting(true);
		let res = await deleteCustomer(id);
		if (res?.success) {
			addToast("Customer deleted", { appearance: "success" });
			setTimeout(() => {
				window.location.replace("/customers");
			}, 1000);
		} else {
			addToast(res?.message, { appearance: "error" });
		}
		setDeleting(false);
	};

	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			<DashboardNav history={props.history} />

			<div
				onClick={() => props.history.goBack()}
				className="flex mx-8 mt-4 cursor-pointer text-gray-700">
				<KeyboardBackspaceOutlined /> <span>Back</span>
			</div>
			<div className="mt-10 px-3 lg:px-8 mb-4">
				<h1 className="text-md font-medium lg:text-2xl">{name}</h1>
				<p className="text-xs text-gray-700">
					Created:{" "}
					<span className="text-gray-900">
						{format(new Date(customer.createdAt ? customer.createdAt : new Date()), "do MMMM yyyy")}
					</span>{" "}
					.
				</p>
			</div>
			<div className="lg:flex mx-6 lg:mx-8 mt-4">
				<div
					style={{ minHeight: "400px", overflowY: "auto" }}
					className="lg:w-2/3 w-full mx-2 bg-white rounded-sm px-4 pb-4">
					<div className="flex justify-between px-2 pt-4">
						<p className="text-xl ">Customer information</p>
						<div className="flex">
							<div
								onClick={() => setEdit(!edit)}
								className="flex text-xs uppercase cursor-pointer text-gray-700">
								<EditIcon /> <span className="pt-1 mx-2">EDIT</span>
							</div>
							<div
								onClick={() => onDeleteCustomer()}
								className="flex text-xs uppercase cursor-pointer text-red-500">
								<DeleteOutlined />{" "}
								<span className="pt-1 mx-2">{!deleting ? "Delete" : "Deleting..."}</span>
							</div>
						</div>
					</div>
					<hr className="border-gray-500 w-full" />
					{!loading ? (
						<>
							<div className="flex flex-wrap -mx-3 mb-3 mt-6">
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="grid-city">
										Name
									</label>
									<input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
										id="grid-first-name"
										type="text"
										value={name}
										autocomplete="off"
										onChange={(e) => setName(e.target.value)}
										placeholder="Enter product name"
									/>
								</div>
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<CustomFormLabel label={"Industry"} />
									<div className="relative">
										<select
											value={industry}
											onChange={(e) => setIndustry(e.target.value)}
											className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state">
											<option value={1}> Healthcare, Beauty & Fitness, Technology</option>
											<option value={2}>Financial Services & Insurance</option>
											<option value={3}>FinTechs</option>
											<option value={4}>Food, Beverage & Restaurant</option>
											<option value={5}>FMCGs</option>
											<option value={6}>Government</option>
											<option value={7}>Oil & Gas</option>
											<option value={8}>Travel & Tourism</option>
											<option value={9}>Education & Training</option>
											<option value={10}>Planning & Events Management</option>
											<option value={11}>Religion</option>
											<option value={12}>Logistics & Transportation</option>
											<option value={13}>Fashion & Retail</option>
											<option value={14}>Professional Services: Legal & Accounting</option>
											<option value={15}>Creative Freelancers & Agencies</option>
											<option value={16}>Photography & Lifestyle</option>
											<option value={17}>NGOs & Charities</option>
											<option value={18}>Real Estate & Construction</option>
											<option value={19}>Other</option>
										</select>
										<SelectPointer />
									</div>
								</div>
							</div>
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full px-3">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
										htmlFor="grid-city">
										COMPANY ANNIVERSARY
									</label>
									<div className="relative">
										<DatePicker
											wrapperClassName="customize-date"
											className="customize-date"
											dateFormat="dd/MM/yyyy"
											placeholderText="dd/mm/yyyy"
											selected={companyAnniversary ? new Date(companyAnniversary) : ""}
											onChange={(date) => setcompanyAnniversary(date)}
										/>
									</div>
								</div>
							</div>

							<div className=" flex justify-between -mx-3 px-3 mb-3 mt-4 text-orange-600">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor="grid-city">
									Contact Person
								</label>
								<span onClick={() => addContact()} className="flex cursor-pointer">
									<AddCircleOutlineOutlined />
									Add new contact person
								</span>
							</div>
							<hr className="border-gray-500 border-1" />
							{contacts.map((contact, i) => {
								return (
									<span key={i}>
										<div className="flex flex-wrap -mx-3 mb-2 mt-6">
											<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
												<label
													className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
													htmlFor="grid-city">
													PRIMARY CONTACT PERSON
												</label>
												<input
													className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="grid-city"
													type="text"
													autocomplete="off"
													value={contact.name}
													onChange={(e) => editContact("name", i, e.target.value)}
													placeholder="Contact name"
												/>
											</div>
											<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
												<label
													className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
													htmlFor="grid-email">
													EMAIL ADDRESS
												</label>
												<input
													className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="grid-email"
													type="email"
													autocomplete="off"
													value={contact.email}
													onChange={(e) => editContact("email", i, e.target.value)}
													placeholder="email@example.com"
												/>
											</div>
											<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
												<label
													className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
													htmlFor="grid-phone">
													PHONE NUMBER
												</label>
												<PhoneInput
													country={"ng"}
													enableSearch={true}
													value={contact.phone}
													onChange={(val) => {
														if (val && val.length > 0) {
															let formattedPhone = val.split("");
															formattedPhone.unshift("+");
															editContact("phone", i, formattedPhone.join(""));
														}
													}}
												/>
												{/* <input
													className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="grid-phone"
													type="number"
													autocomplete="off"
													value={contact.phone}
													onChange={(e) => editContact("phone", i, e.target.value)}
													placeholder="09012345672"
												/> */}
											</div>
										</div>
										<div className="flex flex-wrap -mx-3 mb-6">
											<div className="w-full px-3">
												<label
													className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
													htmlFor="grid-city">
													Contact's Birthday
												</label>
												<div className="relative">
													<DatePicker
														wrapperClassName="customize-date"
														className="customize-date"
														dateFormat="dd/MM/yyyy"
														placeholderText="dd/mm/yyyy"
														selected={contact.anniversary ? new Date(contact.anniversary) : ""}
														onChange={(date) => editContact("anniversary", i, date)}
													/>
												</div>
											</div>
										</div>
										<div className="flex">
											{i > 0 && (
												<span onClick={() => deleteContact(i)} className="cursor-pointer text-red-600">
													<Delete />
												</span>
											)}
											<label className="md:w-2/3 block text-gray-500 font-bold ml-4">
												<input
													onChange={(e) => onChangePrimaryContact("isPrimary", i, !contact.isPrimary)}
													className="mr-2 leading-tight p-2"
													type="checkbox"
													checked={contact.isPrimary}
												/>
												<span className="text-sm">Make primary contact</span>
											</label>
										</div>
										<hr className="border-dashed border-gray-300" />
									</span>
								);
							})}

							<div className="mt-6">
								<button
									onClick={() => onUpdateCustomer()}
									className="bg-orange-500 hover:bg-orange-700 text-white font-bold text-xs py-2 px-4 rounded">
									{!savingCustomer ? "Update customer" : "Saving..."}
								</button>
							</div>
						</>
					) : (
						<div className="flex justify-center">
							<Pageloader />
						</div>
					)}
				</div>
				<div className="lg:w-1/3 w-full mx-2">
					<div className="bg-white rounded-sm px-2 lg:px-4 my-3">
						<div className="flex justify-between px-2 pt-4 pb-3">
							<p className="text-sm ">Address Book</p>
						</div>
						<hr className="border-gray-300 pb-2" />
						{addresses && addresses.length > 0 ? (
							addresses.map((addr, i) => {
								return (
									<div key={i} className="text-xs pb-4">
										<p className="py-2">
											<span className="font-bold">
												Primary address {i + 1} - {i === 0 ? "Head office" : ""}{" "}
											</span>
										</p>
										<p className="py-2 text-gray-600">
											<span className="font-bold">{addr.address}</span>
										</p>
										<div className="flex">
											<span onClick={() => setOpenEditAddress(i)} className="flex cursor-pointer">
												<EditIcon /> <span className="pt-1 mx-2">Edit</span>
											</span>
											<span>
												<span
													onClick={() => onDeleteAddress(i)}
													className="cursor-pointer mx-6 text-red-500">
													<Delete /> <span>Delete</span>
												</span>
											</span>
										</div>
										<hr className="border-dashed border-gray-500 mt-2" />
										<Modal
											isOpen={openEditAddress === i}
											onRequestClose={() => setOpenEditAddress(false)}
											contentLabel="Modal"
											style={customStyles}>
											<div className="px-4 mb-4">
												<div className="flex justify-end">
													<button onClick={() => setOpenEditAddress(false)}>
														<CloseIcon />
													</button>
												</div>
												Add address
												<div>
													{/* <textarea className="rounded-md w-full border-gray-500" rows="4" cols="50" /> */}
													<textarea
														className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
														rows="4"
														value={addr.address}
														onChange={(e) => onChangeAddress(i, e.target.value)}
														type="text"
														placeholder="Enter address"
													/>
													<button
														onClick={() => onUpdateCustomerAddress()}
														className="bg-orange-500 hover:bg-orange-700 mt-3 text-white font-bold text-xs py-1 px-4 rounded">
														{savingCustomer ? "Saving..." : `Save Address`}
													</button>
												</div>
											</div>
										</Modal>
									</div>
								);
							})
						) : (
							<p className="mb-4">No address found</p>
						)}
						<span
							onClick={() => setOpenNewAddress(true)}
							className="flex justify-center cursor-pointer capitalize text-orange-600 pb-2">
							<AddCircleOutlineOutlined />
							Add new address
						</span>
					</div>
				</div>
			</div>
			{/* Open new address */}
			<Modal
				isOpen={openNewAddress}
				onRequestClose={() => setOpenNewAddress(!openNewAddress)}
				contentLabel="Modal"
				style={customStyles}>
				<div className="px-4 mb-4">
					<div className="flex justify-end">
						<button onClick={() => setOpenNewAddress(!openNewAddress)}>
							<CloseIcon />
						</button>
					</div>
					Add address
					<div>
						{/* <textarea className="rounded-md w-full border-gray-500" rows="4" cols="50" /> */}
						<textarea
							className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							rows="4"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							type="text"
							placeholder="Enter address"
						/>
						<button
							onClick={() => onSaveAddress()}
							className="bg-orange-500 hover:bg-orange-700 mt-3 text-white font-bold text-xs py-1 px-4 rounded">
							Save Address
						</button>
					</div>
				</div>
			</Modal>

			<section className="mt-4 mx-12">
				<p className="text-gray-700 text-lg">Recent Orders</p>
				<div className=" lg:w-2/3 w-full overflow-scroll bg-white rounded-sm mt-5">
					{!loadingOrders ? (
						<table className="table-auto  px-2 ">
							<thead className="">
								<tr className="min-h-24 text-xs border-b  border-gray-200 text-gray-600 uppercase">
									<th className="px-4 py-2">
										<span className="lg:flex ">
											<OrderIcon />
											<p className="px-2">Order name</p>
										</span>
									</th>

									<th className="px-4 py-2 border-l  border-gray-200">
										<span className="lg:flex ">
											<StatusIcon />
											<p className="px-2">Status</p>
										</span>
									</th>
									<th className="px-4 py-2 border-r  border-gray-200 border-l">
										<span className="lg:flex ">
											<AvTimerOutlined />
											<p className="px-2">Due date</p>
										</span>
									</th>
									<th className="px-4 py-2">
										<span className="lg:flex ">
											<PersonOutline />
											<p className="px-2">Assigned to</p>
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{customerOrders && customerOrders.orders && customerOrders.orders.length > 0 ? (
									customerOrders.orders.map((order, i) => {
										return (
											<OrderItem
												length={customerOrders?.orders?.length}
												order={order}
												index={i}
												key={i}
												forCustomer={true}
											/>
										);
									})
								) : (
									<tr>
										<td className="text-xs italic text-gray-700">
											This customer has not made any order
										</td>
									</tr>
								)}
							</tbody>
						</table>
					) : (
						<p className="text-xs text-gray-700 italic">Loading...</p>
					)}
					<div className="mt-6 flex px-6 justify-end ">
						{!loadingOrders && (
							<Pagination
								onChange={(page) => onPageChange(page)}
								defaultCurrent={currentPage}
								total={customerOrders.count ? customerOrders.count : 0}
								pageSize={20}
							/>
						)}
					</div>
				</div>
			</section>
		</div>
	);
};

const mapStateToProps = (state) => ({
	customer: state.customers.customer,
	customerOrders: state.orders.customerOrders,
});

export default connect(mapStateToProps, {
	getSingleCustomer,
	updateCustomer,
	addCustomerAddress,
	deleteCustomerAddress,
	getAllCustomerOrders,
	deleteCustomer,
})(ViewCustomer);
