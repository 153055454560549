import { leadsActionTypes } from "../action-types/leads-action.types";

const initialState = {
	leadsStat: {},
	allLeads: {},
	lead: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case leadsActionTypes.GET_LEADS_STATS:
			return { ...state, leadsStat: action.payload };
		case leadsActionTypes.GET_ALL_LEADS:
			return { ...state, allLeads: action.payload };
		case leadsActionTypes.GET_SINGLE_LEAD:
			return { ...state, lead: action.payload };
		default:
			return state;
	}
};
