import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AddCircleOutlineRounded, AttachFileOutlined, Delete } from "@material-ui/icons";
import SelectPointer from "../../components/commons/SelectPointer";
import { connect } from "react-redux";
import { getAllProducts } from "../../redux/actions/products.actions";
import { getAllCustomers } from "../../redux/actions/customers.actions";
import Pageloader from "../../components/page-loader/Pageloader";
import { getAllUsers, uploadImage } from "../../redux/actions/users.actions";
import { formatISO } from "date-fns/esm";
import { attachFileToOrder, createNewOrder, getAllOrders } from "../../redux/actions/order.actions";

const AddOder = (props) => {
	const {
		getAllProducts,
		modalOpen,
		allCustomers,
		allProducts,
		getAllCustomers,
		getAllUsers,
		allUsers,
		createNewOrder,
		getAllOrders,
		uploadImage,
	} = props;
	const { addToast } = useToasts();
	const [loadingModal, setLoadingModal] = useState(false);
	const [savingOrder, setSavingOrder] = useState(false);
	const [products, setproducts] = useState([
		{ product: {}, quantity: "", specifications: "", attachments: [] },
	]);
	const [title, setTitle] = useState("");
	const [productOptions, setProductOptions] = useState([]);
	const [userOptions, setAllUserOptions] = useState([]);
	const [customerOptions, setCustomerOptions] = useState([]);
	const [orderCustomer, setOrderCustomer] = useState("");
	const [assignedTo, setAssignedTo] = useState({});
	const [dueDate, setDueDate] = useState(new Date());
	const [priority, setPriority] = useState(0);
	const [uploading, setUploading] = useState(1000);
	const [source, setSource] = useState("Ark");
	const [address, setAddress] = useState("");
	const [useExistingAddress, setUseExistingAddress] = useState(false);
	const [addressOptions, setAddressOptions] = useState([]);
	const [selectedAssignee, setSelectedAssignee] = useState({});

	const addMoreProducts = () => {
		let tempProducts = [...products];
		tempProducts.push({ product: {}, quantity: "", specifications: "", attachments: [] });
		setproducts(tempProducts);
	};

	useEffect(() => {
		let credentials = JSON.parse(sessionStorage.getItem("_ark_task"));
		setSelectedAssignee({
			label: `${credentials?.user?.firstName} ${credentials?.user?.lastName}`,
			value: credentials?.user?._id,
		});
		setAssignedTo(credentials?.user?._id);
		getAllUsers(3000000);
		getAllProducts(1, 200000, true);
		onGetAllCustomers();
	}, []);

	const onGetAllCustomers = async () => {
		setLoadingModal(true);
		let data = await getAllCustomers(1, 200000, true);
		if (data) {
			setLoadingModal(false);
		} else {
			setLoadingModal(false);
		}
	};

	const editProduct = (index, label, value) => {
		let tempProducts = [...products];
		tempProducts[index][label] = value;
		setproducts(tempProducts);
	};

	const onSelectProduct = (id, index) => {
		let tempProducts = [...products];
		if (allProducts.products) {
			allProducts.products.map((prod) => {
				if (prod._id === id) {
					tempProducts[index].product = prod;
					setproducts(tempProducts);
				}
			});
		}
	};

	const onCreateNewOrder = async (e) => {
		e.preventDefault();
		setSavingOrder(true);
		let formattedProducts = [];
		products.map((prod) => {
			formattedProducts.push({
				product: prod.product._id,
				specifications: prod.specifications,
				quantity: prod.quantity,
				attachments: prod.attachments,
			});
		});
		let orderDetails = {
			title,
			customer: orderCustomer,
			assignees: [assignedTo],
			dueAt: formatISO(new Date(dueDate)),
			priority,
			items: formattedProducts,
			source,
			deliveryAddress: address,
		};
		let order = await createNewOrder(orderDetails);
		if (order.success) {
			setSavingOrder(false);
			addToast("New Order Created", { appearance: "success" });
			getAllOrders(1, 20);
			setTimeout(() => {
				modalOpen(false);
			}, 1000);
		} else {
			addToast(order.message, { appearance: "error" });
			setSavingOrder(false);
		}
	};

	const onUploadImage = async (index, file) => {
		setUploading(index);
		const data = new FormData();
		data.append("file", file);
		const config = { headers: { "Content-Type": "multipart/form-data" } };
		let upload = await uploadImage(data, config);
		if (upload.success) {
			addToast(upload.message, { appearance: "success" });
			let tempProducts = [...products];
			tempProducts[index]["attachments"].push({ url: upload.data });
			setproducts(tempProducts);
		} else {
			addToast(upload?.message, { appearance: "error" });
		}
		setUploading("");
	};

	useEffect(() => {
		let copyProductOptions = [];
		if (allProducts.products && allProducts.products.length > 0) {
			allProducts.products.map((prod) => {
				copyProductOptions.push({ value: prod._id, label: prod.name });
			});
			setProductOptions(copyProductOptions);
		}
	}, [allProducts]);

	useEffect(() => {
		let copyAllUserOptions = [];
		if (allUsers.users && allUsers.users.length > 0) {
			allUsers.users.map((user, i) => {
				copyAllUserOptions.push({
					value: user._id,
					label: `${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName : ""}`,
				});
			});
			setAllUserOptions(copyAllUserOptions);
		}
	}, [allUsers]);

	useEffect(() => {
		let copyAllCustomerOptions = [];
		if (allCustomers.customers && allCustomers.customers.length > 0) {
			allCustomers.customers.map((customer, i) => {
				copyAllCustomerOptions.push({
					value: customer._id,
					label: customer.name,
				});
			});
			setCustomerOptions(copyAllCustomerOptions);
		}
	}, [allCustomers]);

	const removeAttachement = (productIndex, itemIndex) => {
		let copyProduct = [...products];
		copyProduct[productIndex].attachments.splice(itemIndex, 1);
		setproducts(copyProduct);
	};

	const renderCustomerAddress = () => {
		if (allCustomers.customers && orderCustomer.length > 0) {
			allCustomers.customers.map((cust) => {
				if (cust._id === orderCustomer) {
					if (cust.addresses) {
						setAddressOptions(cust.addresses);
						return "";
					}
				}
			});
		}
	};

	useEffect(() => {
		renderCustomerAddress();
	}, [orderCustomer]);

	return (
		<>
			{!loadingModal && (
				<form onSubmit={(e) => onCreateNewOrder(e)} className="pt-8 pb-4">
					<div className="flex flex-wrap -mx-3 mb-6 mt-4">
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-first-name">
								Customer name:
							</label>
							<Select onChange={(val) => setOrderCustomer(val.value)} options={customerOptions} />
						</div>
						<div className="w-full md:w-1/2 px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-code">
								Assignee:
							</label>
							<Select
								value={selectedAssignee}
								onChange={(val) => {
									setSelectedAssignee(val);
									setAssignedTo(val.value);
								}}
								options={userOptions}
							/>
						</div>
					</div>

					<div className="flex flex-wrap -mx-3 mb-6 mt-4">
						<div className="w-full  px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-date">
								Order name:
							</label>
							<div className="relative">
								<input
									type="text"
									required
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									className="block appearance-none text-xs w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-date"
									placeholder="Enter order name"
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-wrap -mx-3 mb-6 mt-4">
						<div className="w-full  px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-date">
								Source:
							</label>
							<div className="relative">
								<select
									value={source}
									onChange={(e) => setSource(e.target.value)}
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-state">
									<option value="">select source</option>
									<option value={"Ark"}>Ark</option>
									<option value={"WiiCreate"}>WiiCreate</option>
									<option value="PromoAfrique">PromoAfrique</option>
									<option value="247prints">247 Prints</option>
								</select>
								<SelectPointer />
							</div>
							{/* <div className="relative">
								<input
									type="text"
									required
									value={source}
									onChange={(e) => setSource(e.target.value)}
									className="block appearance-none text-xs w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-date"
									placeholder="Enter order name"
								/>
							</div> */}
						</div>
					</div>
					<div className="flex flex-wrap -mx-3 mb-6 mt-4">
						<div className="w-full  px-3">
							<label
								className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
								htmlFor="grid-date">
								Delivery address:
							</label>
							{!useExistingAddress && (
								<div className="relative">
									<input
										type="text"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
										className="block appearance-none text-xs w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-date"
										placeholder="Enter a delivery address"
									/>
								</div>
							)}
							{useExistingAddress && (
								<div className="relative">
									<select
										value={address}
										onChange={(e) => setAddress(e.target.value)}
										className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-state">
										<option value="">select address</option>
										{addressOptions.map((addr, i) => {
											return <option value={addr.address}>{addr.address}</option>;
										})}
									</select>
									<SelectPointer />
								</div>
							)}
							<span
								onClick={() => setUseExistingAddress(!useExistingAddress)}
								className="text-xs text-orange-400 cursor-pointer">
								{useExistingAddress ? "Input a delivery address" : "Use existing address"}
							</span>
						</div>
					</div>

					<div className="flex flex-wrap -mx-3 mb-6 mt-4">
						<div className="w-full md:w-1/3 px-3">
							<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
								Due date:
							</label>
							<div className="relative">
								<DatePicker
									wrapperClassName="customize-date"
									className="customize-date"
									dateFormat="yyyy-MM-dd h:mm aa"
									selected={dueDate}
									showTimeSelect={true}
									onChange={(date) => setDueDate(date)}
								/>
							</div>
						</div>
						<div className="w-full md:w-1/3 px-3">
							<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
								Priority:
							</label>
							<div className="relative text-xs">
								<select
									value={priority}
									onChange={(e) => setPriority(parseInt(e.target.value))}
									className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
									<option>Select priority</option>
									<option value={0}>Low</option>
									<option value={1}>Medium</option>
									<option value={2}>High</option>
									<option value={3}>Express</option>
								</select>
								<SelectPointer />
							</div>
						</div>
					</div>

					{/* product */}
					{products.length > 0
						? products.map((item, i) => {
								return (
									<div key={i}>
										<hr className="border-dashed  border-gray-300 my-3" />
										<div className="flex flex-wrap -mx-3 mb-6 mt-4">
											<div className="w-full md:w-1/2 px-3">
												<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
													Product:
												</label>
												<Select
													onChange={(val) => onSelectProduct(val.value, i)}
													options={productOptions}
												/>
											</div>
											{products[i].product.hasOwnProperty("_id") ? (
												<div className="w-full md:w-1/4 px-3">
													<label
														className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
														htmlFor="grid-first-name">
														Quantity:
													</label>

													{products[i].product &&
													products[i].product.options &&
													!products[i].product.options.includes(0) ? (
														<div className="relative text-xs">
															<select
																required
																onChange={(e) => editProduct(i, "quantity", parseInt(e.target.value))}
																className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
																id="grid-code">
																<option value={0}>Select quantity</option>
																{products[i].product && products[i].product.options
																	? products[i].product.options.map((opt, i) => {
																			return (
																				<option key={i} value={opt}>
																					{opt}
																				</option>
																			);
																	  })
																	: ""}
															</select>
															<SelectPointer />
														</div>
													) : (
														<div className="relative">
															<input
																type="number"
																required
																onChange={(e) => editProduct(i, "quantity", parseInt(e.target.value))}
																className="block appearance-none text-xs w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
																id="grid-date"
																placeholder="Enter quantity"
															/>
														</div>
													)}
												</div>
											) : (
												""
											)}

											<div className="w-full md:w-1/4 px-3">
												<div className=" -mx-3 px-3 mt-8 text-orange-600 text-xs">
													<label className="flex cursor-pointer">
														<AttachFileOutlined />
														<input
															onChange={(e) => onUploadImage(i, e.target.files[0])}
															type="file"
															className="hidden"
														/>
														{uploading === i ? "processing..." : "Attach files"}
													</label>
												</div>
											</div>
										</div>
										<div className="flex">
											{item.attachments.map((photo, ind) => {
												return (
													<span key={ind} className="flex text-xs">
														<img
															src={photo.url}
															className="rounded mx-2"
															height="40"
															width="40"
															alt="img-prod"
															style={{ height: "40px", width: "40px" }}
														/>

														<Delete
															style={{ cursor: "pointer" }}
															onClick={() => removeAttachement(i, ind)}
														/>
													</span>
												);
											})}
										</div>
										<div className="flex flex-wrap -mx-3 mb-6">
											<div className="w-full px-3">
												<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
													Product Specs:
												</label>
												<textarea
													required
													rows="3"
													onChange={(e) => editProduct(i, "specifications", e.target.value)}
													className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
													type="text"
													placeholder="include a description that explains a bit about this order here, including any link that you will like to share"
												/>
											</div>
										</div>
									</div>
								);
						  })
						: ""}

					<div onClick={() => addMoreProducts()} className=" -mx-3 px-3 mb-3 mt-6">
						<span className="flex cursor-pointer">
							<AddCircleOutlineRounded />
							Add new job
						</span>
					</div>
					<div className="mt-6">
						<button className="bg-orange-500 hover:bg-orange-700 text-white font-bold text-xs py-2 px-4 rounded">
							{!savingOrder ? "Create new order" : "Saving..."}
						</button>
					</div>
				</form>
			)}
			{loadingModal && <Pageloader />}
		</>
	);
};

const mapStateToProps = (state) => ({
	allCustomers: state.customers.customers,
	allProducts: state.products.products,
	allUsers: state.users.allUsers,
});

export default connect(mapStateToProps, {
	getAllProducts,
	getAllCustomers,
	getAllUsers,
	createNewOrder,
	getAllOrders,
	attachFileToOrder,
	uploadImage,
})(AddOder);
