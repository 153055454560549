import React from "react";

export const JobStatusIcon = () => {
	return (
		<svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.66667 15V11.1111M5.66667 8.88889V5M10.1111 15V10M10.1111 7.77778V5M14.5556 15V12.2222M14.5556 10V5M4 11.1111H7.33333M8.44444 7.77778H11.7778M12.8889 12.2222H16.2222"
				stroke="#F26925"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const JobPrintIcon = () => {
	return (
		<svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7 8.5V5H13V8.5M7 13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12V9.5C5 9.23478 5.10536 8.98043 5.29289 8.79289C5.48043 8.60536 5.73478 8.5 6 8.5H14C14.2652 8.5 14.5196 8.60536 14.7071 8.79289C14.8946 8.98043 15 9.23478 15 9.5V12C15 12.2652 14.8946 12.5196 14.7071 12.7071C14.5196 12.8946 14.2652 13 14 13H13M7 11H13V15H7V11Z"
				stroke="#F26925"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
