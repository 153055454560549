import React, { useEffect, useState } from "react";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import UsersTable from "./UsersTable";
import { filterUsers, getAllUsers } from "../../redux/actions/users.actions";
import Pageloader from "../../components/page-loader/Pageloader";

const Users = (props) => {
	const { getAllUsers, allUsers, filterUsers } = props;
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [viewActive, setViewActive] = useState(true);

	useEffect(() => {
		onGetAllUsers();
	}, []);

	const onGetAllUsers = async () => {
		setLoading(true);
		let res = await getAllUsers(20, currentPage);
		if (res.success) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const onPageChange = async (page) => {
		let res = await getAllUsers(20, currentPage);
		setCurrentPage(page);
		return res;
	};

	const onFilter = async (value) => {
		let res = await filterUsers(20000, value);
		if (value === "active") {
			setViewActive(true);
		} else {
			setViewActive(false);
		}
	};

	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			<DashboardNav history={props.history} />
			<div className="mt-10 px-3 lg:px-8 mb-4 md:flex md:justify-between">
				<div>
					<h1 className="text-md font-medium lg:text-2xl">Users</h1>
					<p className="text-xs text-gray-700">Here is a list of users added to ERP:</p>
				</div>
			</div>
			<div className="flex justify-end  mb-2">
				<div style={{ width: "120px" }} className="relative pt-1 mx-8 ">
					<select
						onChange={(e) => onFilter(e.target.value)}
						style={{ height: "38px" }}
						className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none text-xs focus:bg-white focus:border-gray-500"
						id="grid-state">
						<option value="active">Active</option>
						<option value="archived">Archived</option>
					</select>
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
						<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
							<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
						</svg>
					</div>
				</div>
			</div>
			{!loading ? <UsersTable activeView={viewActive} allUsers={allUsers} /> : <Pageloader />}

			<div className="mt-6 flex px-6 justify-end ">
				{!loading && (
					<Pagination
						onChange={(page) => onPageChange(page)}
						defaultCurrent={currentPage}
						total={allUsers.count ? allUsers.count : 0}
						pageSize={20}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	allUsers: state.users.allUsers,
});

export default connect(mapStateToProps, { getAllUsers, filterUsers })(Users);
