import React, { useEffect } from "react";
import Pagination from "rc-pagination";
import { useState } from "react";
import { connect } from "react-redux";
import Pageloader from "../../../../components/page-loader/Pageloader";
import { getActivities } from "../../../../redux/actions/activities.actions";
import { getGreeting } from "../../../../utils/getGreeting";
import ActivityItem from "./ActivityItem";

const AllActivities = (props) => {
	const { getActivities, allActivities } = props;
	const [user, setUser] = useState({});
	const [greeting, setGreeting] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		onGetUser();
		setGreeting(getGreeting());
		onGetActivities();
		return;
	}, []);

	const onGetUser = () => {
		let storage = JSON.parse(sessionStorage.getItem("_ark_task"));
		if (storage) {
			setUser(storage.user);
		}
	};

	const onGetActivities = async () => {
		setLoading(true);
		let data = await getActivities(currentPage, 20);
		setLoading(false);
		return data;
	};

	const onPageChange = async (page) => {
		let data = await getActivities(page, 20);
		setCurrentPage(page);
		return data;
	};

	return (
		<section className="px-3 lg:px-12 pt-6 ">
			<h1 className="text-md font-medium lg:text-2xl">
				{greeting}, {user.firstName ? user.firstName : ""}
			</h1>
			<p className="text-xs text-gray-700">Here is a lit of all the activities:</p>
			<div className=" bg-white rounded-sm px-3 lg:px-6 py-4 mt-8">
				<div>
					<p className="text-lg text-gray-900 ">Activities</p>
					<hr className="border-gray-200 mt-3" />
				</div>
				{!loading ? (
					<>
						{allActivities && allActivities?.events?.length > 0 ? (
							allActivities?.events?.map((event, i) => {
								return (
									<ActivityItem event={event} key={i} lastItem={i + 1 === allActivities.events.length} />
								);
							})
						) : (
							<p>No activity found</p>
						)}
					</>
				) : (
					<Pageloader />
				)}
			</div>
			<div className="mt-6 flex px-6 justify-end ">
				{!loading && (
					<Pagination
						onChange={(page) => onPageChange(page)}
						defaultCurrent={currentPage}
						total={allActivities.count ? allActivities.count : 0}
						pageSize={20}
					/>
				)}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	allActivities: state.activities.activities,
});

export default connect(mapStateToProps, { getActivities })(AllActivities);
