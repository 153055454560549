import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

const CustomerTableItem = (props) => {
	const { customer, primaryContact, email, phoneNumber, numOfOrders, lastOrderDate, id } = props;
	return (
		<tr className="border-t h-16">
			<td className="  border-l-0 px-4 py-2 text-gray-900 text-sm">
				<div className="capitalize">
					<Link to={`/customers/customer/${id}`}>
						<span className="py-2 hover:text-orange-400">{customer}</span>
					</Link>
				</div>
			</td>
			<td className=" border-l border-gray-200 border-r px-4 py-2 text-sm   t capitalize">
				{primaryContact}
			</td>
			<td className=" border-l border-gray-200 border-r px-4 py-2 text-sm ">{email}</td>

			<td className=" border-l border-gray-200 border-r px-4 py-2 text-sm   text-center">
				{phoneNumber}
			</td>
			<td className=" border-l border-gray-200 px-4 py-2 text-sm text-center ">{numOfOrders}</td>
			<td className=" border-l border-gray-200 px-4 py-2 text-sm text-center ">
				{lastOrderDate ? format(new Date(lastOrderDate), "do MMMM yyyy") : "N/A"}
			</td>
		</tr>
	);
};

export default CustomerTableItem;
