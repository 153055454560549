import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import {
	makeStyles,
	List,
	ListSubheader,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
} from "@material-ui/core";
import {
	Settings,
	ExpandLess,
	ExpandMore,
	StarBorder,
	MenuBook,
	SettingsOutlined,
	ListOutlined,
	PeopleAltOutlined,
	ContactsOutlined,
	AddCircleOutlined,
	GroupOutlined,
	LocalMallOutlined,
	AssignmentIndOutlined,
} from "@material-ui/icons";
import { CloseIcon, OrderIcon } from "../../assets/Svg";
import AddCustomer from "../containers/AddCustomer";
import AddOder from "../containers/AddOder";
import AddTask from "../containers/AddTask";
import AddLeads from "../containers/AddLeads";
import AddProducts from "../containers/AddProducts";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
	},
};

const customTaskStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "70%",
		height: "650px",
		overflow: "auto",
	},
};

const useStyles = makeStyles(() => ({
	root: {
		width: "250px",
		backgroundColor: "#ffffff",
		color: "#666666",
	},
	nested: {
		paddingLeft: 4,
	},
	sideLink: {
		fontSize: "12px",
	},
}));

const SideBarList = (props) => {
	const {
		logout,
		setOpendepartment,
		setOpenDrawer,
		setExpandAdd,
		openAddProduct,
		setOpenAddProduct,
		openAddCustomer,
		setOpenCustomer,
		openLeads,
		setOpenLeads,
		openAddTask,
		setopenAddTask,
		openAddOrder,
		setOpenAddOder,
		expandAdd,
	} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	// const [expandAdd, setExpandAdd] = useState(false);
	// const [openAddProduct, setOpenAddProduct] = useState(false);
	// const [openAddCustomer, setOpenCustomer] = useState(false);
	// const [openLeads, setOpenLeads] = useState(false);
	// const [openAddTask, setopenAddTask] = useState(false);
	// const [openAddOrder, setOpenAddOder] = useState(false);
	// const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = () => {
		setOpen(!open);
	};
	return (
		<>
			<List
				component="nav"
				aria-labelledby="nested-list-subheader"
				subheader={
					<ListSubheader component="div" id="nested-list-subheader">
						<MenuBook></MenuBook> Menu
					</ListSubheader>
				}
				className={classes.root}>
				<ListItem button>
					<Link to={`/dashboard/overview`}>
						<ListItemText className={classes.sideLink} primary="Dashboard" />
					</Link>
				</ListItem>
				<ListItem button>
					<Link to={`/dashboard/joblogs`}>
						<ListItemText className={classes.sideLink} primary="Job logs" />
					</Link>
				</ListItem>
				<ListItem button>
					<Link to={`/dashboard/mytasks`}>
						<ListItemText className={classes.sideLink} primary="Tasks" />
					</Link>
				</ListItem>
				<hr />
				<ListItem button>
					<Link to={`/orders`}>
						<ListItemText className={classes.sideLink} primary="Orders" />
					</Link>
				</ListItem>
				<hr />
				<hr />
				<ListItem button>
					<Link to={`/leads`}>
						<ListItemText className={classes.sideLink} primary="Leads" />
					</Link>
				</ListItem>
				<hr />
				<ListItem button>
					<Link to="/customers">
						<ListItemText className={classes.sideLink} primary="Customers" />
					</Link>
				</ListItem>
				<hr />
				<ListItem button>
					<Link to="/products">
						<ListItemText className={classes.sideLink} primary="Products" />
					</Link>
				</ListItem>
				<hr />
				<ListItem button onClick={() => setExpandAdd(!expandAdd)}>
					<ListItemIcon>
						<AddCircleOutlined />
					</ListItemIcon>
					<ListItemText className={classes.sideLink} primary="Create new" />
					{expandAdd ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={expandAdd} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem
							onClick={() => {
								setopenAddTask(!openAddTask);
								setOpenDrawer(false);
							}}
							button
							className={classes.nested}>
							<ListItemIcon>
								<AssignmentIndOutlined />
							</ListItemIcon>
							<ListItemText primary="Task" />
						</ListItem>
						<ListItem
							onClick={() => {
								setOpenAddOder(!openAddOrder);
								setOpenDrawer(false);
							}}
							button
							className={classes.nested}>
							<ListItemIcon>
								<OrderIcon />
							</ListItemIcon>
							<ListItemText primary="Order" />
						</ListItem>
						<ListItem
							onClick={() => {
								setOpenCustomer(!openAddCustomer);
								setOpenDrawer(false);
							}}
							button
							className={classes.nested}>
							<ListItemIcon>
								<GroupOutlined />
							</ListItemIcon>
							<ListItemText primary="Customer" />
						</ListItem>
						<ListItem
							onClick={() => {
								setOpenLeads(!openLeads);
								setOpenDrawer(false);
							}}
							button
							className={classes.nested}>
							<ListItemIcon>
								<GroupOutlined />
							</ListItemIcon>
							<ListItemText primary="Lead" />
						</ListItem>
						<ListItem
							onClick={() => {
								setOpenAddProduct(!openAddProduct);
								setOpenDrawer(false);
							}}
							button
							className={classes.nested}>
							<ListItemIcon>
								<LocalMallOutlined />
							</ListItemIcon>
							<ListItemText primary="Product" />
						</ListItem>
					</List>
				</Collapse>
				<hr />
				<ListItem button onClick={handleClick}>
					<ListItemIcon>
						<Settings />
					</ListItemIcon>
					<ListItemText className={classes.sideLink} primary="Account" />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>

				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem button className={classes.nested}>
							<ListItemIcon>
								<ContactsOutlined />
							</ListItemIcon>
							<Link to="/users">
								<ListItemText primary="View users" />
							</Link>
						</ListItem>
						<ListItem onClick={() => setOpendepartment(true)} button className={classes.nested}>
							<ListItemIcon>
								<PeopleAltOutlined />
							</ListItemIcon>

							<ListItemText primary="Add departments" />
						</ListItem>
						<ListItem button className={classes.nested}>
							<ListItemIcon>
								<ListOutlined />
							</ListItemIcon>
							<Link to="/departments">
								<ListItemText primary="View departments" />
							</Link>
						</ListItem>
						<ListItem button className={classes.nested}>
							<ListItemIcon>
								<SettingsOutlined />
							</ListItemIcon>
							<Link to="/profile">
								<ListItemText primary="Profile" />
							</Link>
						</ListItem>
						<ListItem button className={classes.nested}>
							<ListItemIcon>
								<StarBorder />
							</ListItemIcon>
							<ListItemText onClick={() => logout()} primary="Sign Out" />
						</ListItem>
					</List>
				</Collapse>
			</List>
		</>
	);
};

export default SideBarList;
