import { customersActionTypes } from "../action-types/customers-actions.types";

const initialState = {
	customers: {},
	customer: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case customersActionTypes.GET_ALL_CUSTOMERS:
			return { ...state, customers: action.payload };
		case customersActionTypes.GET_SINGLE_CUSTOMER:
			return { ...state, customer: action.payload };
		default:
			return state;
	}
};
