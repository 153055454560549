import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useToasts } from "react-toast-notifications";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ExpandMore } from "@material-ui/icons";
import { definedTaskStatus, definedTaskStatusColors } from "../../../../utils/definedValues";
import { connect } from "react-redux";
import {
	changeTaskStatus,
	getTasks,
	getOverdueTasks,
	getDoneTasks,
	getTodayTasks,
} from "../../../../redux/actions/task.actions";

const StatusSelect = (props) => {
	const { task, changeTaskStatus, getTasks, getOverdueTasks, getDoneTasks, getTodayTasks } = props;
	const { addToast } = useToasts();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [status, setStatus] = useState(0);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setStatus(parseInt(task.status));
	}, []);

	const style = definedTaskStatusColors[parseInt(task.status)];

	const onChangeStatus = async (state) => {
		setStatus(state);
		let statusDetail = {
			status: state,
		};
		let data = await changeTaskStatus(task._id, statusDetail);
		if (data.success) {
			addToast("Task status updated", { appearance: "success" });
			getTasks();
			getOverdueTasks();
			getDoneTasks();
			getTodayTasks();
		} else {
			addToast(data.message, { appearance: "error" });
		}
	};

	return (
		<div>
			<Button
				aria-controls="simple-menu"
				className="outline-none"
				aria-haspopup="true"
				onClick={handleClick}>
				<span className="text-xs flex" style={style.text}>
					<button
						style={style.button}
						type="button"
						className="py-2 px-4 rounded button-outline m-1 text-xs capitalize outline-none">
						{definedTaskStatus[parseInt(task.status)]}
					</button>{" "}
					<span className="mt-3">
						<ExpandMore />
					</span>
				</span>
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(0);
					}}>
					To-do
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(1);
					}}>
					In progress
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(2);
					}}>
					In review
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(3);
					}}>
					Done
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(4);
					}}>
					On-hold
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(5);
					}}>
					Cancelled
				</MenuItem>
			</Menu>
		</div>
	);
};

export default connect(null, {
	changeTaskStatus,
	getTasks,
	getOverdueTasks,
	getDoneTasks,
	getTodayTasks,
})(StatusSelect);
