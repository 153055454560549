import React from "react";
import { differenceInCalendarDays, format, formatDistance } from "date-fns";
import { differenceInDays, endOfToday, formatDistanceToNow } from "date-fns/esm";

const DateButton = (props) => {
	const { task, order, job } = props;
	const style = {
		button: { backgroundColor: "#1B998B" },
		text: {
			color: "#1B998B",
		},
	};

	const getStyle = (dueDate) => {
		let diff = differenceInDays(new Date(dueDate), new Date());
		if (diff >= 0 && diff < 7) {
			diff = {
				button: { backgroundColor: "#F2994A" },
				text: {
					color: "#F2994A",
				},
			};
		} else if (diff < 0) {
			diff = {
				button: { backgroundColor: "#EB5757" },
				text: {
					color: "#EB5757",
				},
			};
		} else if (diff >= 7) {
			diff = {
				button: { backgroundColor: "#1B998B" },
				text: {
					color: "#1B998B",
				},
			};
		}
		return diff;
	};

	return (
		<>
			{task && (
				<span className="text-xs" style={getStyle(task.dueAt).text}>
					<button
						style={getStyle(task.dueAt).button}
						className="py-2 px-4 rounded button-outline m-3 text-white text-xs cursor-not-allowed">
						{format(new Date(task ? task.dueAt : endOfToday()), "dd, MMM")}
					</button>{" "}
					<span>
						{formatDistanceToNow(new Date(task?.dueAt), {
							addSuffix: true,
						})}
					</span>
				</span>
			)}
			{order && (
				<span className="text-xs" style={getStyle(order.dueAt).text}>
					<button
						style={getStyle(order.dueAt).button}
						className="py-2 px-4 rounded button-outline m-3 text-white text-xs cursor-not-allowed">
						{/* {format(new Date(order?.dueAt), "Lo, MMM")} */}
						{format(new Date(order?.dueAt), "dd, MMM")}
					</button>{" "}
					<span>
						{formatDistanceToNow(new Date(order ? order.dueAt : endOfToday()), {
							addSuffix: true,
						})}
					</span>
				</span>
			)}
			{job && (
				<span className="text-xs" style={getStyle(job.dueAt).text}>
					<button
						style={getStyle(job.dueAt).button}
						className="py-2 px-4 rounded button-outline m-3 text-white text-xs cursor-not-allowed">
						{format(new Date(job ? job.dueAt : endOfToday()), "dd, MMM")}
					</button>{" "}
					<span>
						{formatDistanceToNow(new Date(job?.dueAt), {
							addSuffix: true,
						})}
					</span>
				</span>
			)}
		</>
	);
};

export default DateButton;
