import React from "react";
import { format } from "date-fns";
import { leadSourceValue } from "../../utils/definedValues";
import { Link } from "react-router-dom";
import LeadStatusButton from "../../components/commons/LeadStatusButton";
import { numberWithCommas } from "../../utils/helper-functions";
import SwitchLeadStatus from "./SwitchLeadStatus";

const LeadsTableItem = (props) => {
	const { lead } = props;
	return (
		<tr className="border-b border-gray-200 ">
			<td className="  border-l-0 px-4 py-2 text-gray-900 text-sm ">
				<div className="">
					<Link to={`/leads/lead/${lead._id}`}>
						<span className="py-2 hover:text-orange-400">{lead.contact.name}</span>
					</Link>
					<p className="capitalize text-gray-500 text-sm">{lead.contact.context}</p>
				</div>
			</td>
			<td className=" border-l border-r  border-gray-200 px-4 py-2 text-sm  capitalize text-center">
				{lead.organisation}
			</td>
			<td className=" border-l border-r  border-gray-200 px-4 py-2 text-sm  capitalize text-center">
				{lead.phone}
			</td>
			<td className="  border-l-0 px-4 py-2 text-gray-900 text-sm">
				<div className="">
					<span className="py-2 ">{lead.source.name}</span>
					<p className="capitalize text-gray-500 text-sm">{leadSourceValue[lead.source.context]}</p>
				</div>
			</td>
			<td className=" border-l border-r  border-gray-200 px-4 py-2 text-sm  capitalize">
				{format(new Date(lead.createdAt), "do MMMM yyyy")}
			</td>
			<td className=" border-l border-r  border-gray-200 px-4 py-2 text-sm text-center ">
				{/* <LeadStatusButton status={lead.leadStatus} /> */}
				<SwitchLeadStatus leadStatus={lead.leadStatus} leadId={lead._id} />
			</td>
			<td className=" border-l px-4 py-2 text-sm  capitalize text-center">
				₦ {numberWithCommas(lead.potentialValue)}
			</td>
		</tr>
	);
};

export default LeadsTableItem;
