import { orderActionTypes } from "../action-types/order-action.types";

const initialState = {
	allOrders: {},
	customerOrders: {},
	order: {},
	orderStats: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case orderActionTypes.GET_ALL_ORDERS:
			return { ...state, allOrders: action.payload };
		case orderActionTypes.GET_SINGLE_ORDER:
			return { ...state, order: action.payload };
		case orderActionTypes.GET_CUSTOMER_ORDERS:
			return { ...state, customerOrders: action.payload };
		case orderActionTypes.GET_ORDER_STATS:
			return { ...state, orderStats: action.payload };
		default:
			return state;
	}
};
