import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import { deleteProduct, getSingleProduct, updateProduct } from "../../redux/actions/products.actions";
import { CloseIcon, EditIcon } from "../../assets/Svg";
import CustomFormLabel from "../../components/commons/FormLabel";
import SelectPointer from "../../components/commons/SelectPointer";
import CustomCheckbox from "../../components/commons/custom-checkbox/custom-checkbox";
import {
	Delete,
	AddCircleOutlineOutlined,
	AvTimerOutlined,
	KeyboardBackspaceOutlined,
	DeleteOutlined,
} from "@material-ui/icons";
import Pageloader from "../../components/page-loader/Pageloader";
import { filteredActivity } from "../../redux/actions/activities.actions";
import { formatDistance } from "date-fns";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";
Modal.setAppElement("#root");
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
	},
};

const ViewProduct = (props) => {
	const { id } = useParams();
	const { getSingleProduct, updateProduct, filteredActivity, activities, deleteProduct } = props;
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [quantityOptions, setQuantityOptions] = useState([]);
	const [category, setCategory] = useState(1);
	const [workFlow, setWorkFlow] = useState([1]);
	const [name, setName] = useState("");
	const [savingProduct, setSavingProducts] = useState(false);
	const [openProductNote, setOpenProductNote] = useState(false);
	const [productNote, setProductNote] = useState("");
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		onGetSingleProduct();
		filteredActivity(id);
	}, []);

	const addRemoveQuantityOption = (value) => {
		let temQuantityOptions = [...quantityOptions];
		if (temQuantityOptions.includes(value)) {
			let filterOptions = temQuantityOptions.filter((opt) => opt !== value);
			setQuantityOptions(filterOptions);
		} else {
			temQuantityOptions.push(value);
			setQuantityOptions(temQuantityOptions);
		}
	};

	const deleteWorkFlow = (index) => {
		let tempWorkFlow = [...workFlow];
		tempWorkFlow.splice(index, 1);
		setWorkFlow(tempWorkFlow);
	};

	const editWorkFlow = (index, value) => {
		let tempWorkFlow = [...workFlow];
		tempWorkFlow.splice(index, 1, value);
		setWorkFlow(tempWorkFlow);
	};

	const onGetSingleProduct = async () => {
		if (id) {
			setLoading(true);
			let singleProduct = await getSingleProduct(id);
			if (singleProduct.success) {
				const { data } = singleProduct;
				setName(data.name);
				if (data.description) {
					setProductNote(data.description);
				}
				setCategory(data.category);
				setQuantityOptions(data.options);
				setWorkFlow(data.workflow);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	const addWorkFlow = () => {
		let tempWorkFlow = [...workFlow];
		tempWorkFlow.push(1);
		setWorkFlow(tempWorkFlow);
	};

	const onUpdateProduct = async () => {
		setSavingProducts(true);
		let productDetails = {
			name,
			category: parseInt(category),
			workflow: workFlow,
			options: quantityOptions,
		};
		let update = await updateProduct(id, productDetails);
		if (update.success) {
			addToast(update?.message, { appearance: "success" });
			filteredActivity(1, 4);
		} else {
			addToast(update?.message, { appearance: "error" });
		}
		setSavingProducts(false);
	};

	const onUpdateNote = async () => {
		if (productNote.length > 0) {
			let productDetails = {
				description: productNote,
			};
			let update = await updateProduct(id, productDetails);
			if (update.success) {
				getSingleProduct(id);
				addToast(update?.message, { appearance: "success" });
				filteredActivity(id);
			} else {
				addToast(update?.message, { appearance: "error" });
			}
		} else {
			addToast("Add product note", { appearance: "warning" });
		}
	};

	const onDeleteProduct = async () => {
		setDeleting(true);
		let res = await deleteProduct(id);
		if (res?.success) {
			addToast("Product deleted", { appearance: "success" });
			setTimeout(() => {
				window.location.replace("/products");
			}, 1000);
		} else {
			addToast(res?.message, { appearance: "error" });
		}
		setDeleting(false);
	};
	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			<DashboardNav history={props.history} />

			<div
				onClick={() => props.history.goBack()}
				className="flex mx-8 mt-4 cursor-pointer text-gray-700">
				<KeyboardBackspaceOutlined /> <span>Back</span>
			</div>
			<div className="mt-10 px-3 lg:px-8 mb-4">
				<h1 className="text-md font-medium lg:text-2xl">{name}</h1>
				<p className="text-xs text-gray-700">This product has been ordered 10 times</p>
			</div>
			<div className="lg:flex mx-6 lg:mx-8 mt-4">
				<div className="lg:w-2/3 w-full mx-2 bg-white rounded-sm px-4 pb-4">
					<div className="flex justify-between px-2 pt-4">
						<p className="text-xl font-bold">Product information</p>
						<div className="flex">
							<div
								onClick={() => setEdit(!edit)}
								className="flex text-xs uppercase cursor-pointer text-gray-700">
								<EditIcon /> <span className="pt-1 mx-2">EDIT</span>
							</div>
							<div
								onClick={() => onDeleteProduct()}
								className="flex text-xs uppercase cursor-pointer text-red-500">
								<DeleteOutlined />{" "}
								<span className="pt-1 mx-2">{!deleting ? "Delete" : "Deleting..."}</span>
							</div>
						</div>
					</div>
					<hr className="border-gray-500 w-full" />

					{!loading ? (
						<>
							<div className="flex flex-wrap -mx-3 mb-3 mt-6">
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<CustomFormLabel label={"Product name"} />
									<input
										className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
										id="grid-first-name"
										type="text"
										value={name}
										disabled={!edit}
										onChange={(e) => setName(e.target.value)}
										placeholder="Enter product name"
									/>
								</div>
								<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
									<CustomFormLabel label={"Category"} />
									<div className="relative">
										<select
											value={category}
											disabled={!edit}
											onChange={(e) => setCategory(e.target.value)}
											className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state">
											<option value={1}>Business Stationary</option>
											<option value={2}>Marketing Material</option>
											<option value={3}>Clothing & Apparel</option>
											<option value={4}>Labels & Packaging</option>
											<option value={5}>Publications</option>
											<option value={6}>Photo products & Art</option>
											<option value={7}>Promotional Products</option>
											<option value={8}>Signs & Display</option>
											<option value={9}>Other</option>
										</select>
										<SelectPointer />
									</div>
								</div>
							</div>
							<div className=" -mx-3 px-2 mb-3 mt-6">
								<CustomFormLabel label={"quantity options:"} />
								<div className="lg:flex ">
									<div className="flex mr-4 mt-3">
										<div onClick={() => addRemoveQuantityOption(0)} className="round">
											<input
												onChange={() => null}
												type="checkbox"
												disabled={!edit}
												checked={quantityOptions.includes(0)}
											/>
											<label htmlFor="checkbox"></label>
										</div>
										<span className="mx-8 text-sm font-bold">Manual input</span>
									</div>
									<CustomCheckbox
										label={"25"}
										oncheck={addRemoveQuantityOption}
										checked={quantityOptions.includes(25)}
									/>
									<CustomCheckbox
										label={"50"}
										oncheck={addRemoveQuantityOption}
										checked={quantityOptions.includes(50)}
									/>
									<CustomCheckbox
										label={"100"}
										oncheck={addRemoveQuantityOption}
										checked={quantityOptions.includes(100)}
									/>
									<CustomCheckbox
										label={"200"}
										oncheck={addRemoveQuantityOption}
										checked={quantityOptions.includes(200)}
									/>
									<CustomCheckbox
										label={"500"}
										oncheck={addRemoveQuantityOption}
										checked={quantityOptions.includes(500)}
									/>
									<CustomCheckbox
										label={"1000"}
										oncheck={addRemoveQuantityOption}
										checked={quantityOptions.includes(1000)}
									/>
								</div>
							</div>
							<div className=" -mx-3 px-3 mb-3 mt-6">
								<CustomFormLabel label={"workflow:"} />

								{workFlow.map((flow, i) => {
									return (
										<div key={i} className="flex mt-3">
											<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-1">
												<div className="relative">
													<select
														value={flow}
														disabled={!edit}
														onChange={(e) => editWorkFlow(i, parseInt(e.target.value))}
														className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
														id="grid-state">
														<option value={1}>Printing</option>
														<option value={2}>Posting</option>
														<option value={3}>Cutting</option>
														<option value={4}>Laminating</option>
														<option value={5}>Binding</option>
														<option value={6}>Embossing</option>
														<option value={7}>Foil Stamping</option>
														<option value={8}>Perfortaing</option>
														<option value={9}>Die-cutting</option>
														<option value={10}>Numbering</option>
														<option value={11}>Corner Rounding</option>
														<option value={12}>Folding</option>
														<option value={13}>Collating</option>
														<option value={14}>Creasing</option>
														<option value={15}>Punching</option>
														<option value={16}>Gluing</option>
														<option value={17}>Quality Control</option>
														<option value={18}>Dispatched</option>
														<option value={19}>Weeding</option>
														<option value={20}>Heat Pressing</option>
														<option value={21}>Plotting</option>
														<option value={22}>Sewing</option>
														<option value={23}>Installing</option>
														<option value={24}>Mounting</option>
													</select>
													<SelectPointer />
												</div>
											</div>
											{i !== 0 && (
												<span onClick={() => deleteWorkFlow(i)} className="mt-3 cursor-pointer">
													<Delete />
												</span>
											)}
										</div>
									);
								})}
							</div>
							<div className=" -mx-3 px-3 mb-3 mt-6 text-orange-600">
								<span onClick={() => addWorkFlow()} className="flex cursor-pointer">
									<AddCircleOutlineOutlined />
									Add new step
								</span>
							</div>
							{edit && (
								<div className="mt-6">
									<button
										onClick={() => onUpdateProduct()}
										className="bg-orange-500 hover:bg-orange-700 text-white font-bold text-xs py-2 px-4 rounded">
										{!savingProduct ? "Update product" : "Saving..."}
									</button>
								</div>
							)}
						</>
					) : (
						<div className="flex justify-center">
							<Pageloader />
						</div>
					)}
				</div>
				<div className="lg:w-1/3 w-full mx-2">
					<div className="bg-white rounded-sm px-2 lg:px-4 my-3">
						<div className="flex justify-between px-2 pt-4 pb-3">
							<p className="text-sm font-bold">Product note</p>
							<div
								onClick={() => setOpenProductNote(!openProductNote)}
								className="flex text-xs uppercase cursor-pointer text-gray-700">
								<EditIcon /> <span className="pt-1 mx-2">EDIT</span>
							</div>
						</div>
						<hr className="border-gray-300 pb-2" />
						<div className="text-xs pb-4">
							<p className="py-2">
								<span className="font-bold">{productNote}</span>
							</p>
						</div>
					</div>

					<Modal
						isOpen={openProductNote}
						onRequestClose={() => setOpenProductNote(!openProductNote)}
						contentLabel="Modal"
						style={customStyles}>
						<div className="px-4 mb-4">
							<div className="flex justify-end pb-5">
								<button onClick={() => setOpenProductNote(!openProductNote)}>
									<CloseIcon modalOpen={setOpenProductNote} />
								</button>
							</div>
							{/* <CreateUser modalOpen={setOpenInvite} /> */}
							<p className="text-gray-700 capitalize">Product note:</p>
							<textarea
								value={productNote}
								onChange={(e) => setProductNote(e.target.value)}
								className="rounded-md w-full bg-gray-200 p-2"
								rows="4"
								cols="50"
							/>
							<div className="mt-6">
								<button
									onClick={() => onUpdateNote()}
									type="button"
									className="bg-orange-500 hover:bg-orange-700 text-white font-bold text-xs py-2 px-4 rounded">
									Update note
								</button>
							</div>
						</div>
					</Modal>

					<div className="bg-white rounded-sm px-2 lg:px-4 my-3">
						<div className="flex justify-between px-2 pt-4 pb-3">
							<p className="text-sm font-bold">Recent activities</p>
						</div>
						<hr className="border-gray-300 pb-2" />
						{activities.events && activities.events.length > 0 ? (
							activities.events.map((event, i) => {
								return (
									<div key={i} className="text-xs pb-4 flex justify-between mt-2">
										<div style={{ width: "190px" }}>
											<p className="text-xs font-bold">
												<span dangerouslySetInnerHTML={{ __html: event.description }} />
											</p>
											<p className="text-xs text-gray-600">
												{event.user.firstName} {event.user.lastName}
											</p>
										</div>
										<div className="flex text-gray-600">
											<AvTimerOutlined />{" "}
											<span>
												{formatDistance(new Date(event.createdAt), new Date(), {
													addSuffix: true,
												})}
											</span>
										</div>
									</div>
								);
							})
						) : (
							<p className="text-gray-700 italic text-xs">No activity found</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	product: state.products.product,
	activities: state.activities.activities,
});

export default connect(mapStateToProps, {
	getSingleProduct,
	updateProduct,
	filteredActivity,
	deleteProduct,
})(ViewProduct);
