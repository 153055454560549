import React, { useEffect, useState } from "react";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import CustomersTable from "./CustomersTable";
import { connect } from "react-redux";
import Pagination from "rc-pagination";
import { filterCustomer, getAllCustomers } from "../../redux/actions/customers.actions";
import ScreenLoader from "../../components/screen-loader/ScreenLoader";

const Customers = (props) => {
	const { getAllCustomers, customers, filterCustomer, role } = props;
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [filterBy, setFilterBy] = useState("");

	useEffect(() => {
		ongetAllCustomers();
	}, []);

	const ongetAllCustomers = async () => {
		setLoading(true);
		let customer = await getAllCustomers(currentPage, 20);
		if (customer) {
			setLoading(false);
		}
	};

	const onPageChange = async (page) => {
		let allCustomer = await getAllCustomers(page, 20);
		setCurrentPage(page);
		return allCustomer;
	};

	return (
		<div
			style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}
			className="px-4">
			{role !== 1 && <DashboardNav history={props.history} />}
			<div className="pt-10 px-3 lg:px-8 mb-4 md:flex justify-between">
				<div>
					<h1 className="text-md font-medium lg:text-2xl">Customers</h1>
					<p className="text-xs text-gray-700">
						Here is a list of everyone that has ever purchased from Ark:
					</p>
				</div>
				<div className="w-full lg:w-2/6  px-3 mb-6 md:mb-0 flex justify-end">
					<div className="relative mx-1">
						<select
							onChange={(e) => setFilterBy(e.target.value)}
							className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
							id="grid-state">
							<option value="">Filter by</option>
							<option value="industry">Industry</option>
						</select>
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
							<svg
								className="fill-current h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20">
								<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
							</svg>
						</div>
					</div>
					{filterBy === "industry" && (
						<div className="relative">
							<select
								style={{ width: "150px" }}
								onChange={(e) => filterCustomer(e.target.value)}
								className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
								id="grid-state">
								<option value="">Select</option>
								<option value={1}> Healthcare, Beauty & Fitness, Technology</option>
								<option value={2}>Financial Services & Insurance</option>
								<option value={3}>FinTechs</option>
								<option value={4}>Food, Beverage & Restaurant</option>
								<option value={5}>FMCGs</option>
								<option value={6}>Government</option>
								<option value={7}>Oil & Gas</option>
								<option value={8}>Travel & Tourism</option>
								<option value={9}>Education & Training</option>
								<option value={10}>Planning & Events Management</option>
								<option value={11}>Religion</option>
								<option value={12}>Logistics & Transportation</option>
								<option value={13}>Fashion & Retail</option>
								<option value={14}>Professional Services: Legal & Accounting</option>
								<option value={15}>Creative Freelancers & Agencies</option>
								<option value={16}>Photography & Lifestyle</option>
								<option value={17}>NGOs & Charities</option>
								<option value={18}>Real Estate & Construction</option>
								<option value={19}>Other</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20">
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						</div>
					)}
				</div>
			</div>

			{!loading && (
				<div>{role !== 1 ? <CustomersTable customers={customers} /> : <p>Restricted page</p>}</div>
			)}
			{loading === true && <ScreenLoader />}
			<div className="mt-6 flex px-6 justify-end ">
				{!loading && (
					<Pagination
						onChange={(page) => onPageChange(page)}
						defaultCurrent={currentPage}
						total={customers.count ? customers.count : 0}
						pageSize={20}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	customers: state.customers.customers,
});
export default connect(mapStateToProps, { getAllCustomers, filterCustomer })(Customers);
