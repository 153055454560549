import {
	AccessTime,
	MailOutline,
	PeopleAltOutlined,
	PersonOutline,
	PhoneAndroidOutlined,
} from "@material-ui/icons";
import React from "react";
import { StatusIcon } from "../../assets/Svg";
import UserListItem from "./UserListItem";

const UsersTable = ({ allUsers, activeView }) => {
	return (
		<div>
			<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-6 py-4 mx-6 lg:mx-8">
				<table className="table-auto min-w-full px-2 ">
					<thead className="">
						<tr className="min-h-24 text-xs text-gray-600 uppercase">
							<th className="px-4 py-2">
								<span className="lg:flex justify-center">
									<PeopleAltOutlined />
									<p className="px-2">User</p>
								</span>
							</th>
							<th className="px-4 border-r border-l py-2">
								<span className="lg:flex justify-center">
									<PersonOutline />
									<p className="px-2">Department</p>
								</span>
							</th>
							<th className="px-4 py-2">
								<span className="lg:flex justify-center">
									<MailOutline />
									<p className="px-2">Email address</p>
								</span>
							</th>
							<th className="px-4 py-2 border-r border-l">
								<span className="lg:flex justify-center">
									<PhoneAndroidOutlined />
									<p className="px-2">Phone Number</p>
								</span>
							</th>
							<th className="px-4 py-2  border-r">
								<span className="lg:flex justify-center">
									<StatusIcon />
									<p className="px-2">Role</p>
								</span>
							</th>
							<th className="px-4 py-2  border-r">
								<span className="lg:flex justify-center">
									<AccessTime />
									<p className="px-2">Last login</p>
								</span>
							</th>
							<th className="px-4 py-2 ">
								<span className="lg:flex justify-center"></span>
							</th>
						</tr>
					</thead>
					<tbody>
						{allUsers.users && allUsers.users.length > 0 ? (
							allUsers.users.map((user, i) => {
								return <UserListItem activeView={activeView} key={i} user={user} />;
							})
						) : (
							<tr>
								<td>No user found.</td>
							</tr>
						)}
						{/* {products.products && products.products.length > 0 ? (
							products.products.map((product, i) => {
								let sortedOptions = [];
								let sortedWorkflow = [];
								product.options.map((opt) => {
									if (opt === 0) {
										sortedOptions.push("Manual input");
									} else {
										sortedOptions.push(opt);
									}
									product.workflow.map((flow) => {
										if (!sortedWorkflow.includes(flow)) {
											sortedWorkflow.push(workflowValues[flow]);
										}
									});
								});
								return (
									<ProductTableItem
										key={i}
										id={product._id}
										name={product.name}
										workflow={product.workflow}
										category={categoryValues[product.category]}
										orderCount={product.orders}
										quantityOptions={sortedOptions.join(", ")}
										productDetails={product}
									/>
								);
							})
						) : (
							<tr>No product added</tr>
						)} */}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default UsersTable;
