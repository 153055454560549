import React from "react";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import DateButton from "../../../../components/commons/Date-button";
import StackedImages from "../../../../components/commons/Stacked-images";
import {
	changeTaskStatus,
	getDoneTasks,
	getRecentTasks,
	getTasks,
} from "../../../../redux/actions/task.actions";
import { connect } from "react-redux";

const TaskCardItem = ({ task, getTasks, getDoneTasks, changeTaskStatus, getRecentTasks }) => {
	const { addToast } = useToasts();

	const markAsDone = async (id, status) => {
		let details = { status };
		let update = await changeTaskStatus(id, details);
		if (update?.success) {
			getRecentTasks();
			getDoneTasks();
			addToast("Task status updated", { appearance: "success" });
		} else {
			addToast(update?.data?.message, { appearance: "error" });
		}
	};

	const changeStatus = (id, status) => {
		if (status === 3) {
			markAsDone(id, 2);
		} else {
			markAsDone(id, 3);
		}
	};

	return (
		<div className="mt-6">
			<div className="order-title flex">
				<input
					onClick={() => changeStatus(task._id, task.status)}
					type="radio"
					checked={task.status === 3}
				/>
				<p
					className={
						task.status === 3
							? "text-xs text-gray-600 cursor-pointer font-bold mx-3 pb-2 line-through hover:text-orange-400"
							: "text-xs cursor-pointer font-bold mx-3 pt-1 pb-2 hover:text-orange-400"
					}>
					{task.title}
				</p>
			</div>
			<div className="flex justify-between">
				<DateButton task={task} />
				<div className="pt-4">
					<StackedImages assignees={[task.assignedTo]} />
				</div>
			</div>
			<hr className="border-gray-300" />
		</div>
	);
};

export default connect(null, { getTasks, getDoneTasks, changeTaskStatus, getRecentTasks })(TaskCardItem);
