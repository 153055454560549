import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = (props) => {
	const { component: Component, auth, ...rest } = props;
	return (
		<>
			<Route
				{...rest}
				render={(props) => {
					if (window.sessionStorage.getItem("_ark_task") === null) {
						return (
							<main>
								<Component {...rest} {...props} />
							</main>
						);
					} else {
						window.location.replace("/dashboard/overview");
						{
							/* return <Redirect to="/dashboard/overview" />; */
						}
					}
				}}
			/>
		</>
	);
};

export default PublicRoute;
