import { combineReducers } from "redux";
import auth from "./auth.reducer";
import products from "./products.reducers";
import customers from "./customers.reducers";
import leads from "./leads.reducer";
import users from "./users.reducer";
import tasks from "./task.reducer";
import orders from "./orders.reducer";
import jobLogs from "./joblogs.reducer";
import activities from "./activities.reducer";

export default combineReducers({
	auth,
	products,
	customers,
	leads,
	users,
	tasks,
	orders,
	jobLogs,
	activities,
});
