const { productsActionTypes } = require("../action-types/product-actions.type");

const initialState = {
	products: {},
	product: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case productsActionTypes.GET_ALL_PRODUCTS:
			return { ...state, products: action.payload };
		case productsActionTypes.GET_SINGLE_PRODUCT:
			return { ...state, product: action.payload };
		default:
			return state;
	}
};
