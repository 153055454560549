import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { CloseIcon, MegaphoneIcon } from "../../../../assets/Svg";
import { sendAnnouncement } from "../../../../redux/actions/activities.actions";
import { getAllUsers, getDepartments } from "../../../../redux/actions/users.actions";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";
Modal.setAppElement("#root");
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
	},
};

const AnnouncementCard = ({
	sendAnnouncement,
	getAllUsers,
	allUsers,
	getDepartments,
	allDepartments,
	role,
}) => {
	const { addToast } = useToasts();
	const [openAnnouncement, setOpenAnnouncement] = useState(false);
	const [announcement, setAnnouncement] = useState("");
	const [openRecipient, setOpenRecipients] = useState(false);
	const [usersId, setUsersId] = useState([]);
	const [departmentId, setDepartmentId] = useState([]);
	const [allSelected, setAllselected] = useState(false);

	const onSendAnnouncement = async () => {
		if (announcement.length > 0) {
			let details = { message: announcement, targets: { users: usersId, departments: departmentId } };

			let res = await sendAnnouncement(details);
			if (res.success) {
				addToast("Announcement sent", { appearance: "success" });
				setOpenAnnouncement(false);
			} else {
				addToast(res?.error, { appearance: "error" });
			}
		}
	};

	useEffect(() => {
		getAllUsers(2000);
		getDepartments();
	}, []);

	const onSelectDepartment = (id) => {
		let copyDepartment = [...departmentId];
		if (copyDepartment.includes(id)) {
			let filterDept = copyDepartment.filter((item) => item !== id);
			setDepartmentId(filterDept);
		} else {
			copyDepartment.push(id);
			setDepartmentId(copyDepartment);
		}
	};

	const onselectUser = (id) => {
		let copyUser = [...usersId];
		if (copyUser.includes(id)) {
			let filterDept = copyUser.filter((item) => item !== id);
			setUsersId(filterDept);
		} else {
			copyUser.push(id);
			setUsersId(copyUser);
		}
	};

	const onSelectAllUsers = () => {
		let tempusers = [];
		if (allSelected === false) {
			if (allUsers.users) {
				allUsers.users.map((user) => {
					tempusers.push(user._id);
				});
			}
			setUsersId(tempusers);
			setAllselected(true);
		} else {
			tempusers = [];
			setUsersId(tempusers);
			setAllselected(false);
		}
	};

	return (
		<div className="mt-6  p-10 bg-white shadow-sm min-w-full rounded-sm">
			<div className="flex pt-4">
				<div>
					<p className="text-gray-600 uppercase text-xs pb-4">Create announcement</p>
					<p className="text-gray-900">Make an announcement to your co-workers</p>
				</div>
				<div>
					<MegaphoneIcon />
				</div>
			</div>
			<div className="flex justify-between">
				{role !== 1 && role !== 2 ? (
					<button
						onClick={() => setOpenAnnouncement(true)}
						style={{ border: "1px solid #F8AC87", color: "#F8AC87" }}
						className="button-outlined rounded-md p-3">
						Create announcement
					</button>
				) : (
					""
				)}
				<p className=" cursor-pointer pt-3 hover:text-red-200">
					<Link to="/dashboard/announcements">See history</Link>
				</p>
			</div>
			{/* announcement */}
			<Modal
				isOpen={openAnnouncement}
				onRequestClose={() => setOpenAnnouncement(!openAnnouncement)}
				contentLabel="Modal"
				style={customStyles}>
				<div className="px-4 mb-4">
					<div className="flex justify-end pb-5">
						<button onClick={() => setOpenAnnouncement(!openAnnouncement)}>
							<CloseIcon modalOpen={setOpenAnnouncement} />
						</button>
					</div>
					<div className="mb-6">
						<p className="text-lg text-gray-900">Send an announcement</p>
						<p className="text-gray-600 text-sm">
							Select your announcement recipients and send your message:
						</p>
					</div>
					{/* <CreateUser modalOpen={setOpenInvite} /> */}
					<p className="text-gray-700 capitalize">Announcement:</p>
					<textarea
						value={announcement}
						onChange={(e) => setAnnouncement(e.target.value)}
						className="rounded-md w-full bg-gray-200 "
						rows="4"
						cols="50"
					/>
					<div className="bg-gray-200 w-full h-12 p-3 mt-6">
						<div onClick={() => setOpenRecipients(!openRecipient)} className="flex justify-between">
							<div>Select recipient</div>
							<div>{openRecipient ? <ExpandLess /> : <ExpandMore />}</div>
						</div>
					</div>
					{openRecipient && (
						<div className="bg-gray-200 flex flex-wrap mt-1 p-4">
							<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
								<label className=" block text-gray-600 ">
									<input
										checked={allSelected}
										onClick={() => onSelectAllUsers()}
										className="mr-2 leading-tight"
										type="checkbox"
									/>
									<span className="text-sm">All users</span>
								</label>
								<label className=" block text-gray-600 ">
									<input className="mr-2 leading-tight" type="checkbox" />
									<span className="text-sm">Management</span>
								</label>
								<p className="mt-6">Departments</p>
								{allDepartments.departments && allDepartments.departments.length > 0
									? allDepartments.departments.map((dpt, i) => {
											return (
												<label key={i} className=" block text-gray-600 ">
													<input
														checked={departmentId.includes(dpt._id)}
														onClick={() => onSelectDepartment(dpt._id)}
														className="mr-2 leading-tight"
														type="checkbox"
													/>
													<span className="text-sm">{dpt.name}</span>
												</label>
											);
									  })
									: ""}
							</div>
							<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
								<p>Users</p>
								{allUsers.users && allUsers.users.length > 0
									? allUsers.users.map((user, i) => {
											return (
												<label key={i} className=" block text-gray-600 ">
													<input
														checked={usersId.includes(user._id)}
														onClick={() => onselectUser(user._id)}
														className="mr-2 leading-tight"
														type="checkbox"
													/>
													<span className="text-sm">
														{user.firstName} {user.lastName}
													</span>
												</label>
											);
									  })
									: ""}
							</div>
						</div>
					)}
					<div className="mt-6">
						<button
							onClick={() => onSendAnnouncement()}
							type="button"
							className="bg-orange-500 hover:bg-orange-700 text-white font-bold text-xs py-2 px-4 rounded">
							Send announcement
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	allUsers: state.users.allUsers,
	allDepartments: state.users.departments,
});

export default connect(mapStateToProps, { sendAnnouncement, getAllUsers, getDepartments })(
	AnnouncementCard
);
