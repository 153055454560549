import { usersActionTypes } from "../action-types/users-action.types";

const initialState = {
	allUsers: {},
	user: {},
	departments: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case usersActionTypes.GET_ALL_USERS:
			return { ...state, allUsers: action.payload };
		case usersActionTypes.GET_DEPARTMENTS:
			return { ...state, departments: action.payload };
		default:
			return state;
	}
};
