import React from "react";
import { NumberIcon } from "../../assets/Svg";
import { CategoryOutlined, FlashOnOutlined, LocalMall } from "@material-ui/icons";
import ProductTableItem from "./ProductTableItem";
import { categoryValues, workflowValues } from "../../utils/definedValues";

const ProductTable = (props) => {
	const { products } = props;
	return (
		<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-6 py-4 mx-6 lg:mx-8">
			<table className="table-auto min-w-full px-2 ">
				<thead className="">
					<tr className="min-h-24 text-xs text-gray-600 uppercase">
						<th className="px-4 py-2">
							<span className="lg:flex justify-center">
								<LocalMall />
								<p className="px-2">product name</p>
							</span>
						</th>
						<th className="px-4 border-r border-l py-2">
							<span className="lg:flex justify-center">
								<FlashOnOutlined />
								<p className="px-2">Work flow</p>
							</span>
						</th>
						<th className="px-4 py-2">
							<span className="lg:flex justify-center">
								<CategoryOutlined />
								<p className="px-2">Category</p>
							</span>
						</th>
						<th className="px-4 py-2 border-r border-l">
							<span className="lg:flex justify-center">
								<NumberIcon />
								<p className="px-2">order count</p>
							</span>
						</th>
						<th className="px-4 py-2 ">
							<span className="lg:flex justify-center">
								<NumberIcon />
								<p className="px-2">Quantity options</p>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					{products.products && products.products.length > 0 ? (
						products.products.map((product, i) => {
							let sortedOptions = [];
							let sortedWorkflow = [];
							product.options.map((opt) => {
								if (opt === 0) {
									sortedOptions.push("Manual input");
								} else {
									sortedOptions.push(opt);
								}
								product.workflow.map((flow) => {
									if (!sortedWorkflow.includes(flow)) {
										sortedWorkflow.push(workflowValues[flow]);
									}
								});
							});
							return (
								<ProductTableItem
									key={i}
									id={product._id}
									name={product.name}
									workflow={product.workflow}
									category={categoryValues[product.category]}
									orderCount={product.orders}
									quantityOptions={sortedOptions.join(", ")}
									productDetails={product}
								/>
							);
						})
					) : (
						<tr>No product added</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default ProductTable;
