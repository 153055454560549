import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NumberIcon } from "../../assets/Svg";

import {
	AvTimerOutlined,
	PersonOutline,
	LocalPhoneOutlined,
	GroupOutlined,
	MailOutline,
} from "@material-ui/icons";
import CustomerTableItem from "./CustomerTableItem";
import { getAllCustomers } from "../../redux/actions/customers.actions";

const CustomersTable = (props) => {
	const { customers } = props;

	// useEffect(() => {
	// 	getAllCustomers(1, 20);
	// }, []);

	return (
		<div className=" overflow-scroll bg-white rounded-sm px-3 lg:px-6 py-4 mx-6 lg:mx-8">
			<table className="table-auto min-w-full px-1 ">
				<thead className="">
					<tr className="min-h-24 text-xs text-gray-600 uppercase">
						<th className="px-4 py-2">
							<span className="lg:flex justify-center">
								<GroupOutlined />
								<p className="px-2">Customer</p>
							</span>
						</th>
						<th className="px-4 border-r border-l py-2">
							<span className="lg:flex justify-center">
								<PersonOutline />
								<p className="px-2">Primary contact</p>
							</span>
						</th>
						<th className="px-4 py-2">
							<span className="lg:flex justify-center">
								<MailOutline />
								<p className="px-2">Email address</p>
							</span>
						</th>
						<th className="px-4 py-2 border-r border-l">
							<span className="lg:flex justify-center">
								<LocalPhoneOutlined />
								<p className="px-2">Phone number</p>
							</span>
						</th>
						<th className="px-4 py-2 ">
							<span className="lg:flex justify-center">
								<NumberIcon />
								<p className="px-2">Number of orders</p>
							</span>
						</th>
						<th className="px-4 py-2 ">
							<span className="lg:flex justify-center">
								<AvTimerOutlined />
								<p className="px-2">last order date</p>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					{customers.customers && customers.customers.length > 0 ? (
						customers.customers.map((customer, i) => {
							let primaryContact = {};
							customer.contacts.map((contact) => {
								if (contact.isPrimary) {
									primaryContact = contact;
								}
							});
							return (
								<CustomerTableItem
									key={i}
									id={customer._id}
									customer={customer.name}
									primaryContact={primaryContact.name}
									email={primaryContact.email}
									phoneNumber={primaryContact.phone}
									numOfOrders={customer.orders}
									lastOrderDate={customer.lastOrderDate ? customer.lastOrderDate : null}
								/>
							);
						})
					) : (
						<tr>No customer found</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

const mapStateToProps = (state) => ({
	customers: state.customers.customers,
});

export default connect(mapStateToProps, { getAllCustomers })(CustomersTable);
