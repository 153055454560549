import axios from "axios";

const { leadsActionTypes } = require("../action-types/leads-action.types");

export const getLeadsStats = () => async (dispatch) => {
	let url = `/stats/leads`;
	try {
		let stats = await axios.get(url);
		const { success, data } = stats.data;
		if (success) {
			dispatch({
				type: leadsActionTypes.GET_LEADS_STATS,
				payload: data,
			});
			return stats.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const createNewLead = (leadDetails) => async (dispatch) => {
	let url = `/leads`;
	try {
		let lead = await axios.put(url, leadDetails);
		const { success } = lead.data;
		if (success) {
			return lead.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const getAllLeads = (page, limit) => async (dispatch) => {
	let url = "";
	if (page) {
		url = `/leads?page=${page}&limit=${limit}`;
	} else {
		url = `/leads?limit=${limit}`;
	}
	try {
		let leads = await axios.get(url);
		const { success, data } = leads.data;
		if (success) {
			dispatch({
				type: leadsActionTypes.GET_ALL_LEADS,
				payload: data,
			});
			return leads.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const searchLeads = (query) => async (dispatch) => {
	let url = "";
	if (query.length > 0) {
		url = `/leads?query=${query}`;
	} else {
		url = `/leads?page=1&limit=20`;
	}
	try {
		let leads = await axios.get(url);
		const { success, data } = leads.data;
		if (success) {
			dispatch({
				type: leadsActionTypes.GET_ALL_LEADS,
				payload: data,
			});
			return leads.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const filterLeads = (status) => async (dispatch) => {
	let url = "";
	if (status.length > 0) {
		url = `/leads?status=${status}`;
	} else {
		url = `/leads?page=1&limit=20`;
	}
	try {
		let leads = await axios.get(url);
		const { success, data } = leads.data;
		if (success) {
			dispatch({
				type: leadsActionTypes.GET_ALL_LEADS,
				payload: data,
			});
			return leads.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const getSingleLead = (id) => async (dispatch) => {
	let url = `/leads/${id}`;
	try {
		let lead = await axios.get(url);
		const { success, data } = lead.data;
		if (success) {
			dispatch({
				type: leadsActionTypes.GET_SINGLE_LEAD,
				payload: data,
			});
			return lead.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateLead = (id, leadDetails) => async (dispatch) => {
	let url = `/leads/${id}`;
	try {
		let lead = await axios.post(url, leadDetails);
		const { success } = lead.data;
		if (success) {
			return lead.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const changeLeadStatus = (id, leadDetails) => async (dispatch) => {
	let url = `/leads/${id}`;
	try {
		let lead = await axios.patch(url, leadDetails);
		const { success } = lead.data;
		if (success) {
			return lead.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const deleteLeads = (id) => async () => {
	let url = `/leads/${id}`;
	try {
		let data = await axios.delete(url);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};
