import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import DashboardNav from "../../containers/navbar/Dashboard-nav";
import { EditIcon } from "../../assets/Svg";
import SelectPointer from "../../components/commons/SelectPointer";
import {
	KeyboardBackspaceOutlined,
	AvTimerOutlined,
	Delete,
	AttachFileOutlined,
	DeleteOutlined,
} from "@material-ui/icons";
import Pageloader from "../../components/page-loader/Pageloader";
import {
	addItemAttachment,
	deleteItemAttachement,
	getSingleOrder,
	updateOrder,
	updateOrderItems,
	deleteOrder,
} from "../../redux/actions/order.actions";
import { formatDistance, formatISO } from "date-fns/esm";
import { getAllProducts } from "../../redux/actions/products.actions";
import { getActivities, filteredActivity } from "../../redux/actions/activities.actions";
import "react-datepicker/dist/react-datepicker.css";
import { uploadImage } from "../../redux/actions/users.actions";

const customStyles = {
	content: {
		top: "40%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
	},
};
const ViewOrder = (props) => {
	const { id } = useParams();
	const {
		getSingleOrder,
		order,
		updateOrder,
		updateOrderItems,
		getAllProducts,
		allProducts,
		getActivities,
		activities,
		role,
		filteredActivity,
		deleteItemAttachement,
		addItemAttachment,
		uploadImage,
		deleteOrder,
	} = props;
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(true);
	const [savingOrder, setSavingOrder] = useState(false);
	const [savingOrderItems, setSavingOrderItems] = useState(false);
	const [customer, setCustomer] = useState({});
	const [orderName, setOrderName] = useState("");
	const [orderDueDate, setOrderDueDate] = useState(new Date());
	const [orderPriority, setOrderPriority] = useState(0);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [editItems, setEditItems] = useState(true);
	const [paymentStatus, setPaymentStatus] = useState(0);
	const [fetchedproducts, setfetchedProducts] = useState([]);
	const [selecteditems, setselectedItems] = useState([]);
	const [deliveryAddress, setDeliveryAddress] = useState("");
	const [uploading, setUploading] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		onGetSingleOrder();
		getAllProducts(1, 200000, true);
		ongetAllProducts();
		filteredActivity(id);
		return;
	}, []);

	const ongetAllProducts = async () => {
		let allProdData = await getAllProducts(1, 200000, true);
		if (allProdData.success) {
			setfetchedProducts(allProdData.data.products);
		}
	};

	const onGetSingleOrder = async () => {
		if (id) {
			setLoading(true);
			let data = await getSingleOrder(id);
			if (data && data.success) {
				setselectedItems(data.data.items);
				setOrderName(data.data.title);
				setOrderPriority(data.data.priority);
				setCustomer(data.data.customer);
				setPaymentStatus(data.data.paymentStatus);
				setSelectedProducts(data.data.items);
				setOrderDueDate(new Date(data.data.dueAt));
				setDeliveryAddress(data.data.deliveryAddress);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	const onUpdateOrder = async (e) => {
		e.preventDefault();
		setSavingOrder(true);
		let orderDetails = {
			title: orderName,
			customer: customer._id,
			priority: orderPriority,
			dueAt: formatISO(new Date(orderDueDate)),
			deliveryAddress,
		};
		let update = await updateOrder(orderDetails, id);
		if (update.success) {
			addToast("Order updated", { appearance: "success" });
			setSavingOrder(false);
		} else {
			addToast(update.message, { appearance: "error" });
			setSavingOrder(false);
		}
	};

	// changing the selected product
	const onUpdateOrderProduct = async (index, value, label) => {
		let tempSelectedItems = [...selectedProducts];
		tempSelectedItems[index][label] = value;
		setSelectedProducts(tempSelectedItems);
	};

	const renderOptions = (opts) => {
		if (!opts.includes(0)) {
			return opts.map((opt, i) => (
				<option key={i} value={opt}>
					{opt}
				</option>
			));
		} else {
			return (
				<div className="relative">
					<input
						type="number"
						required
						className="block appearance-none text-xs w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						id="grid-date"
						placeholder="Enter quantity"
					/>
				</div>
			);
		}
	};

	// save the updated order items
	const onSaveOrderItems = async (e) => {
		e.preventDefault();
		setSavingOrderItems(true);
		let details = { items: selectedProducts };
		let data = await updateOrderItems(details, id);
		if (data.success) {
			addToast("Order items updated ", { appearance: "success" });
			setSavingOrderItems(false);
			return data;
		} else {
			addToast(data.message, { appearance: "error" });
			setSavingOrderItems(false);
		}
	};

	const onUpdatePayment = async (value) => {
		let details = {
			paymentStatus: parseInt(value),
		};
		setPaymentStatus(parseInt(value));
		let update = await updateOrder(details, id);
		if (update.success) {
			addToast("Payment updated ", { appearance: "success" });
		} else {
			addToast(update.message, { appearance: "error" });
		}
	};

	const onDeleteItemFile = async (itemId, fileId) => {
		let details = { attachment: fileId };
		let response = await deleteItemAttachement(id, itemId, details);
		if (response.success) {
			addToast("File deleted", { appearance: "success" });
			updatePage();
		} else {
			addToast(response.message, { appearance: "error" });
		}
	};

	const updatePage = async () => {
		let data = await getSingleOrder(id);
		if (data && data.success) {
			setselectedItems(data.data.items);
			setOrderName(data.data.title);
			setOrderPriority(data.data.priority);
			setCustomer(data.data.customer);
			setPaymentStatus(data.data.paymentStatus);
			setSelectedProducts(data.data.items);
			setOrderDueDate(new Date(data.data.dueAt));
			setDeliveryAddress(data.data.deliveryAddress);
		}
	};

	const onUploadItemFile = async (itemId, file) => {
		setUploading(true);
		const data = new FormData();
		data.append("file", file);
		const config = { headers: { "Content-Type": "multipart/form-data" } };
		let upload = await uploadImage(data, config);
		if (upload.success) {
			let imageUrl = upload.data;
			let details = { url: imageUrl };
			let response = await addItemAttachment(id, itemId, details);
			if (response.success) {
				addToast("File uploaded", { appearance: "success" });
				updatePage();
			} else {
				addToast(response?.message, { appearance: "error" });
			}
		} else {
			addToast(upload?.message, { appearance: "error" });
		}

		setUploading(false);
	};

	const onDeleteOrder = async () => {
		setDeleting(true);
		let res = await deleteOrder(id);
		if (res?.success) {
			addToast("Order deleted", { appearance: "success" });
			window.location.replace("/orders");
		} else {
			addToast(res?.message, { appearance: "error" });
		}
		setDeleting(false);
	};

	return (
		<div style={{ backgroundColor: "#F6F6F6", paddingBottom: "20px", minHeight: "720px" }}>
			{role !== 1 && <DashboardNav history={props.history} />}

			<div
				onClick={() => props.history.goBack()}
				className="flex mx-8 mt-4 lg:mt-8 cursor-pointer text-gray-700">
				<KeyboardBackspaceOutlined /> <span>Back</span>
			</div>
			<div className="mt-10 px-3 lg:px-8 mb-4">
				<h1 className="text-md font-medium lg:text-2xl pb-2">
					{order.shortCode ? order.shortCode : "Order"}
				</h1>
				<p className="text-xs text-gray-700">{orderName}</p>
			</div>

			<div className="lg:flex  mt-4">
				<div className="lg:w-2/3 w-full mx-2  px-4  pb-4">
					<div className=" w-full mx-2 bg-white rounded-sm px-4  pb-4">
						<div className="flex justify-between px-2 pt-4">
							<p className="text-l ">Order information</p>
							<div className="flex">
								<div
									onClick={() => setEdit(!edit)}
									className="flex text-xs uppercase cursor-pointer text-gray-700">
									<EditIcon /> <span className="pt-1 mx-2">EDIT</span>
								</div>
								<div
									onClick={() => onDeleteOrder()}
									className="flex text-xs uppercase cursor-pointer text-red-500">
									<DeleteOutlined />{" "}
									<span className="pt-1 mx-2">{!deleting ? "Delete" : "Deleting..."}</span>
								</div>
							</div>
						</div>
						<hr className="border-gray-200 w-full" />
						{!loading ? (
							<>
								<form onSubmit={(e) => onUpdateOrder(e)} className="w-full mt-3 lg:mt-6">
									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-city">
												Order name:
											</label>
											<input
												required
												disabled={edit}
												value={orderName}
												onChange={(e) => setOrderName(e.target.value)}
												className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
												id="grid-owner"
												type="text"
												placeholder="Order name..."
											/>
										</div>
									</div>
									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full px-3">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
												htmlFor="grid-city">
												Delivery address:
											</label>
											<input
												required
												disabled={edit}
												value={deliveryAddress}
												onChange={(e) => setDeliveryAddress(e.target.value)}
												className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
												id="grid-owner"
												type="text"
												placeholder="address..."
											/>
										</div>
									</div>

									<div className="flex flex-wrap -mx-3 mb-2 mt-6">
										<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
											<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
												Due Date:
											</label>

											<DatePicker
												wrapperClassName="customize-date"
												disabled={edit}
												className="customize-date"
												dateFormat="yyyy-MM-dd h:mm aa"
												selected={orderDueDate}
												onChange={(date) => setOrderDueDate(date)}
											/>
										</div>
										<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
											<label
												className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
												htmlFor="grid-email">
												Priority:
											</label>
											<div className="relative">
												<select
													disabled={edit}
													value={orderPriority}
													onChange={(e) => setOrderPriority(parseInt(e.target.value))}
													className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="grid-state">
													<option value={0}>High</option>
													<option value={1}>Mid</option>
													<option value={2}>Low</option>
													<option value={3}>Express</option>
												</select>
												<SelectPointer />
											</div>
										</div>
									</div>

									{!edit && (
										<div className="mt-6">
											<button
												type="submit"
												className="bg-orange-500 hover:bg-orange-700 w-1/6 overflow-x-auto text-white  text-xs py-2 px-4 rounded">
												{!savingOrder ? "Save" : "Saving..."}
											</button>
										</div>
									)}
								</form>
							</>
						) : (
							<div className="flex justify-center">
								<Pageloader />
							</div>
						)}
					</div>

					<div className=" w-full mx-2 bg-white rounded-sm px-4 mt-4  pb-4">
						<div className="flex justify-between px-2 pt-4">
							<p className="text-l ">Job items</p>
							<div
								onClick={() => setEditItems(!editItems)}
								className="flex text-xs uppercase cursor-pointer text-gray-700">
								<EditIcon /> <span className="pt-1 mx-2">EDIT</span>
							</div>
						</div>
						<hr className="border-gray-200 w-full" />
						<>
							<form onSubmit={(e) => onSaveOrderItems(e)} className="w-full mt-3 lg:mt-6">
								{selectedProducts && selectedProducts.length > 0
									? selectedProducts.map((prod, i) => {
											return (
												<div key={i}>
													<div className="flex flex-wrap -mx-3 mb-2 mt-6">
														<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
															<label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
																Product:
															</label>
															<div className="relative">
																<select
																	disabled={editItems}
																	value={selectedProducts[i].product}
																	onChange={(e) => onUpdateOrderProduct(i, e.target.value, "product")}
																	className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
																	<option>Select product</option>
																	{allProducts.products && allProducts.products.length > 0 ? (
																		allProducts.products.map((product, index) => {
																			return (
																				<option key={index} value={product._id}>
																					{product.name}
																				</option>
																			);
																		})
																	) : (
																		<option>No product added</option>
																	)}
																</select>
																<SelectPointer />
															</div>
														</div>

														<div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
															<label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
																Quantity:
															</label>
															<div className="relative">
																{allProducts.products?.map((item, x) => {
																	if (item._id === selectedProducts[i].product) {
																		if (!item.options.includes(0)) {
																			return (
																				<div key={x}>
																					<select
																						disabled={editItems}
																						onChange={(e) =>
																							onUpdateOrderProduct(
																								i,
																								parseInt(e.target.value),
																								"quantity"
																							)
																						}
																						value={selectedProducts[i].quantity}
																						className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
																						<option>Select quantity</option>
																						{allProducts.products?.map((item) => {
																							return item._id === selectedProducts[i].product
																								? renderOptions(item.options)
																								: "";
																						})}
																					</select>
																					<SelectPointer />
																				</div>
																			);
																		} else {
																			return (
																				<input
																					type="number"
																					required
																					value={selectedProducts[i].quantity}
																					onChange={(e) =>
																						onUpdateOrderProduct(i, parseInt(e.target.value), "quantity")
																					}
																					className="block appearance-none text-xs w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
																					id="grid-date"
																					placeholder="Enter quantity"
																				/>
																			);
																		}
																	}
																})}
															</div>
														</div>

														<div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
															<label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
																Status:
															</label>
															<div className="relative">
																<select
																	disabled={editItems}
																	value={selectedProducts[i].status}
																	onChange={(e) =>
																		onUpdateOrderProduct(i, parseInt(e.target.value), "status")
																	}
																	className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
																	<option>Select status</option>
																	<option value={0}>Order received</option>
																	<option value={1}>Pre-flight</option>
																	<option value={2}>In production</option>
																	<option value={3}>Quality control</option>
																	<option value={4}>Ready</option>
																	<option value={5}>Dispatched</option>
																	<option value={6}>On-hold</option>
																	<option value={7}>Cancelled</option>
																	<option value={8}>Awaiting approval</option>
																	<option value={9}>Awaiting design</option>
																	<option value={10}>Awaiting payment</option>
																	<option value={11}>Delivered</option>
																</select>
																<SelectPointer />
															</div>
														</div>
													</div>
													<div className="flex flex-wrap -mx-3 mb-6">
														<div className="w-full px-3">
															<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
																Product Specs:
															</label>
															<textarea
																required
																rows="3"
																value={selectedProducts[i].specifications}
																onChange={(e) =>
																	onUpdateOrderProduct(i, e.target.value, "specifications")
																}
																className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
																type="text"
																placeholder="include a description that explains a bit about this order here, including any link that you will like to share"
															/>
														</div>
													</div>
													<hr className="border-dashed  border-gray-300 my-3" />
													<div className="px-3">
														<p className="uppercase text-xs text-gray-600">Attachments:</p>
														{prod.attachments && prod.attachments.length > 0 ? (
															prod.attachments.map((file, idx) => {
																return (
																	<div key={idx} className="flex">
																		<p className="text-gray-600 text-xs hover:text-orange-400 cursor-pointer">
																			<a href={file.url} target="_blank" noreferrer>
																				{file.name}
																			</a>
																		</p>
																		<span
																			onClick={() => onDeleteItemFile(prod._id, file._id)}
																			className="text-red-500 text-xs cursor-pointer mx-1">
																			<Delete fontSize="small" />
																		</span>
																	</div>
																);
															})
														) : (
															<p className="italic text-xs">No file uploaded</p>
														)}
													</div>
													<div className="w-full md:w-1/4 px-2">
														<div className=" -mx-3 px-3 mt-4 text-orange-600 text-xs">
															<label className="flex cursor-pointer">
																<AttachFileOutlined />
																<input
																	onChange={(e) => onUploadItemFile(prod._id, e.target.files[0])}
																	type="file"
																	className="hidden"
																/>
																{uploading === i ? "processing..." : "Attach files"}
															</label>
														</div>
													</div>
												</div>
											);
									  })
									: ""}

								{!editItems && (
									<div className="mt-6">
										<button
											type="submit"
											className="bg-orange-500 hover:bg-orange-700 text-white w-1/6 overflow-x-auto text-xs py-2 px-4 rounded">
											{!savingOrderItems ? "Save" : "Saving..."}
										</button>
									</div>
								)}
							</form>
						</>
					</div>

					{/* payment status */}

					<div className=" w-full mx-2 bg-white rounded-sm px-4 mt-4  pb-4">
						<div className="flex justify-between px-2 pt-4">
							<p className="text-l ">Payment status</p>
						</div>
						<hr className="border-gray-200 w-full" />
						<>
							<form className="w-full mt-3 lg:mt-6">
								<div className="w-full  px-3 mb-6 md:mb-0">
									<label className="block uppercase tracking-wide text-gray-700 text-xs mb-2">
										Status:
									</label>
									<div className="relative">
										<select
											value={paymentStatus}
											onChange={(e) => onUpdatePayment(e.target.value)}
											className="block appearance-none w-full bg-gray-100 border text-sm border-gray-100 text-gray-700 py-3 pr-8 rounded px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
											<option>Select status</option>
											<option value={0}>Not Paid</option>
											<option value={1}>Part payment</option>
											<option value={2}>Full payment</option>
										</select>
										<SelectPointer />
									</div>
								</div>
							</form>
						</>
					</div>

					{/* end payment status */}
				</div>

				<div className="lg:w-1/3 w-full mx-2 lg:mx-6">
					<div className="bg-white rounded-sm px-2 lg:px-4 my-3 pb-2">
						<div className="flex justify-between px-2 pt-4 pb-3">
							<p className="text-sm font-bold">Customer information</p>
							<div className="flex text-xs text-gray-600 cursor-pointer">
								{/* <EditIcon /> <span className="pt-0 mx-2">EDIT</span> */}
							</div>
						</div>
						<hr className="border-gray-300 pb-2" />
						<div className="px-2 lg:px-2 mt-4">
							<p className="capitalize text-sm">{customer.name ? customer.name : ""}</p>
							<div className=" capitalize text-xs pt-4">
								{customer.contacts
									? customer.contacts.map((cus, i) => {
											return (
												<div key={i} className="text-xs text-gray-600">
													<p>{cus.name}</p>
													<p>{cus.email}</p>
													<p>{cus.phone}</p>
												</div>
											);
									  })
									: ""}
							</div>
						</div>

						<hr className="mt-6 border-dashed border-gray-300" />
						<p className="font-bold text-xs">Shipping address:</p>
						<div className="text-xs text-gray-600">{deliveryAddress}</div>
					</div>

					<div className="bg-white rounded-sm px-2 lg:px-4 my-3">
						<div className="flex justify-between px-2 pt-4 pb-3">
							<p className="text-sm font-bold">Recent activities</p>
						</div>
						<hr className="border-gray-300 pb-2" />
						{activities.events
							? activities.events.map((event, i) => {
									return (
										<div key={i} className="text-xs pb-4 flex justify-between mt-2">
											<div style={{ width: "190px" }}>
												<p className="text-xs font-bold">
													<span dangerouslySetInnerHTML={{ __html: event.description }} />
												</p>
											</div>
											<div className="flex text-gray-600 text-left">
												<AvTimerOutlined />{" "}
												<span className="text-left">
													{formatDistance(new Date(event.createdAt), new Date(), {
														addSuffix: true,
													})}
												</span>
											</div>
										</div>
									);
							  })
							: ""}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	order: state.orders.order,
	allProducts: state.products.products,
	activities: state.activities.activities,
});

export default connect(mapStateToProps, {
	getSingleOrder,
	updateOrder,
	updateOrderItems,
	getAllProducts,
	getActivities,
	filteredActivity,
	deleteItemAttachement,
	addItemAttachment,
	deleteOrder,
	uploadImage,
})(ViewOrder);
