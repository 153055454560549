import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { Notifications } from "react-push-notification";
import "./tailwind.output.css";
import "./App.css";
import "./assets/pagination.css";
import Routes from "./routes/Routes";
import store from "./redux/store/Store";
import { baseURL } from "./utils/base-url";
import axios from "axios";
import WithClearCache from "./ClearCache";
// import firebase from "./firebase";
const ClearCacheComponent = WithClearCache(MainApp);

axios.defaults.baseURL = baseURL;
let credentials = JSON.parse(sessionStorage.getItem("_ark_task"));
if (credentials !== null && credentials.token) {
	axios.defaults.headers.common["Authorization"] = `Bearer ${credentials.token}`;
}

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {
	return (
		<ToastProvider autoDismiss autoDismissTimeout={3000} placement="top-right">
			<Provider store={store}>
				<Routes />
			</Provider>
		</ToastProvider>
	);
}

export default App;
