import axios from "axios";
import { baseURL } from "../../utils/base-url";
import { customersActionTypes } from "../action-types/customers-actions.types";

/**
 * @description: Create a new customer
 */
export const createCustomer = (customerDetails) => async (dispatch) => {
	let url = "/customers";
	try {
		let customer = await axios.put(url, customerDetails);
		const { success } = customer.data;
		if (success) {
			return customer.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

/**
 * @description: fetch all customers
 */
export const getAllCustomers = (page, limit, all) => async (dispatch) => {
	let url = "";
	if (all === true) {
		url = `/customers?limit=${limit}`;
	} else {
		url = `/customers?page=${page}&limit=${limit}`;
	}
	try {
		let customers = await axios.get(url);
		const { success, data } = customers.data;
		if (success) {
			dispatch({
				type: customersActionTypes.GET_ALL_CUSTOMERS,
				payload: data,
			});
			return customers.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const filterCustomer = (filter) => async (dispatch) => {
	let url = "";
	if (filter.length > 0) {
		url = `/customers?industry=${filter}&page=${1}&limit=${200}`;
	} else {
		url = `/customers?page=${1}&limit=${20}`;
	}
	try {
		let customers = await axios.get(url);
		const { success, data } = customers.data;
		if (success) {
			dispatch({
				type: customersActionTypes.GET_ALL_CUSTOMERS,
				payload: data,
			});
			return customers.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

/**
 * @description: Search customer list
 */
export const searchCustomers = (query) => async (dispatch) => {
	let url = "";
	if (query.length > 0) {
		url = `/customers?query=${query}`;
	} else {
		url = `/customers?page=1&limit=20`;
	}
	try {
		let customers = await axios.get(url);
		const { success, data } = customers.data;
		if (success) {
			dispatch({
				type: customersActionTypes.GET_ALL_CUSTOMERS,
				payload: data,
			});
			return customers.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response;
		}
	}
};

export const getSingleCustomer = (id) => async (dispatch) => {
	let url = `/customers/${id}`;
	try {
		let customer = await axios.get(url);
		const { success, data } = customer.data;
		if (success) {
			dispatch({
				type: customersActionTypes.GET_SINGLE_CUSTOMER,
				payload: data,
			});
			return customer.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateCustomer = (id, customerDetails) => async (dispatch) => {
	let url = `/customers/${id}`;
	try {
		let customer = await axios.patch(url, customerDetails);
		const { success } = customer.data;
		if (success) {
			return customer.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const addCustomerAddress = (id, address) => async (dispatch) => {
	let url = `/customers/${id}/address`;
	try {
		let data = await axios.put(url, address);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};

export const deleteCustomerAddress = (id, address) => async (dispatch) => {
	let url = `${baseURL}/customers/${id}/address`;
	const request = new Request(url, {
		method: "DELETE",
		headers: new Headers({
			Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("_ark_task")).token,
			Accept: "application/json",
			"Content-Type": "application/json",
		}),
		body: JSON.stringify(address),
	});
	let deleteData = await fetch(request);
	let data = await deleteData.json();
	return data;
};

export const deleteCustomer = (id) => async () => {
	let url = `/customers/${id}`;
	try {
		let data = await axios.delete(url);
		const { success } = data.data;
		if (success) {
			return data.data;
		}
	} catch (error) {
		if (error.response) {
			return error.response.data;
		}
	}
};
