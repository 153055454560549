import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: "#1a90ff",
	},
}))(LinearProgress);

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

const NavProgressBar = ({ ready, total }) => {
	const classes = useStyles();
	return (
		<div style={{ width: `${total}px` }} className="relative pt-2 ml-2">
			<div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
				<div
					style={{ width: `${ready}px`, backgroundColor: "#f26925" }}
					className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
			</div>
		</div>
	);
};

export default NavProgressBar;
