let baseURL;

// task-manager.ark.com.ng (prod link)
if (window.location.href.includes("erp")) {
	baseURL = "https://erp.ark.com.ng/v1";
}
if (window.location.href.includes("netlify")) {
	baseURL = "https://ark-beta.herokuapp.com/v1";
}

if (window.location.href.includes("localhost")) {
	baseURL = "http://ark-beta.herokuapp.com/v1";
}

export { baseURL };
