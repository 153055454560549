import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import SelectPointer from "../../components/commons/SelectPointer";
import { inviteUser } from "../../redux/actions/auth.actions";
import { getDepartments } from "../../redux/actions/users.actions";

const CreateUser = (props) => {
	const { inviteUser, modalOpen, getDepartments, allDepartments } = props;
	const { addToast } = useToasts();
	const [saving, setSaving] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [role, setRole] = useState(1);
	const [department, setDepartment] = useState("");
	const [departmentOptions, setDepartmentOptions] = useState([]);

	const onInviteUser = async (e) => {
		e.preventDefault();
		setSaving(true);
		let userDetails = {
			firstName,
			lastName,
			email,
			phone,
			role: parseInt(role),
			department,
		};
		let user = await inviteUser(userDetails);
		if (user.success) {
			addToast(`Invite sent to ${email}`, { appearance: "success" });
			modalOpen(false);
		} else {
			addToast(user.message, { appearance: "error" });
		}
		setSaving(false);
	};

	useEffect(() => {
		getDepartments();
	}, []);

	useEffect(() => {
		let copyDepartments = [];
		if (allDepartments.departments && allDepartments.departments.length > 0) {
			allDepartments.departments.map((dept) => {
				copyDepartments.push({
					value: dept._id,
					label: dept.name,
				});
			});
			setDepartmentOptions(copyDepartments);
		}
	}, [allDepartments]);

	return (
		<form onSubmit={(e) => onInviteUser(e)}>
			<p className="text-xl">Invite new user</p>
			<div className="mt-4">
				<div className="flex flex-wrap -mx-3 mb-6">
					<div className="w-full md:w-1/2 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
							htmlFor="grid-city">
							FIRST NAME
						</label>
						<input
							required
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-first-name"
							type="text"
							placeholder=" first name"
						/>
					</div>
					<div className="w-full md:w-1/2 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
							htmlFor="grid-city">
							LAST NAME
						</label>
						<input
							required
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-last-name"
							type="text"
							placeholder=" last name"
						/>
					</div>
				</div>
				<div className="flex flex-wrap -mx-3 mb-6">
					<div className="w-full md:w-1/2 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
							htmlFor="grid-email">
							Email
						</label>
						<input
							required
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-first-name"
							type="text"
							placeholder=" email"
						/>
					</div>
					<div className="w-full md:w-1/2 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
							htmlFor="grid-phone">
							Phone
						</label>
						<PhoneInput
							country={"ng"}
							enableSearch={true}
							value={phone}
							onChange={(val) => {
								if (val && val.length > 0) {
									let formattedPhone = val.split("");
									formattedPhone.unshift("+");
									setPhone(formattedPhone.join(""));
								}
							}}
						/>
						{/* <input
							required
							maxLength="15"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-last-name"
							type="text"
							placeholder="+2347054744645"
						/> */}
					</div>
				</div>
				<div className="flex flex-wrap -mx-3 mb-6">
					<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
							htmlFor="grid-city">
							User role:
						</label>
						<div className="relative">
							<select
								value={role}
								onChange={(e) => setRole(e.target.value)}
								className="block appearance-none w-full bg-gray-100 border border-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-state">
								<option value={1}>Customer</option>
								<option value={2}>Team member</option>
								<option value={3}>Manager</option>
								<option value={4}>Admin</option>
							</select>
							<SelectPointer />
						</div>
					</div>
					<div className="w-full md:w-1/2 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
							htmlFor="grid-phone">
							Department
						</label>
						<Select
							styles={{
								backgroundColor: "#f8fafd",
							}}
							onChange={(val) => setDepartment(val.value)}
							options={departmentOptions}
						/>
					</div>
				</div>

				<div className="mt-4">
					<button
						type="submit"
						className="bg-orange-500 hover:bg-orange-700 w-1/2 text-white  text-xs py-2 px-4 rounded">
						{!saving ? "Send invite" : "loading..."}
					</button>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => ({
	allDepartments: state.users.departments,
});

export default connect(mapStateToProps, { inviteUser, getDepartments })(CreateUser);
