import React, { useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { createDepartment } from "../../redux/actions/users.actions";

const AddDepartment = ({ createDepartment, modalOpen }) => {
	const [name, setName] = useState("");
	const [saving, setSaving] = useState(false);
	const { addToast } = useToasts();

	const onCreateDepartment = async (e) => {
		e.preventDefault();
		setSaving(true);
		let details = {
			name,
		};
		let response = await createDepartment(details);
		if (response?.success) {
			addToast("Department created successfully", { appearance: "success" });
			modalOpen(false);
			setSaving(false);
		} else {
			addToast(response?.data?.message, { appearance: "error" });
			setSaving(false);
		}
	};

	return (
		<div>
			<form onSubmit={(e) => onCreateDepartment(e)}>
				<label className="block uppercase tracking-wide text-gray-700 text-xs  mb-2" htmlFor="grid-city">
					Name of department
				</label>
				<input
					required
					value={name}
					onChange={(e) => setName(e.target.value)}
					className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-4 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					id="grid-first-name"
					type="text"
					placeholder="enter name of department"
				/>
				<div className="mt-8 flex justify-end">
					<button
						type="submit"
						className="bg-orange-500 hover:bg-orange-700 w-1/2 text-white  text-xs py-3 px-4 rounded">
						{!saving ? "Create department" : "loading..."}
					</button>
				</div>
			</form>
		</div>
	);
};

export default connect(null, { createDepartment })(AddDepartment);
